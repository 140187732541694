<script>
    import { onMount, createEventDispatcher } from 'svelte'
    import { Button } from 'svelte-metro-ui'
    import { ListKey } from './MultiListStore'
    import { loading as pageLoading } from './PageStore'
    import { servicesDate, importServicesList } from '../../stores/DeliveryPlannerStore'
    import StickyFooter from '../../UI/v2/StickyFooter.svelte'

    const dispatch = createEventDispatcher()
    const API_KEY = `AIzaSyAvmvgCSleLj0_RBUVyOcqMglX7qs19yyk`
    const endpoint = address => `https://maps.google.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`
    
    export let value = null
    export let addresses = []
    export let geocodeAddresses = []

    let loading = false
    let el = null
    let textareaEl = null

    const takeFromApi = async address => {
        let response = await fetch(endpoint(address))

        if (response.ok) {
            return response.json();
        } 
    }

    const handleKeydown = e => {
        if (e.key == 'ArrowDown') {
            el.focus()
            setTimeout(() => {
                el.dispatchEvent(new KeyboardEvent('keydown', {key: 'ArrowDown'}))
                el.dispatchEvent(new KeyboardEvent('keydown', {key: 'ArrowDown'}))
            })
        }
    }

    const geocode = async () => {
        geocodeAddresses = []

        Swal.fire({
          title: 'Идет загрузка точек!',
          html: 'Подождите...',
          allowOutsideClick: false,
          onBeforeOpen: () => { 
            Swal.showLoading()
          }
        })
        
        for (var i = 0; i < addresses.length; i++) {
            let answer = await takeFromApi(addresses[i])

            if (answer && answer.results && answer.results[0]) {
                geocodeAddresses.push({
                    address: answer.results[0].formatted_address,
                    lat: answer.results[0].geometry.location.lat,
                    lng: answer.results[0].geometry.location.lng,
                    date: $servicesDate
                })
            }
        }
        $pageLoading[ListKey.Services] = true

        const answer = await importServicesList(geocodeAddresses)
        
        Swal.close() 
        
        if (answer.status == 200) {
            dispatch('success', geocodeAddresses)
            $pageLoading[ListKey.Services] = false
        }       
    }

    onMount(() => {
        let autocomplete = new google.maps.places.Autocomplete(el)

        autocomplete.addListener('place_changed', function() {
            let place = autocomplete.getPlace()
            
            let prevValue = addresses.filter((el, idx) => idx != addresses.length - 1).join('\n')
            let newValue = prevValue
            if (prevValue != '') newValue += '\n'
            newValue += place.formatted_address + '\n'
            value = newValue

            textareaEl.focus()
        })
    })

    $: if (value) addresses = value.split('\n')
    $: if (el && addresses) {
        if (addresses.length && addresses[addresses.length - 1] != '') {
            setTimeout(() => {
                el.dispatchEvent(new KeyboardEvent('keypress'))
                el.dispatchEvent(new Event('focus'))
            }, 50)
        }
    }
</script>

<textarea on:mouseleave={() => el.focus()} on:keydown={handleKeydown} class="uk-textarea" rows="6" bind:value={value} bind:this={textareaEl}></textarea>
<input value={addresses[addresses.length - 1]} bind:this={el} />

<StickyFooter>
    <slot name="submitButton">
        <Button on:click={geocode} success>
            {#if loading}
                <div uk-spinner></div>
            {:else}
                Загрузить точки
            {/if}
        </Button>
    </slot>
</StickyFooter>

<style>
    textarea {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    input {
        width: 100% !important;
        height: 0 !important;
        max-height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
    }
</style>