<script>
    import { onMount, onDestroy, tick, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

    import Phalcon from 'modules/phalcon.js';

    import WindowButton from 'UI/ButtonWindowClose.html';

    export let modalType;
    export let header;
    export let body;
    export let footer;
    export let isReady;
    export let size = '';
    export let props;
    export let request;
    export let data = {};
    export let large = false;

    let options = {
        escClose: false,
        bgClose: false
    };
    let el;


    const dispatch = createEventDispatcher();
    const close = () => dispatch('close');

    onMount(async () => {
		await tick();
		dispatch('ready');
    });

    $: if(modalType && props && props.id && !request) {
        request = Phalcon.request(`${modalType}`, 'get', "GET", {
            id: props.id
        }).then( answer => {
            dispatch('setData', answer);
        }).catch( error => {});
    };

    onDestroy( () => {
    });

</script>

<style>
    .full {
        height: 100vh;
    }

    .uk-modal-body{
        padding: 20px;
    }

    .uk-modal-dialog {
        min-width: 700px;
    }
</style>

<div
    class="uk-modal uk-open"
    class:uk-modal-container={large}
    class:uk-modal-full="{size === 'full'}"
    id="v2-ui-modal" style="display:block"
    transition:fade
>

    <div
        class="uk-modal-dialog uk-padding-small">
        <slot name='header'>
            <div class="uk-modal-header">
                <h2 class="uk-modal-title">{header || 'Нет заголовка'}</h2>
            </div>
            <WindowButton  type="plus" on:click={close}>close</WindowButton>
        </slot>

        <div
            class:full="{size === 'full'}"
            class="uk-modal-body uk-position-relative" uk-overflow-auto>
            <slot name='body'>
                {body || 'Нет данных'}
            </slot>
        </div>

        <slot name='footer'>
            <div class="uk-modal-footer uk-text-right">
                {footer || 'Нет подвала'}
            </div>
        </slot>
    </div>
</div>