<script>
  import { onDestroy } from 'svelte';
  import Phalcon from 'modules/phalcon.js';
  import Validator from 'modules/validator.js';

  export let session;

  //"yandexnavi://build_route_on_map?lat_to=37.640391&lon_to=55.824874&lat_via_0=37.663019&lon_via_0=55.669656&lat_via_1=37.682529&lon_via_1=55.675039&lat_via_2=37.648872&lon_via_2=55.698232&lat_via_3=37.664575&lon_via_3=55.739656"
  const baseRouteString = `yandexnavi://build_route_on_map?`;

  let routeArray = [
    {lat: '55.669656',lon: '37.663019'},
    {lat: '55.675039',lon: '37.682529'},
    {lat: '55.698232',lon: '37.648872'},
    {lat: '55.739656',lon: '37.664575'},
    {lat: '55.824874',lon: '37.640391'},
  ];

  function concatRoute () {
    let tempRouteString = baseRouteString;
    tempRouteString += `lat_to=${routeArray[routeArray.length-1].lat}&lon_to=${routeArray[routeArray.length-1].lon}`;
    routeArray.map( (item, index) => {
      if (index !== routeArray.length - 1){
        tempRouteString += `&lat_via_${index}=${item.lat}&lon_via_${index}=${item.lon}`;
      }
    });
    return tempRouteString;
  };
  concatRoute();

  let lon1=`55.74`,
      lat1=`37.60`,
      lon2=`55.76`,
      lat2=`37.64`;

  let tempString = `yandexnavi://build_route_on_map?lat_to=${lat2}&lon_to=${lon2}`;
  // let tempString = `yandexnavi://build_route_on_map?lat_from=${lat1}&lon_from=${lon1}&lat_to=${lat2}&lon_to=${lon2}`;
  //                  `yandexnavi://build_route_on_map?lat_from=55.74  &lon_from=37.60  &lat_to=55.76  &lon_to=37.64`;

  let isRefresh = false;
  let isUpdate = false;

  onDestroy( () => {
  });

 let request = {
   email: "",
   password: ""
 };

 function getSession() {
   if (typeof request === "object") {
     Phalcon.session.start(request)
       .then(answer => {
         if (answer.status === 200){
           delete answer.status;
           session = answer;
         }
       })
       .catch();
   }
 }

 function pswRepair() {
   if (request.email && request.email.length !== 0){
     let parity = Validator.isValid.mail(request.email);
     if (parity === 0) {
       Phalcon.users.passwordReset({email: request.email.toLowerCase()})
         .then( answer => {
           if (answer.status === 200){
             isUpdate = true;
             UIkit.notification({
               message: `${answer.messages[0]}`,
               status: 'success',
               pos: 'bottom-center',
               timeout: 5000
             })
           }
         })
         .catch();
     } else {
       UIkit.notification({
         message: 'Невалидная почта',
         status: 'danger',
         pos: 'bottom-center',
         timeout: 5000
       })
     }
   } else {
     UIkit.notification({
       message: 'Заполните поле почта',
       status: 'danger',
       pos: 'bottom-center',
       timeout: 5000
     })
   }
 }
</script>

<style lang="scss">.uk-link-muted {
  font-size: 1.2rem; }

.uk-link {
  font-size: 1.2rem;
  font-weight: bold;
  color: #e30613; }
  .uk-link:active {
    color: #e30613; }

.auth__logo {
  font-weight: bold;
  font-size: 2rem;
  color: #000000; }
  .auth__logoimg {
    vertical-align: baseline; }

span.auth__logo {
  color: #e30613; }

.auth__name {
  font-size: 1.3rem;
  color: #000000; }

.uk-input {
  border: none;
  border-bottom: 1px solid #e2e2e2;
  font-size: 1rem; }

.uk-icon-button {
  color: #ffffff;
  background: #e30613; }

.sub-button {
  text-transform: unset;
  font-size: 1.2rem;
  text-decoration: none;
  color: #cecece; }
  .sub-button:hover {
    text-decoration: underline;
    text-transform: unset; }

.g__btn--yes {
  border: none;
  padding: 1rem;
  font-size: 1.3rem; }

.button {
  text-transform: unset;
  color: #cecece; }
  .button__repeat {
    text-transform: unset;
    color: #c4c4c4;
    font-size: 1.2rem; }
</style>

<div class="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">
  <div  class="uk-width-large" >
    <div class="auth__logo uk-margin-large-bottom uk-text-center uk-width-1-1"><span><img class="fondo-logo" src="/svg/redLogo.svg" alt="logo"></span><span>REX: </span><span class="auth__logo">routes expert</span></div>

    {#if isRefresh === false && isUpdate === false}
    <div class="auth__name uk-margin-large-bottom uk-text-center">Авторизация</div>
    <div class="uk-text-center uk-margin-small-bottom"><input bind:value="{request.email}" placeholder="Email или Телефон" class="uk-width-3-4 uk-input" type="text"></div>
    <div class="uk-margin-bottom uk-text-center"><input bind:value="{request.password}" placeholder="Пароль" class="uk-width-3-4 uk-input" type="password"></div>
    <div class="auth__action uk-margin-xlarge-bottom uk-flex uk-flex-center uk-flex-around">
      <a class="uk-link-muted" on:click="{() => isRefresh = true}">Забыли пароль?</a>
      <a on:click={getSession} class="uk-link">Войти</a>
    </div>

<!--    <div class="uk-flex uk-flex-between">-->
<!--      <div>-->
<!--        <a href="{tempString}">hehe</a>-->
<!--        <a href="{concatRoute()}">hoho</a>-->
<!--      </div>-->

    {:else if isUpdate}
      <div class="uk-width-1-1 uk-text-center uk-margin-small-top uk-margin-medium-bottom">
        Письмо с подтверждением было выслано на указанную вами почту <span>(<a>{request.email}</a>)</span>. Нажмите на ссылку в письме, чтобы подтвердить свой Email.
      </div>
      <div class="uk-width-1-1 uk-flex uk-flex-column">
        <button on:click="{()=> {isRefresh = false, isUpdate = false}}" class="g__btn--yes uk-button-small">Авторизация</button>
      </div>
    {:else}
    <div class="auth__name uk-margin-large-bottom uk-text-center">Восстановление пароля</div>
    <div class="uk-text-center uk-margin-bottom"><input bind:value="{request.email}" placeholder="Введите Email" class="uk-width-3-4 uk-input" type="text"></div>
    <div class="uk-flex uk-flex-center">
      <button on:click="{() => pswRepair()}" class="button__repeat uk-button uk-button-link">Восстановить пароль</button>
    </div>
    {/if}

  </div>
</div>