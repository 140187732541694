<script>
   import HelpModal from "components/support/SupportModal.html";
   
   import Button from "UI/ButtonOld.html";

   let currentField = 0;
   let data = [{
      name: 'Начало работы',
      content: `<h3>С чего начать работу?</h3><p>Для добавления точек маршрута воспользуйтесь ручным вводом адреса или загрузкой файла с адресами. Маркеры адресов с номерами будут показаны на карте. Передвигайте маркер на карте, чтобы изменить адрес. На данном шаге вы можете заполнить ограничение по времени поездки, а также пробегу.</p><img src="svg/dashboard/carousel/pic1.svg">`
   }, {
      name: 'Заказы',
      content: `<h3>Заказы</h3><p>Чтобы создать заказ, зайдите в раздел "Заказы" -> "Новый заказ"</p><footer>Заполнив форму и необходимые данные, нажмите кнопку "Готово"</footer>`
   }];
   let content = data[0].content;

   function createRequest(index) {
      UIkit.modal(document.getElementById('modal-help')).show();
   }

   const setActive = index => () => {
      currentField = index;
      content = data[index].content;
   }
</script>

<style lang="scss">.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

.uk-leader-fill::after {
  color: #c4c4c4;
  margin-left: 0 !important; }

ul {
  margin: 0 0 15px 0;
  padding: 0; }
  ul li {
    list-style: none;
    font-size: 20px; }
    ul li a {
      font-size: 20px; }

.text__decoration {
  display: flex;
  flex-direction: row; }
  .text__decoration::before {
    margin: auto;
    content: '';
    width: 25px;
    border-bottom: 1px solid #c4c4c4; }
  .text__decoration::after {
    flex: 1 1;
    margin: auto;
    content: '';
    width: auto;
    border-bottom: 1px solid #c4c4c4; }

.text__link {
  margin-left: 25px; }
</style>

<div class="uk-grid-collapse" uk-grid="masonry: true">
   <div class="uk-width-medium">
      <div class="uk-background-default uk-width-expand uk-padding-small">
         <ul>
            {#each data as item, index}
            <li>
               {#if index > 0 && data[index - 1].name.charAt(0) !== item.name.charAt(0) || index == 0}
                  <div class="text__decoration uk-width-expand">{item.name.charAt(0)}</div>
               {/if}
               <div class="text__link uk-text-emphasis"><a on:click="{setActive(index)}" class="uk-link-text">{item.name}</a></div>
            </li>
            {/each}
         </ul>
         <div class="uk-text-center"><Button on:click="{() => createRequest(currentField)}" size="half" name="Задать вопрос" type="success"/></div>
      </div>
   </div>

   <div class="uk-width-expand">
      <div class="uk-background-default uk-padding uk-margin-left">
         {@html content}
      </div>
   </div>
</div>