<script>
    import Input from 'UI/Input.html';
    import Main from 'components/orders/OrdersDetailMain.html';
    import Other from 'components/orders/OrdersDetailOther.html';

    export let data;
    export let requiredCallbacks = {};
    //console.log(data);
    let danger = false;
    let mainInfoTitleElm;

    $: if(danger) {
        mainInfoTitleElm.classList.add('uk-text-danger', 'uk-animation-shake');
        setTimeout(() => {
            mainInfoTitleElm.classList.remove('uk-animation-shake');
            danger = false;
        }, 300)
    }

    $: if(data) {
        removeDanger();
    }

    function removeDanger() {
        if(mainInfoTitleElm) mainInfoTitleElm.classList.remove('uk-text-danger');
    }
</script>

<style lang="scss">a {
  text-transform: unset;
  padding: 10px; }

h2 {
  margin: 0;
  padding: 0; }
</style>

<div class="wrapper uk-flex uk-flex-start">

    <div class="uk-card uk-card-default uk-card-body uk-padding-remove uk-width-large">
        <ul uk-switcher uk-tab class="uk-flex-around uk-card-title uk-margin-remove-bottom">
            <li class="uk-active uk-width-expand" class:danger><a bind:this={mainInfoTitleElm}>Основное</a></li>
            <li class="uk-width-expand" ><a>Комментарий</a></li>
        </ul>

        <ul class="uk-switcher  uk-padding-small">
            <li>
                <Main
                    bind:payType="{data.payType}"
                    bind:data="{data}"
                    bind:requiredCallbacks
                    on:danger="{() => danger = true}" />
            </li>
            <li>
                <Other bind:filesFormData={data.filesFormData} bind:comment={data.comment} bind:files={data.files} />
            </li>
        </ul>
    </div>

</div>