<script context="module">
    export const routeName = '/regions';
</script>

<script>
    import { onMount, onDestroy } from "svelte";
    import { get } from 'svelte/store';
    import Phalcon from 'modules/phalcon.js'

    import regionsStore, { fetchAllRegions, updateAllRegions } from 'main/stores/RegionsStore.js'

    import GoogleMap from "components/map/GoogleMap.html"
    import RegionsImportModal from 'components/regions/RegionsImportModal.html'
    import RegionsEditModal from 'components/regions/RegionsEditModal.html'
    
    import PaginationTable from 'UI/v2/PaginationTable.html'
    import Datepicker from 'UI/Datepicker.html'
    import Backdrop from 'UI/Backdrop.html'
    import Loader from 'UI/Loader.html'
    import Button from 'UI/Button.html'
    import { successMsg, warningMsg, errorMsg } from 'UI/Messages.html'

    let regions = []
    let regionsLimit = 10
    let regionsLoading = false
    let regionsPage = 1
    let regionsTotalPages = 1
    let regionsPolygons = []
    let regionsPolygonsMap = new Map()
    let currentRegion = null
    
    let isShowRegionsImportModal = false

    const getAllRegions = async () => {
        regionsLoading = true
        await fetchAllRegions()
        regionsLoading = false

        regions = get(regionsStore)
    }
    
    const getAllRegionsForce = async () => {
        regionsLoading = true
        await updateAllRegions()
        regionsLoading = false

        regions = get(regionsStore)
    }

    // const fetchAllRegions = async () => {
    //     regionsLoading = true

    //     const answer = await Phalcon.api.regions.all({ 
    //         page: regionsPage, 
    //         limit: regionsLimit 
    //     })

    //     if(answer.status == 200) {
    //         regions = answer.payload.items
    //         regionsTotalPages = answer.payload.total_pages
    //     } else {
    //         errorMsg(answer.message)
    //     }

    //     regionsLoading = false
    // }

    const deleteRegion = item => async () => {
        if (!confirm(`Удалить регион "${item.name}"?`)) return

        regions = regions.map(r => {
            if (r.id === item.id) r.loading = true
            return r
        })

        const answer = await Phalcon.api.regions.delete(item.id)

        if(answer.status == 200) {
            regions = regions.filter(r => r.id !== item.id)
            successMsg(`Регион "${item.name}" удален успешно`)
        } else {
            errorMsg(answer.message)
        }
    }

    const editRegion = region => () => {
        currentRegion = region
    }

    const showRegionOnMap = region => () => {
        let geometry = JSON.parse(region.geometry)
        let properties = JSON.parse(region.properties)

        if (geometry) {

            if (regionsPolygonsMap.has(region.id)){
                regionsPolygonsMap.delete(region.id)
            } else {
                let coords = geometry[0].map(coord => {
                    return {
                        lat: coord[1],
                        lng: coord[0],
                    }
                })

                regionsPolygonsMap.set(region.id, new google.maps.Polygon({
                    paths: coords,
                    strokeColor: properties && properties['stroke'] ? properties['stroke'] : '#FF0000',
                    strokeOpacity: properties && properties['stroke-opacity'] ? properties['stroke-opacity'] : 0.8,
                    strokeWeight: properties && properties['stroke-width'] ? properties['stroke-width'] : 2,
                    fillColor: properties && properties['fill'] ? properties['fill'] : '#FF0000',
                    fillOpacity: properties && properties['fill-opacity'] ? properties['fill-opacity'] : 0.35,
                    regionId: region.id,
                }))

                // console.log({regionsPolygons})
            }

            regionsPolygons = Array.from(regionsPolygonsMap.values())
        }
    }

    const showAllRegionOnMap = () => {
        if (regionsPolygonsMap.size == regions.length) {
            regionsPolygonsMap.clear()
        } else {
            regions.forEach(region => {
                let geometry = JSON.parse(region.geometry)

                if (geometry) {
                    let coords = geometry[0].map(coord => {
                        return {
                            lat: coord[1],
                            lng: coord[0],
                        }
                    })

                    regionsPolygonsMap.set(region.id, new google.maps.Polygon({
                        paths: coords,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        regionId: region.id,
                    }))
                }
            })
        }

        regionsPolygons = Array.from(regionsPolygonsMap.values())
    }
    
    const showRegionsImportModal = () => isShowRegionsImportModal = true
    const closeRegionsImportModal = () => isShowRegionsImportModal = false
    const successReionsImportModal = async () => {
        isShowRegionsImportModal = false
        successMsg('Импорт регионов совершен успешно')

        regionsLoading = true
        await updateAllRegions()
        regionsLoading = false
    }

    const closeRegionsEditModal = () => currentRegion = null

    // $: regionsPage, fetchAllRegions()

    onMount(() => {
        window.nav.set({
            breadcrumb: [{
                name: 'Экспресс заказы',
            }, {
                name: 'Регионы',
            }],
        });

        document.querySelector("#work-zone").classList.remove('padding');
        document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
        window.scrollTo(0, document.body.scrollHeight);
    });

    onDestroy(() => {
        document.querySelector("#work-zone").classList.add('padding');
        document.querySelector('meta[name="viewport"]').setAttribute('content', '');
    });

    getAllRegions()
</script>

{#if isShowRegionsImportModal}
    <RegionsImportModal on:close={closeRegionsImportModal} on:success={successReionsImportModal} />
{/if}

{#if currentRegion}
    <RegionsEditModal region={currentRegion} on:close={closeRegionsEditModal} />
{/if}

<div class="uk-width uk-grid uk-grid-collapse wrapper">
    <div class="uk-width-large uk-padding-small uk-height-1-1 uk-overflow-auto">
        <div class="uk-flex uk-flex-between">
            <Button class="uk-margin-small-bottom" size="small" on:click={showRegionsImportModal}>Импорт регионов</Button>

            {#if regionsLoading}
                <a class="uk-icon-button uk-spinner uk-margin-small-right">
                    <div uk-spinner></div>
                </a>
            {:else}
                <a class="uk-icon-button uk-margin-small-right" uk-icon="refresh" on:click={getAllRegionsForce}></a>
            {/if}
        </div>
        
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-padding-remove uk-margin-remove">
            <PaginationTable 
                totalPages={regionsTotalPages} 
                loading={regionsLoading}
                limit={regionsLimit}
                bind:page={regionsPage}
                data={regions}  
                small
                native
                let:items
            >
                <thead>
                    <th class="uk-table-shrink"></th>
                    <th>ID</th>
                    <th>Наименование</th>
                    <th class="uk-table-shrink"></th>
                </thead>
                <tbody>
                    {#each items as item (item.id)}
                        <tr>
                            <td>
                                <input class="uk-checkbox" type="checkbox" checked={regionsPolygonsMap.has(item.id)} on:click={showRegionOnMap(item)}>
                            </td>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td class="uk-position-relative uk-flex">
                                {#if item.loading}
                                    <a class="uk-icon-button uk-spinner uk-margin-small-right">
                                        <div uk-spinner></div>
                                    </a>
                                {:else}
                                    <a class="uk-icon-button uk-margin-small-right" uk-icon="trash" on:click={deleteRegion(item)}></a>
                                {/if}

                                {#if item.loading}
                                    <a class="uk-icon-button uk-spinner uk-margin-small-right">
                                        <div uk-spinner></div>
                                    </a>
                                {:else}
                                    <a class="uk-icon-button uk-margin-small-right" uk-icon="pencil" on:click={editRegion(item)}></a>
                                {/if}
                            </td>
                        </tr>
                    {:else}
                        <tr>
                            <td colspan="4" class="uk-text-center">
                                Нет регионов
                            </td>
                        </tr>
                    {/each}
                </tbody>
            </PaginationTable>
        </div>
    </div>
    <div class="uk-width-expand">
        <GoogleMap polygons={regionsPolygons} />
    </div>
</div>
  
<style>
    .wrapper {
        height: 100%;
    }

    .tool {
        width: 500px !important;
    }

    :global(.grecaptcha-badge) {
        display: none;
    }

    .tools {
        position: fixed !important;
        z-index: 999;
    }
</style>