<script>
  import { routeStore } from "main/Mobile/stores/stores.js";
  import Scorer from 'UI/InputScorer.html';
  import Button from 'main/Mobile/UI/Buttons/Button.html';
  import Router from 'modules/router.js';

  let totalCount;
  let totalPrice;
  let routeId;
  const unsubscribe = routeStore.subscribe(value => {
    routeId = value;
  });

  let columns = [
    {title:'Имя',prop:'name'},
    {title:'Количество',prop:'count'},
    {title:'Сумма',prop:'price'},
  ];

  $: if (routeId){
    totalPrice = 0;
    totalCount = 0;
    routeId.application.products.map( (item) => {
      if (item.fact_count){
        totalPrice += (item.fact_count * item.price);
        totalCount += item.fact_count;
      }
    });
  }

  function changeScore(event, index){
    routeId.application.products[index].fact_count = event.detail;
  }
</script>

<style lang="scss">table,
td,
th {
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  background: #ffffff;
  text-transform: unset;
  font-size: 14px;
  color: #515151; }
</style>

<div class="uk-container uk-padding-small uk-margin-remove ">
  <table
      class="uk-table">
    <thead>
    <tr>
    {#each columns as column}
      <th>{column.title}</th>
    {/each}
    </tr>
    </thead>
    {#if routeId}
    <tbody>
    {#each routeId.application.products as item, index}
      <tr>
        {#each columns as column}
          {#if column.prop === 'name'}
            <td>{item.article} {item.name}</td>
          {:else if column.prop === 'price'}
            <td>{item.price * item.fact_count}</td>
          {:else if column.prop === 'count'}
            <td><Scorer min="0" max="{routeId.application.products[index].count}" bind:count="{routeId.application.products[index].fact_count}" /></td>
          {:else}
            <td>{item[column.prop]}</td>
          {/if}
        {/each}
      </tr>
    {/each}
    </tbody>
    {/if}
  </table>
  <div class="uk-flex uk-flex-between">
    <div>Количество:</div>
    <div>{totalCount} <span></span>шт.</div>
  </div>
  <div class="uk-flex uk-flex-between">
    <div>Сумма:</div>
    <div>{totalPrice}<span></span>руб.</div>
  </div>

  <div class="uk-position-bottom uk-position-fixed uk-flex">
    <Button on:click="{ () => Router.goTo(`route/${routeId.id}`) }" name="Назад" type="passive"/>
    <Button on:click="{ () => console.log('Подтвердить') }" name="Подтвердить" type="confirm"/>
  </div>
</div>