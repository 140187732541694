<script context="module">
    export function fromRexApi(item) {
        return {
            id: item.id,
            firstName: item.first_name,
            lastName: item.last_name,
            status: item.status,
            location: item.location,
            regionId: item.regions_id,
            onDuty: item.on_duty,
            capacity: item.capacity,
            volume: item.volume,
            maxDeliveryServicesCount: item.max_delivery_services_count
        }
    }
</script>

<script>
    import { createEventDispatcher } from 'svelte'

    import { regionsMap } from 'main/stores/RegionsStore.js'

    import DeliveryServicesItem from "components/delivery_services/DeliveryServicesItem.html"

    const dispatch = createEventDispatcher()

    export let id
    export let firstName
    export let lastName
    export let deliveryServices = []
    export let status
    export let statusList
    export let usersList
    export let location
    export let currentTime
    export let regionId = 0
    export let showRegion = false
    export let onDuty = false
    export let capacity = 0
    export let volume = 0
    export let maxDeliveryServicesCount = 0

    let showDetail = false
    let statusString = 'Неизвестно'

    let region = regionsMap.get(regionId)
    let regionProperties = null

    if (region) {
        if (region.properties) regionProperties = JSON.parse(region.properties)
        // console.log({regionProperties})
    }

    const onLocationIconClick = () => {
        dispatch('locationIconClick', location)
    }

    const onPickupIconClick = event => {
        dispatch('pickupIconClick', event.detail)
    }

    const onDeliveryIconClick = event => {
        dispatch('deliveryIconClick', event.detail)
    }

    $: if(!onDuty) {
        statusString = 'Смена закрыта'
    } else {
        statusString = 'Смена открыта'

        if(status == 'FREE'){
            statusString = 'Свободен'
        } else if(status == 'WAITING'){
            statusString = 'В зоне выдачи'
        } else if(status == 'BUSY'){
            statusString = 'Занят'
        }
    }
</script>

<div 
    class="uk-card uk-card-default uk-card-small uk-card-body uk-margin-small-top" 
    class:not-on-duty={!onDuty}
>
    <div class="uk-grid-small" uk-grid>
        <div class="uk-width-expand">
            <h3>{lastName} {firstName}</h3>
        </div>
        <div class="uk-width-auto uk-flex">
            <label
                class:status-free="{status == 'FREE'}"
                class:status-waited="{status == 'WAITING'}"
                class:status-busy="{status == 'BUSY'}"
                class:status-not-on-duty="{!onDuty}"
            >{statusString}</label>
            <a class="uk-margin-small-left" uk-icon="icon: location; ratio: 0.8" on:click={onLocationIconClick}></a>
        </div>
    </div>
    {#if showRegion}
        <div class="uk-width uk-margin-small-top">
            <span class="uk-badge" style="--fill-color:{regionProperties && regionProperties.fill ? regionProperties.fill : 'black'}">{region && region.name ? region.name : 'Без региона'}</span>
        </div>
    {/if}
    <div class="uk-grid-small uk-margin-small-top" uk-grid>
        <div class="uk-width">
            <label class="uk-text-meta uk-margin-small-right">Макс. вес: {capacity}кг</label>
            <label class="uk-text-meta uk-margin-small-right">Макс. объем: {volume}м³</label>
            <label class="uk-text-meta uk-margin-small-right">Макс. заказов: {maxDeliveryServicesCount}шт</label>
        </div>
        <div class="uk-width-expand">
            <label class="uk-text-meta">Заказов в работе: </label>{deliveryServices.length}
        </div>
        <div class="uk-width-auto uk-flex">
            {#if showDetail}
                <a uk-icon="chevron-up" on:click="{() => showDetail = false}"></a>
            {:else}
                <a uk-icon="chevron-down" on:click="{() => showDetail = true}"></a>
            {/if}
        </div>
    </div>
    {#if showDetail}
        {#each deliveryServices as item} 
            <DeliveryServicesItem 
                { statusList }
                { usersList }
                { currentTime }
                deliveryService={item}
                showRegion={false}
                on:deliveryIconClick={onDeliveryIconClick}
                on:pickupIconClick={onPickupIconClick}
            />
        {/each}
    {/if}
</div>

<style>
    h3 {
        font-size: 15px;
    }

    p {
        margin: 0 !important;
        margin-bottom: 3px;
        font-size: 13px;
    }

    b, div {
        font-size: 13px;
    }

    .uk-card {
        padding: 10px;
    }

    .uk-text-meta {
        color: #818181;
    }

    .status-free {
        color: green;
    }
    
    .status-waited {
        color: orange;
    }
    
    .status-busy {
        color: red;
    }

    .status-not-on-duty {
        color: red;
    }

    .not-on-duty {
        opacity: 0.5;
    }
</style>