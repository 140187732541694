<script>
  import { onMount, onDestroy } from "svelte";
  
  import MapHelper from "modules/mapHelper.js";
  import pathOptimizer from "modules/pathOptimizer.js";

  import FastRoute from "components/map/MapFastRoute.html";

  let map;
  let wrapper;
  let googleMap;
  let markers = new Map();
  let durationText = '0 мин';
  let distanceText = '0 км';
  let isMobile = window.screen.width < 968 ? true : false;

  window.nav.set({
    breadcrumb: [{
      name: 'Быстрый маршрут',
      link: '/fastmap'
    }],
    actions: [],
    actionAddText: ''
  });

  onMount(() => {
    if (map) {
      googleMap = new google.maps.Map(map, {
        center: { lat: 55.752107, lng: 37.622345 },
        zoom: 10,
        maxZoom: 18,
        disableDefaultUI: true
      });
      MapHelper.init({ googleMap, markers }, {
        clusterize: false
      });
      MapHelper.pathOptimizer = pathOptimizer;
    }

    // let marker = new google.maps.Marker({
    //   position: { lat: 55.752107, lng: 37.622345 },
    //   map: googleMap,
    //   title: 'Hello World!'
    // });

    document.querySelector("#work-zone").classList.remove('padding');
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
    window.scrollTo(0,document.body.scrollHeight);
  });

  onDestroy(() => {
    document.querySelector("#work-zone").classList.add('padding');
    document.querySelector('meta[name="viewport"]').setAttribute('content', '');
  })


</script>

{#if isMobile} 
  <div id="modal-full" class="uk-modal-full uk-background-default" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
        <div style="padding-top: 40px">
          <FastRoute {MapHelper} {googleMap} bind:durationText bind:distanceText />
        </div>
    </div>
  </div>

  <div class="uk-height-1-1 uk-position-relative">
    <div class="map" bind:this="{map}"></div>
    <div class="uk-position-bottom uk-background-default uk-flex uk-flex-between uk-padding-small tools">
      <div>
        <b>~{distanceText}/{durationText} в пути</b>
      </div>
      <div>
        <a class="uk-button uk-button-small uk-button-default" href="#modal-full" uk-toggle>Точки</a>
      </div>
    </div>
  </div>
{:else}
  <div class="uk-width uk-grid uk-grid-collapse wrapper" bind:this="{wrapper}">
    {#if googleMap}
    <div class="uk-width-auto tool">
      <FastRoute {MapHelper} {googleMap} />
    </div>
    {/if}
    <div class="uk-width-expand">
      <div class="map" bind:this="{map}"></div>
    </div>
  </div>
{/if}

<style>

  .wrapper {
    height: 100%;
  }

  .tool {
    width: 500px !important;
  }

  .map {
    height: 100%;
    width: 100%;
  }

  :global(.grecaptcha-badge) {
    display: none;
  }

  .tools {
    position: fixed !important;
    z-index: 999;
  }
</style>