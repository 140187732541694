<script>
  const HOURS = 1;
  const MINUTES = 2;

  export let step=1, title='c';
  export let value = "00:00";

  let hoursEl;
  let minutesEl;

  let hours = parseInt(value.split(':')[0]) || "00";
  let minute = parseInt(value.split(':')[1]) || "00";

  const parseTime = (type, value) => {
    value = parseInt(value);

    let max, min = 0;

    if(type == 'h') max = 23;
    if(type == 'm') max = 59;

    if(value > max) value = max;
    if(value < 0) value = 0;
    if(value < 10) value = `0${value}`;

    return value;
  };

  const cursorToEnd = type => () => {
    // console.log({type});
    let el = type == HOURS ? hoursEl : minutesEl;
    let val = type == HOURS ? hours : minute;
    
    if (el.setSelectionRange) {
      let len = val.length * 2;

      setTimeout(function() {
        el.setSelectionRange(len, len);
      }, 1);

    } else {
      el.value = val;
    }
  }

  const onChange = () => {
    hours = parseTime('h', hours);
    minute = parseTime('m', minute);
    value = `${hours}:${minute}`;
  }

  $: {
    if(hours) hours = hours.toString().replace(/\D/g, '').slice(-2)
    if(minute) minute = minute.toString().replace(/\D/g, '').slice(-2)
  }
</script>

<style>
  input {
    margin-bottom: 10px;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    font-size: 14px;
    height: 1.5rem;
    width: 2.8rem;
  }
</style>

  <div class="uk-width-1-1">
    <div>{title}</div>
    <div class="uk-flex">
      <input bind:value="{hours}" class="uk-input" on:focus={cursorToEnd(HOURS)} on:change={onChange} type="text" step="1" bind:this={hoursEl}>:
      <input bind:value="{minute}" class="uk-input" on:focus={cursorToEnd(MINUTES)} on:change={onChange} type="text" step="1" bind:this={minutesEl}>
    </div>
  </div>