<script>
    import { onMount, createEventDispatcher } from 'svelte';
    
    import Button from "UI/ButtonOld.html";

    const dispatch = createEventDispatcher();

    export let items = [],
        isDropList = "",
        searchText = '',
        displayProps = [],
        filteredProp = 'name',
        focus = false,
        clearOnSelect = true,
        placeholder = 'Начните ввод',
        withCreate = false,
        input,
        isFilterActive = true;
    let filteredData = [];
    let pickList;
    let selectInput;
    let focusedItem = 0;

    $: if(items) {
        if(isFilterActive) {
            filteredData = items.filter(item => {
                if (item.selected) return false;
                if (item[filteredProp]) {
                    if (item[filteredProp] === null || typeof item[filteredProp] === 'undefined' || item[filteredProp].length === 0) return false;
                    if (item[filteredProp].toString().toLowerCase().search(searchText.toLowerCase()) === -1) return false;
                }
                return true;
            });
        } else {
            filteredData = items;
        }
    }

    const focusOut = () => {
        setTimeout(() => {
            focus = false;
        }, 150);
    }

    const selectItem = index => () => {
        dispatch('selected', filteredData[index]);
        if(clearOnSelect) searchText = '';
    }

    function arrowNavigate (event) {
        if (focus && filteredData.length){
            if(event.keyCode === 40){
                if (focusedItem < filteredData.length - 1) focusedItem++;
            } else if(event.keyCode === 38){
                if (focusedItem > 0) focusedItem--;
            } else if(event.keyCode === 13){
                dispatch('selected', filteredData[focusedItem]);
                if(clearOnSelect) searchText = '';
                focusedItem = 0;
                setTimeout(() => {
                    focus = false;
                    input.blur();
                }, 150);
            }
        }
    }
</script>

<style lang="scss">.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

/*.wrapper {*/
/*    font-family: Roboto;*/
/*    width: 50%;*/
/*    height: 50%;*/
/*    position: absolute;*/
/*    left: $nav-size / 2;*/
/*    top: 0;*/
/*    transform: translate(50%, 25%);*/
/*    border: 1px solid #515151;*/
/*}*/
.wrapper {
  width: 100%;
  position: relative; }

input {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  height: 30px;
  font-size: 14px; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul li {
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    min-height: 30px; }
    ul li:nth-child(odd) {
      background: #f4f4f4; }
    ul li:hover {
      background: rgba(0, 0, 0, 0.1);
      opacity: 0.8;
      cursor: pointer; }

.targeted {
  background: rgba(28, 100, 255, 0.2) !important;
  cursor: pointer !important; }

.input__block {
  box-sizing: border-box;
  border-left: solid #e2e2e2 1px;
  border-right: solid #e2e2e2 1px;
  border-top: solid #e2e2e2 1px;
  border-bottom: solid #e2e2e2 1px;
  padding: 3px; }

.input__block.active {
  /*box-shadow: 0px 1px 3px #e30613;*/
  box-sizing: border-box;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-left: solid #c4c4c4 1px;
  border-right: solid #c4c4c4 1px;
  border-top: solid #c4c4c4 1px;
  padding: 3px;
  border-bottom: solid none 1px; }

.input__droplist {
  display: none;
  box-sizing: border-box;
  border-left: solid #e2e2e2 1px;
  border-right: solid #e2e2e2 1px;
  border-top: solid #e2e2e2 1px;
  border-bottom: solid #e2e2e2 1px;
  padding: 3px;
  max-height: 200px !important; }

.input__droplist.active {
  /*box-shadow: 0px 1px 3px #e30613;*/
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: solid #c4c4c4 1px;
  border-right: solid #c4c4c4 1px;
  border-bottom: solid #c4c4c4 1px;
  padding: 3px;
  border-top: solid none 1px;
  max-height: 225px;
  overflow-y: scroll;
  background-color: #ffffff;
  z-index: 999; }

.input__list--selected {
  border: 1px solid #e2e2e2;
  display: inline-block;
  padding: 0 3px;
  border-radius: 3px;
  height: 30px;
  margin: 3px 3px; }

hr {
  margin: 0;
  padding: 0; }

.list_item {
  color: #c4c4c4; }

.display-props span {
  padding-right: 3px; }

.link__create {
  cursor: pointer; }
  .link__create:hover {
    color: #515151; }
</style>

<svelte:window on:keydown="{arrowNavigate}"></svelte:window>

<div class="wrapper uk-margin-small-bottom" on:click|stopPropagation>

    <div class="uk-width" class:active="{focus}">
        <!--        {#each selectedItems as item (item.id)}-->
        <!--            <div on:click="{() => unMarkSelected(item.id)}" class="input__list&#45;&#45;selected">-->
        <!--                {item.name} {item.lastname} <a>&times</a>-->
        <!--            </div>-->
        <!--        {/each}-->
        <input class="uk-input uk-width-expand"
               on:focus="{() => focus = true}"
               on:focusout="{focusOut}"
               on:click on:input
               type="text" bind:value={searchText} placeholder="{placeholder}" bind:this={input}>
        {#if withCreate}
                <span on:click|stopPropagation="{ () => dispatch('create') }" class="link__create uk-text-meta uk-position-center-right uk-margin-small-right">Cоздать</span>
        {/if}
    </div>

    {#if focus && filteredData.length}
    <div class="input__droplist active uk-position-absolute">
        <ul bind:this={pickList}>
            {#if searchText.length === 0 }
                <li class="list_item list_item--default">
                    <div class="uk-margin-small-left">Выберите товар или продолжите ввод</div>
                </li>
            {:else if filteredData.length === 0}
                <li class="list_item list_item--default">
                    <div class="uk-margin-small-left">Совпадений не найдено</div>
                </li>
            {/if}

            {#each filteredData as item, i}
                <li on:click|stopPropagation="{selectItem(i)}" class:targeted="{focusedItem == i}">
                    <div class="uk-margin-small-left display-props">
                        {#each displayProps as {key, className, isLineBreak}}
                            {#if key.split('.').length == 3}
                                {#if item[key.split('.')[0]]
                                  && item[key.split('.')[0]][key.split('.')[1]]
                                  && item[key.split('.')[0]][key.split('.')[1]][key.split('.')[2]]}
                                <span class="{className}">
                                    {item[key.split('.')[0]][key.split('.')[1]][key.split('.')[2]]}
                                </span>
                                {/if}
                            {:else if key.split('.').length == 2}
                                {#if item[key.split('.')[0]]
                                && item[key.split('.')[0]][key.split('.')[1]]}
                                <span class="{className}">{item[key.split('.')[0]][key.split('.')[1]]}</span>
                                {/if}
                            {:else if key.split('.').length == 1}
                                {#if item[key.split('.')[0]]}
                                <span class="{className}">{item[key.split('.')[0]]}</span>
                                {/if}
                            {/if}
                            {#if isLineBreak}
                                <br>
                            {/if}
                        {:else}
                            {#if item.name}
                                <span>{item.name}</span>
                            {/if}
                        {/each}
                    </div>
                </li>
            {/each}
        </ul>
    </div>
    {/if}

<!--    <div>-->
<!--        {#each selectedItems as item (item.id)}-->
<!--        <div on:click="{() => unMarkSelected(item.id)}">-->
<!--            {item.name} {item.brandName} <a>&times</a>-->
<!--        </div>-->
<!--        {/each}-->
<!--    </div>-->
</div>