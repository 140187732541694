<script context="module">
    export const routeName = '/delivery/express';
</script>

<script>
    import { Observable } from 'rxjs';
    import { onMount, onDestroy } from "svelte";
    import { fade } from 'svelte/transition';
    import { get } from 'svelte/store'

    import Phalcon from 'modules/phalcon.js'
    import MapHelper from "modules/mapHelper.js";

    import { fetchAllRegions } from 'main/stores/RegionsStore.js'
    import { expressRoutePointsStore, expressEditRouteModeStore, expressDSUserRecommendation } from 'main/stores/DeliveryServicesStore.js'
    import statusListStore, { fetchAllStatusList } from 'main/stores/DeliveryStatusListStore.js'

    import GoogleMap, { fitBounds } from "components/map/GoogleMap.html"
    import Marker from "components/map/Marker.html"
    import Polyline from "components/map/Polyline.html"
    import Route from "components/map/Route.html"
    import DeliveryServicesItem from "components/delivery_services/DeliveryServicesItem.html"
    import DeliveryServicesMarkerSet from "components/delivery_services/DeliveryServicesMarkerSet.html"
    import DeliveryService from 'models/DeliveryService'
    import UsersMapListItem, { fromRexApi as userFromRexApi } from "components/users/UsersMapListItem.html"
    import UsersMarkerSet from "components/users/UsersMarkerSet.html"

    import Skeleton from "UI/Skeleton.html"
    import Tabs from "UI/Tabs.html"
    import Loader from "UI/Loader.html"
    import Backdrop from 'UI/Backdrop.html'
    import { successMsg, warningMsg, errorMsg, infoMsg } from 'UI/Messages.html'
    import Datepicker from 'UI/Datepicker.html'

    import dsService from 'services/DeliveryServicesService';

    const CLOSED_STATUSES = [5,6,7,8,9,10,11]

    let editRouteMode = false

    let previousDeliveryServices = []
    let deliveryServices = []
    let deliveryServicesMap = new Map();
    let activeServices = []
    let closedServices = []
    let filteredServices = []
    let statusList = []
    let usersList = []
    let onlyMyRegion = true
    let isInit = false
    let date = moment().format('YYYY-MM-DD')

    const CLOSED_SERVICES_TAB = "CLOSED_SERVICES_TAB"
    const ACTIVE_SERVICES_TAB = "ACTIVE_SERVICES_TAB"
    const USERS_TAB = "USERS_TAB"
    
    let activeTab = ACTIVE_SERVICES_TAB
    let tabs = [{
        key: CLOSED_SERVICES_TAB,
        name: "Закрытые заказы",
    },{
        key: ACTIVE_SERVICES_TAB,
        name: "Активные заказы",
    },{
        key: USERS_TAB,
        name: "Курьеры",
    }]
    
    let currentTime = new Date().getTime() / 1000;
    let timer = setInterval(() => {
        currentTime += 1
    }, 1000)
    let route = [];

    let usersChannelId;
    let deliveryServiceChannelId;

    const pusher = new Pusher('d1b94b39de0f97ebb3cf', {
        cluster: 'eu'
    });

    try {
        const beamsClient = new PusherPushNotifications.Client({
            instanceId: 'e797dc51-415d-4e37-9943-4410489c8f7b',
        });
    } catch (er) {
        console.error(er);
    }

    const expressEditRouteModeStoreUsubscribe = expressEditRouteModeStore.subscribe(v => {
        editRouteMode = v

        if (!editRouteMode) {
            $expressRoutePointsStore = [];
        }
    });

    const expressRoutePointsStoreUnsubscribe = expressRoutePointsStore.subscribe(v => {
        route = v.map(p => {
            return {
                lat: p.split(',')[0],
                lng: p.split(',')[1]
            }
        })
    });

    let fetchAllDeliveryServicesLoading = false
    const fetchAllDeliveryServices = async () => {
        if (fetchAllDeliveryServicesLoading) return;

        return new Promise(async (resolve, reject) => {
            fetchAllDeliveryServicesLoading = true

            const answer = await Phalcon.api.delivery.services.all({
                date_start: date,
                date_end: date,
                all_regions: !onlyMyRegion,
                limit: 999,
            })

            if(answer.status == 200) {
                deliveryServices = answer.payload.items.map(ds => DeliveryService.fromApi(ds));
            } else {
                errorMsg(answer.message)
            }

            fetchAllDeliveryServicesLoading = false

            resolve(answer);
        });
    }

    // const fetchAllStatusList = async () => {
    //     const answer = await Phalcon.api.delivery.services.statusList()

    //     if(answer.status == 200) {
    //         statusList = answer.payload
    //     } else {
    //         errorMsg(answer.message)
    //     }
    // }

    const getAllStatusList = async () => {
        await fetchAllStatusList()

        statusList = get(statusListStore)
    }

    let fetchAllCouriersLoading = false
    const fetchAllCouriers = async () => {
        if (fetchAllCouriersLoading) return

        fetchAllCouriersLoading = true

        const answer = await Phalcon.api.users.all({ 
            groups_id: 3,
            all_regions: !onlyMyRegion,
        })

        if(answer.status == 200) {
            usersList = answer.payload.items.sort((a, b) => {
                return (a.on_duty === b.on_duty)? 0 : a.on_duty? -1 : 1;
            });
        } else {
            errorMsg(answer.message)
        }

        fetchAllCouriersLoading = false
    }

    const fetchUsersLocation = async () => {
        if (usersList) {
            if(usersList.length) {
                let users_id = usersList.filter(u => u.on_duty).map(u => u.id);

                if (users_id.length == 0) return;

                const answer = await Phalcon.api.users.getLocation({
                    users_id: users_id.join(",")
                })

                if(answer.status == 200) {
                    if (answer.payload && Array.isArray(answer.payload)) {
                        answer.payload.forEach(location => {
                            if (location && location.user_id) {
                                usersList = usersList.map(user => {
                                    if(user.id == location.user_id){
                                        user.location = location

                                        if(
                                            deliveryServices.some(ds => {
                                                return ds.statusId != 11 && ds.pickups && ds.pickups.some(p => p.deliveryUserId == user.id && p.statusId == 11)
                                            }) 
                                        ) {
                                            user.status = 'BUSY'
                                        } else 
                                        if(
                                            deliveryServices.some(ds => {
                                                return ds.statusId != 11 && ds.pickups && ds.pickups.some(p => p.deliveryUserId == user.id && p.statusId == 2)
                                            })
                                        ) {
                                            user.status = 'WAITING'
                                        } else {
                                            user.status = 'FREE'
                                        }
                                    }

                                    return user
                                })
                            }
                        })
                    }
                } else {
                    errorMsg(answer.message)
                }
            }
        }
    }

    const autoUpdate = async () => {
        // await fetchAllDeliveryServices();
        await fetchUsersLocation();
    }
    
    let mapCenter
    let autoUpdateInterval = setInterval(() => autoUpdate(), 60000)

    const setMapCenterByService = service => () => {
        mapCenter = { 
            lat: service.lat,
            lng: service.lng
        }
    }

    const setMapCenterByUserLocation = user => () => {
        if (user.location) mapCenter = { 
            lat: user.location.lat,
            lng: user.location.lng
        }
    }

    const setMapCenter = event => {
        mapCenter = { 
            lat: event.detail.lat,
            lng: event.detail.lng
        }
    }

    function updateDeliveryServiceFromApi(data) {
        let newDS = DeliveryService.fromApi(data);

        deliveryServices = deliveryServices.map(ds => {
            if (ds.id == data.id) {
                if (newDS.statusId == 4) {
                    if (ds.statusId != newDS.statusId) {
                        let user = usersList.find(u => u.id == newDS.deliveryUserId);

                        if (user) {
                            warningMsg(`Курьер ${user.last_name} ${user.first_name} взял заказ №${newDS.number} в работу!`, 60000)
                        }
                    }
                }

                if (newDS.statusId == 11) {
                    if (ds.statusId != newDS.statusId) {
                        let user = usersList.find(u => u.id == newDS.deliveryUserId);

                        if (user) {
                            successMsg(`Курьер ${user.last_name} ${user.first_name} выполнил заказ №${newDS.number}!`, 60000)
                        }
                    }
                }

                ds = newDS;
            }

            if (ds.pickups) {
                ds.pickups = ds.pickups.map(pickup => {
                    if (pickup.id == data.id) {
                        if (newDS.statusId == 2) {
                            usersList = usersList.map(u => {
                                if (u.id == newDS.deliveryUserId) {
                                    if (!u.status || u.status == 'FREE') {
                                        u.status = 'WAITING';
                                    }
                                }

                                return u;
                            });
                        }

                        if (newDS.statusId == 11) {
                            let user;

                            usersList = usersList.map(u => {
                                if (u.id == newDS.deliveryUserId) {
                                    user = u;
                                    u.status = 'BUSY';
                                }

                                return u;
                            });

                            if (pickup.statusId != newDS.statusId) {
                                if (user) {
                                    successMsg(`Курьер ${user.last_name} ${user.first_name} забрал заказ №${newDS.number} из магазина!`, 60000)
                                }
                            }
                        }

                        pickup = newDS;
                    }
                    return pickup;
                })
            }

            return ds;
        })
    }

    let filterByRegionLoading = false;
    const filterByRegion = async () => {
        if (!isInit) return;

        filterByRegionLoading = true;
        await fetchAllDeliveryServices();
        await fetchAllCouriers();
        filterByRegionLoading = false;
    }

    const onDateChange = async () => {
        if (!isInit) return;

        await fetchAllDeliveryServices();
    }

    const toggleEditRouteMode = async () => {
        $expressEditRouteModeStore = !$expressEditRouteModeStore;
    }

    const R = 6371;

    function rad(x) {
       return x * Math.PI / 180;  
    }

    function getRadDistance(q,p) {
        var dLat = rad(q.lat - p.lat);
        var dLon = rad(q.lng - p.lng);
        var lat1 = rad(p.lat);
        var lat2 = rad(q.lat);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    const getRecommended = event => {
        let deliveryService = event.detail;
        let pickup = deliveryService.pickups ? deliveryService.pickups[0] : null;

        if (pickup) {
            deliveryServices.map(ds => {
                if (ds.deliveryUserId == 0 && ds.pickups && ds.pickups[0]) {
                    if (ds.pickups[0].lat == pickup.lat && ds.pickups[0].lng == pickup.lng) {
                        let distance = getRadDistance(deliveryService, ds);

                        if (distance > 0 && distance < 0.65) {
                            if (deliveryService.deliveryUserId == 0) {
                                $expressDSUserRecommendation[ds.id] = null;
                            } else {
                                let user = usersList.find(u => u.id == deliveryService.deliveryUserId);
                                $expressDSUserRecommendation[ds.id] = {
                                    text: `! Курьер '${user.last_name} ${user.first_name}' так же может доставить данный заказ`,
                                    deliveryUser: user
                                };
                            }
                        }
                    }
                }
            })
        }
    }

    $: onlyMyRegion, filterByRegion()
    $: date, onDateChange()
    $: activeServices = deliveryServices.filter(ds => !CLOSED_STATUSES.includes(ds.statusId))
    $: closedServices = deliveryServices.filter(ds => CLOSED_STATUSES.includes(ds.statusId))

    onMount(async () => {
        window.nav.set({
            breadcrumb: [{
                name: 'Экспресс заказы',
            }, {
                name: 'Окно диспетчера',
            }],
        });

        document.querySelector("#work-zone").classList.remove('padding');
        // document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
        window.scrollTo(0, document.body.scrollHeight);

        try {
            if (Notification) {
                Notification.requestPermission();
            }

            beamsClient.start()
                .then(() => beamsClient.addDeviceInterest(secretEl.value + '_deliveryServicesExpress'))
                .then(() => console.log('Successfully registered and subscribed!'))
                .catch(console.error);
        } catch (er) {
            console.error(er);
        }

        let secretEl = document.getElementById(window.location.host.split('.')[0]);

        usersChannelId = secretEl.value + '_users_channel';
        let usersChannel = pusher.subscribe(usersChannelId);

        usersChannel.bind('on_duty_toggle', function(data) {
            if (data) {
                if (data.payload) {
                    if (data.payload.on_duty !== null) {
                        usersList = usersList
                            .map(u => {
                                if (u && data.payload.id) {
                                    if (u.id == data.payload.id) {
                                        u.on_duty = data.payload.on_duty;
                                    }
                                }

                                return u;
                            })
                            .filter(u => u)
                            .sort((a, b) => {
                                return (a.on_duty === b.on_duty)? 0 : a.on_duty? -1 : 1;
                            });
                    }
                }
            }
        });

        deliveryServiceChannelId = secretEl.value + '_delivery_services_channel';
        let deliveryServiceChannel = pusher.subscribe(deliveryServiceChannelId);

        deliveryServiceChannel.bind('new', function(data) {
            if (data) {
                if (data.payload) {
                    if (data.payload.date) {
                        if (moment(data.payload.date).format('YYYY-MM-DD') == date) {
                            let ds = DeliveryService.fromApi(data.payload);
                            deliveryServices = [...deliveryServices, ds];
                            infoMsg(`В систему поступил новый заказ №${ds.number}`);
                        }
                    }
                }
            }
        });

        deliveryServiceChannel.bind('edit', function(data) {
            if (data) {
                if (data.payload) updateDeliveryServiceFromApi(data.payload);
            }
        });

        deliveryServiceChannel.bind('status_change', function(data) {
            if (data) {
                if (data.payload) updateDeliveryServiceFromApi(data.payload);
            }
        });

        // dsService.statusChangeObservable = new Observable(subscriber => {
        //     deliveryServiceChannel.bind('status_change', function(data) {
        //         if (data.payload) subscriber.next(
        //             DeliveryService.fromApi(data.payload)
        //         );
        //     });
        // });

        deliveryServiceChannel.bind('delivery_user_change', function(data) {
            if (data) {
                if (data.payload) updateDeliveryServiceFromApi(data.payload);
            }
        });

        await fetchAllRegions()
        await fetchAllDeliveryServices()
        await getAllStatusList()
        await fetchAllCouriers()
        await fetchUsersLocation()

        isInit = true;
    });

    onDestroy(() => {
        document.querySelector("#work-zone").classList.add('padding');
        // document.querySelector('meta[name="viewport"]').setAttribute('content', '');
        clearInterval(autoUpdateInterval);
        clearInterval(timer);

        pusher.unsubscribe(usersChannelId);
        pusher.unsubscribe(deliveryServiceChannelId);

        expressEditRouteModeStoreUsubscribe();
        expressRoutePointsStoreUnsubscribe();

        $expressRoutePointsStore = [];
        $expressEditRouteModeStore = 0;
        $expressDSUserRecommendation = {};
    })
</script>

<div class="uk-width uk-grid uk-grid-collapse wrapper">
    <div class="uk-width-large uk-padding-small uk-height-1-1 uk-overflow-auto uk-position-relative" id="delivery-services-list">
        <Tabs items={tabs} bind:active={activeTab} />
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-medium">
                <Datepicker bind:value={date} />
            </div>
            <div class="uk-width-expand">
                <input class="uk-checkbox uk-margin-small-right" type="checkbox" bind:checked={onlyMyRegion} />Мой регион
            </div>
        </div>
        <button on:click={fitBounds}>Показать все на карте</button>
        {#if editRouteMode}
            <button on:click={toggleEditRouteMode}>Откл. режим маршрута</button>
        {:else}
            <button on:click={toggleEditRouteMode}>Вкл. режим маршрута</button>
        {/if}
        {#if activeTab == CLOSED_SERVICES_TAB}
            {#if closedServices.length}
                {#each closedServices as item (item.id)}
                    <DeliveryServicesItem 
                        { statusList }
                        { usersList }
                        deliveryService={item}
                        showRegion={!onlyMyRegion}
                        on:pickupIconClick="{setMapCenterByService(item.pickups ? item.pickups[0] : null)}"
                        on:deliveryIconClick={setMapCenterByService(item)} 
                    />
                {/each}
            {:else}
                <div class="uk-height-1-1 uk-width uk-flex uk-flex-center uk-flex-middle">
                    Нет закрытых заказов
                </div>
            {/if}
        {:else if activeTab == ACTIVE_SERVICES_TAB}
            {#if activeServices.length}
                {#each activeServices as item (item.id)}
                    <DeliveryServicesItem 
                        { statusList }
                        { usersList }
                        { currentTime }
                        deliveryService={item}
                        showRegion={!onlyMyRegion}
                        on:pickupIconClick={setMapCenter}
                        on:deliveryIconClick={setMapCenter}
                        on:userChange={getRecommended}
                    />
                {/each}
            {:else}
                <div class="uk-height-1-1 uk-width uk-flex uk-flex-center uk-flex-middle">
                    Нет активных заказов
                </div>
            {/if}
        {:else if activeTab == USERS_TAB}
            {#if usersList.length}
                {#each usersList.filter(u => u.on_duty) as item}
                    <UsersMapListItem 
                        { statusList }
                        { usersList }
                        { currentTime }
                        { ...userFromRexApi(item) }
                        showRegion={!onlyMyRegion}
                        deliveryServices="{deliveryServices.filter(s => s.deliveryUserId == item.id && s.statusId != 11)}"
                        on:pickupIconClick={setMapCenter}
                        on:deliveryIconClick={setMapCenter}
                        on:locationIconClick={setMapCenter}
                    />
                {/each}
            {:else}
                <div class="uk-height-1-1 uk-width uk-flex uk-flex-center uk-flex-middle">
                    Нет курьеров
                </div>
            {/if}
        {/if}

        {#if filterByRegionLoading || fetchAllCouriersLoading || fetchAllDeliveryServicesLoading}
            <Backdrop>
                <div uk-spinner></div>
            </Backdrop>
        {/if}
    </div>
    <div class="uk-width-expand uk-position-relative">
        <GoogleMap center={mapCenter}>
            {#if activeTab == CLOSED_SERVICES_TAB}
                <DeliveryServicesMarkerSet list={closedServices} />
            {:else if activeTab == ACTIVE_SERVICES_TAB || activeTab == USERS_TAB}
                <DeliveryServicesMarkerSet list={activeServices} />
            {/if}

            <UsersMarkerSet list={usersList} />
            {#if $expressEditRouteModeStore}
                <Route 
                    points={route} 
                    mode="walking" 
                    on:clear="{() => $expressRoutePointsStore = []}"
                    on:cancel="{() => $expressEditRouteModeStore = false}" />
            {/if}
        </GoogleMap>
    </div>
</div>
  
<style>
    .wrapper {
        height: 100%;
    }

    .tool {
        width: 500px !important;
    }

    :global(.grecaptcha-badge) {
        display: none;
    }

    .tools {
        position: fixed !important;
        z-index: 999;
    }
</style>