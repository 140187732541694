<script>
  import Phalcon from 'modules/phalcon.js';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let actions = ['all'],
    groups = false;

  let current = {};

  if (!groups){
    Phalcon.groups.all()
      .then( answer => {
        if( answer.status === 200){
          delete answer.status;
          groups = answer;
        } else {
          throw new Error ('something went wrong');
        }})
      .catch( err => console.error( err.message));
  }

    function setActive(string) {
      dispatch('filterGroup', string);
      current = string;
    }
</script>

<style lang="scss">.active {
  background: #e2e2e2; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    list-style: none;
    padding: 0 6px; }
    ul li:hover {
      background: #e4e4e4; }
</style>

<div class="uk-container">
  {#if groups}
    <ul class="uk-flex uk-margin-small-bottom">

      {#if actions.includes('all')}
        <li class:active="{current === 'all'}" on:click="{() => setActive({key:'all', name:'Все'})}" class="uk-width-auto">Все</li>
      {/if}

      {#each groups as group, index}
        <li class:active="{current === groups[index].key}" on:click="{() => setActive(groups[index])}" class="uk-width-auto ">
          {group.name}
        </li>
      {/each}
      {#if actions.includes('add')}
        <li class="uk-width-auto">Добавить</li>
      {/if}

    </ul>
  {/if}
</div>