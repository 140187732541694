<script>
  import Button from 'main/Mobile/UI/Buttons/Button.html'
  import Router from 'modules/router.js';
  import Validator from 'modules/validator.js';
  import { routeStore } from "main/Mobile/stores/stores.js";

  let routeId;
  const baseRouteString = `yandexnavi://build_route_on_map?`;

  const unsubscribe = routeStore.subscribe(value => {
    routeId = value;
  });

  function concatRoute () {
    let tempRouteString = baseRouteString;
    tempRouteString += `lat_to=${routeId.lat}&lon_to=${routeId.lon}`;
    return tempRouteString;
  };

  function getMask (data){
    let mask = Validator.modifier.phoneToMask(data);
    return mask;
  }

  let id;
  let temp = window.location.pathname.split('/');
  id = temp.pop();
  id = parseInt(id);

  if (typeof parseInt(id) === "number" && !isNaN(parseInt(id))){

  } else {
    // Phalcon.request('points','get','GET',{
    //   id: id
    // })
    //   .then( answer => {
    //     if (answer.status === 200){
    //       ////console.log('answer id is:', answer)
    //     } else {
    //       throw new Error();
    //     }
    //   })
    //   .catch( err => {}( err.message ));
    ////console.log('Going main page!');
    ////console.log(isNaN(parseInt(id)));
    window.history.state === null ? Router.goTo('') : window.history.back()

  }
  let mainAdds = false;
  let initialVal = 0;
  // let totalSum = props.application.products.reduce( (acc, currentItem) => {
  //   return  acc + (currentItem.count * currentItem.price)}, initialVal);

  function getTotalPrice(){
    let totalSum = routeId.application.products.reduce( (acc, currentItem) => {
      return  acc + (currentItem.count * currentItem.price)}, initialVal);
    return totalSum;
  };
  function getClientPhone () {
    let basic = `tel:`;
    return basic += routeId.application.client.phone
  };

</script>

<style lang="scss">.uk-container {
  overflow-y: auto;
  height: 100vh;
  background: #FFFFFF;
  font-size: 0.8rem; }

.nav__head {
  height: 3rem;
  background: #28B446;
  font-size: 1.3rem;
  color: #ffffff; }

img {
  width: 1.5rem;
  height: 1.5rem; }

.uk-link-muted {
  text-decoration: underline; }

tr {
  border: 1px solid #c4c4c4; }

.route__avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%; }

ul {
  margin: 0;
  border: 1px solid #c4c4c4; }
  ul li {
    list-style: none; }

img {
  vertical-align: text-bottom; }

.uk-link {
  text-decoration: underline;
  font-size: 0.8rem; }
</style>
<div class="uk-container">
<!--header-->
  <div class="nav__head uk-position-z-index uk-position-top uk-position-fixed uk-flex uk-flex-middle uk-width-1-1 uk-flex-between ">
    <div class="uk-margin-left">
      Заказ {routeId.id}
    </div>
    <div>
      <span class="uk-margin-right"><a href="{ getClientPhone() }" ><img src="/svg/icons/call.svg" alt=""></a></span>
      <span class="uk-margin-right"><a href={ concatRoute() }><img src="/svg/icons/gps.svg" alt=""></a></span>
    </div>
  </div>
<!-- header -->

<!--main info-->
  <div class="uk-margin-xlarge-top">

    <div class="uk-text-muted">Контактное лицо:</div>
    <div class="uk-margin-small-bottom uk-flex uk-flex-between"><span>{routeId.application.client.name}</span><a class="uk-link">{getMask(routeId.application.client.phone)}</a></div>

    <div class="uk-text-muted">Контрагент:</div>
    <div class="uk-margin-small-bottom uk-flex uk-flex-between">
      <span>{routeId.legals[0]}</span>
    </div>

    <div class="uk-text-muted">Адрес:</div>
    <div class="uk-margin-small-bottom uk-flex uk-flex-between"><span>{routeId.address}</span></div>

<!--    {#if mainAdds}-->
<!--    <div class="uk-flex uk-flex-center"><a on:click="{ () => mainAdds = false}" class="uk-link-muted">Свернуть</a></div>-->
<!--    <div>Контрагент:</div>-->
<!--    <div class="uk-flex uk-flex-between"><span>Василий Пупкин</span><a>{getMask('9250979243')}</a></div>-->
<!--    <div>Контрагент:</div>-->
<!--    <div class="uk-flex uk-flex-between"><span>Василий Пупкин</span><a>{getMask('9250979243')}</a></div>-->
<!--    <div>Контрагент:</div>-->
<!--    <div class="uk-flex uk-flex-between"><span>Василий Пупкин</span><a>{getMask('9250979243')}</a></div>-->
<!--    <div>Контрагент:</div>-->
<!--    <div class="uk-flex uk-flex-between"><span>Василий Пупкин</span><a>{getMask('9250979243')}</a></div>-->
<!--    {:else}-->
<!--    <div class="uk-flex-center uk-flex"><a on:click="{ () => mainAdds = true}" class="uk-link-muted">еще</a></div>-->
<!--    {/if}-->
<!--    <hr class="uk-margin-small-top uk-margin-small-bottom">-->
  </div>
<!--main info-->

<!-- products -->
  {#if routeId.application.products.length > 0}
  <div>

    <table class="uk-table uk-margin-small-bottom">
      <caption class="uk-text-center uk-margin-small-bottom"><span>Товары ({routeId.application.products.length}):</span></caption>
      <tbody>
      {#each routeId.application.products as item}
      <tr><td class="uk-width-1-6">{item.article}</td><td class="uk-width-3-6">{item.name}</td><td class="uk-width-1-6">{item.count}шт.</td><td class="uk-width-1-6">{item.count * item.price}p</td></tr>
      {/each}
      </tbody>
    </table>
    <div class="uk-flex uk-flex-between uk-margin-small-left uk-margin-small-right">
      <span>Итого:</span>
      <span>{getTotalPrice()}р</span>
<!--      <span>{totalSum}р</span>-->
    </div>
    <hr class="uk-margin-small-top uk-margin-small-bottom">

  </div>
  {/if}
<!-- products -->

<!-- comments -->
  <!-- {#if routeId.application.comments}
    <div class="uk-margin-xlarge-bottom">
      <div class="uk-text-center uk-margin-small-bottom"><span>Комментарии:</span></div>
      <ul class="uk-padding-small">
        <li class="uk-flex">
          <div class="uk-width-1-6 uk-flex-center uk-flex-middle uk-flex">
            <img class="route__avatar" src="/img/avatar.png" alt="avatar">
          </div>
          <div class="uk-margin-small-left">
            <div>{props.application.comments.main.name}</div>
            <div>{props.application.comments.main.content}</div>
          </div>
          <div>{props.application.comments.main.time}</div>
        </li>
        <li class="uk-text-muted">
          <div class="uk-flex-between uk-flex">
            <span>Отмечено товаров - {props.application.products.length}. <a class="uk-link-muted">Развернуть</a></span><span>{props.application.comments.assortment.time}</span>
          </div>
        </li>
        <li class="uk-text-muted">
          <div class="uk-flex-between uk-flex">
            <span>{props.application.comments.status.name}</span>
            <span>{props.application.comments.status.time}</span>
          </div>
        </li>
      </ul>
    </div>
  {/if} -->
<!-- comments -->

<!--footer-->
  <div class="uk-position-bottom uk-position-fixed uk-flex">
    <Button on:click="{ () => Router.goTo('points') }" name="Назад" type="passive"/>
    <Button on:click="{ () => Router.goTo('prod-items') }" name="Выполнить" type="confirm"/>
  </div>
<!-- footer -->
</div>