<script>
    import Clipboard from "svelte-clipboard"
    import { TableBodyItem, View, Button, TableRow, infoToast } from 'svelte-metro-ui'
    import { Modal, Link, Icon } from 'svelte-uikit-ui'
    import { phoneFormat } from '../../../helpers/format.helper'
    import { VehiclesOrdersAgreeForm } from '.'
    import { EditForm } from '../vehicles';
    import { RentUsersEditForm } from '../rentUsers'

    export let item = {}
    let id = undefined 
    let name = undefined
    let lastName = ''
    let phone = undefined
    let term = undefined
    let vehicle = undefined
    let vehicleName = undefined
    let vehicleId = undefined
    let rentUser = undefined
    let rentUserId = undefined
    let agreeOrderModalShow = false
    let showVehicleEditModal = false
    let showRentUserEditModal = false
    let isAgree = false
    let verifyUrl = undefined

    const handleShowAgreeOrderModal = () => agreeOrderModalShow = true
    const handleHideAgreeOrderModal = () => agreeOrderModalShow = false
    const handleShowEditVehicleModal = () => {
        if (vehicleId) showVehicleEditModal = true
    }
    const handleShowEditRentUserModal = () => {
        if (rentUserId) showRentUserEditModal = true
    }
    const handleCopyText = el => {
      el.select();
      el.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
    }

    function init() {
        id = item.id
        name = item.user_name
        lastName = item.user_last_name || ''
        phone = item.user_phone
        term = item.tariff ? item.tariff.term : undefined
        vehicle = item.vehicle
        rentUser = item.rent_user
        isAgree = item.is_agree
        if (vehicle) {
            vehicleName = `${vehicle.mark} ${vehicle.model} ${vehicle.number}`
            vehicleId = vehicle.id
        }
        if (rentUser) {
            rentUserId = rentUser.id
        }
    }
    
    $: item, init()
</script>

<Modal bind:show={showRentUserEditModal} bgClose={false} close>
    <RentUsersEditForm id={rentUserId} on:done={() => showRentUserEditModal = false} />
</Modal>

<Modal bind:show={showVehicleEditModal} bgClose={false} close>
    <EditForm id={vehicleId} on:done={() => showVehicleEditModal = false} />
</Modal>

<Modal bind:show={agreeOrderModalShow} bgClose={false} close>
    <VehiclesOrdersAgreeForm 
        vehicleOrder={item} 
        vehicleId={vehicleId} 
        on:done={handleHideAgreeOrderModal} 
    />
</Modal>

<TableRow>
    <TableBodyItem>{id}</TableBodyItem>
    <TableBodyItem>
        {#if rentUser}
            <Link on:click={handleShowEditRentUserModal}>{name} {lastName}</Link>
        {:else}
            {name} {lastName}
        {/if}
    </TableBodyItem>
    <TableBodyItem>
        <div class="uk-flex uk-flex-middle">
            {#if phone}
                <a href="tel:{phoneFormat(phone)}">{phoneFormat(phone)}</a>
                {#if rentUser && rentUser.is_agree}
                    <div class="green-circle uk-margin-small-left uk-flex uk-flex-middle uk-flex-center" uk-tooltip="Телефон подтвержден">
                        <Icon>check</Icon>
                    </div>
                {/if}
            {:else}
                -
            {/if}
        </div>
    </TableBodyItem>
    <TableBodyItem>
      {#if isAgree}
        <Clipboard
          text={`https://rexpro.ru?loginModal=true&phone=${phoneFormat(phone, '%2b', '%20')}`}
          let:copy
          on:copy={() => infoToast('Ссылка скопирована')}>
          <a href="#" on:click={copy}>
            Скопировать
          </a>
        </Clipboard>
      {:else}
        -
      {/if}
    </TableBodyItem>
    <TableBodyItem>{term || '-'}</TableBodyItem>
    <TableBodyItem>
        <Link on:click={handleShowEditVehicleModal}>
            {vehicleName || '-'}
        </Link>
    </TableBodyItem>
    <TableBodyItem>
        {#if isAgree}
            Согласовано
        {:else}
            <Button on:click={handleShowAgreeOrderModal} success>
                Согласовать
            </Button>
        {/if}
    </TableBodyItem>
</TableRow>

<style>
    .green-circle {
        color: white !important;
        background-color: #6feb6f !important;
        width: 18px;
        height: 18px;
        padding: 2px;
        border-radius: 100%;
    }
</style>