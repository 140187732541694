<script>
    export let value = '0';
    export let isFloat = false;
    export let textRight = false;

    if (!value) value = '';

    function getTransformation (payload) {
       return isFloat ? getFloat(payload) : getInteger(payload);
    }
    function getInteger(str) {
        return str.replace(/^([0*\D])|(\D)/g, '');
    }
    function getFloat(str) {
        //TODO correct float regex
        return str.replace(/^([0*\D])|(\D)/g, '');
    }

    $: value = getTransformation(value.toString());
</script>

<input class:uk-text-right={textRight}
       class="uk-input"
       placeholder="0"
       type="text"
       bind:value={value}>

<style lang="scss">input {
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  font-size: 14px;
  height: 30px; }
</style>