<script>
  import { onMount, createEventDispatcher } from "svelte";

  import Phalcon from "modules/phalcon.js"

  import Legals from 'components/legals/LegalsSelect.html';

  import Modal from 'UI/Modal.html';
  import Input from 'UI/Input.html';
  import InputNumber from 'UI/InputNumber.html';
  import Button from 'UI/ButtonOld.html';
  import WindowButton from 'UI/ButtonWindowClose.html';
  import Slider from "UI/Slider.html";
  import FilesCollection from "UI/FilesCollection.html";

  export let legal;
  export let modal;
  export let defaultProps = { 
    article: null, 
    name: null, 
    price: null, 
    selling_price: null, 
    width:'', 
    depth: '', 
    height: '',
     weight: ''
  };
  export let data = defaultProps;
  
  const dispatch = createEventDispatcher();

  let alpha = {};
  let size = 0;
  let condition = {
    name: null,
    email: null,
    phone: null
  };
  let imagesData;

  function sendData () {
    let reqData = {};
    if (data && data.name){
      for (let key in data){
        if(data[key] !== null && data[key].length !== 0) reqData[key] = data[key];
      }
      Phalcon.request('products', 'create', 'POST', {
        products:{...reqData}
      }).then( answer => {
          if (answer.status === 200) {
            delete answer.status;
            dispatch('success', {...answer});
            Phalcon.files.upload('products', 'images', answer.id, imagesData).then(filesAnswer => {
            });

            UIkit.notification({
              message: `Добавлен новый товар'${data.name}'`,
              status: 'success',
              pos: 'bottom-right',
              timeout: 5000
            });
            dispatch('close');
            dispatch('refresh');
          } else {
            throw new Error(`${answer.status}`)
          }
        })
        .catch( err => {
          UIkit.notification({
            message: {err},
            status: 'warning',
            pos: 'bottom-right',
            timeout: 5000
          });
        })
    } else {
      UIkit.notification({
        message: 'Заполните обязательное поле Наименование!',
        status: 'warning',
        pos: 'bottom-right',
        timeout: 5000
      });
    }
  }

  $: if (data){
    size = (data.width * data.height * data.depth) / 1000000;
  }
  const close = () => dispatch("close");

</script>



  <Modal modalType="products" {...modal} on:close on:ready>
    <div class="uk-flex uk-flex-between" slot="header">
      <div>Создание товара</div>
      <WindowButton type="plus" on:click={close}></WindowButton>
    </div>

    <div slot="body" class="uk-flex uk-padding-remove">
      <div class="uk-width-2-5">
        <Slider bind:formData={imagesData} />
        <FilesCollection />
      </div>
      <div class="uk-width-3-5">
        <div class="uk-flex uk-flex-between">
          <div>Артикул</div>
          <div class="uk-width-2-3">
            <Input  name="Артикул" type="text" bind:value={data.article} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Наименование <span class="required">*</span></div>
          <div class="uk-width-2-3">
            <Input  requireName="Наименование" required="true" bind:validCallback="{condition.name}" type="text" placeholder="Введите название" bind:value="{data.name}"/>
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Контрагент</div>
          <div class="uk-width-2-3">
            <Legals type="text" placeholder="Начните ввод данных" bind:data="{alpha.point}" bind:text="{alpha.legal}"/>
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Цена продажи</div>
          <div class="uk-width-2-3">
            <Input  name="Цена продажи" type="number" bind:value={data.price} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Цена закупки</div>
          <div class="uk-width-2-3">
            <Input  name="Цена закупки" type="number" bind:value={data.selling_price} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Вес в кг.</div>
          <div class="uk-width-2-3">
            <InputNumber isFloat={true} bind:value={data.weight} />
          </div>
        </div>

        <div class="uk-flex uk-flex-between">
          <div>Габариты в см.</div>
          <div class="uk-flex uk-flex-middle uk-width-2-3 text__params">
            <div class="uk-text margin-xsmall-right">Ш</div>
            <div><InputNumber bind:value={data.width} /></div>
            <div class="uk-flex"><span class="uk-text-meta margin-xsmall-left">x</span> <span class="uk-text margin-xsmall">Г</span></div>
            <div><InputNumber bind:value={data.depth} /></div>
            <div class="uk-flex"><span class="uk-text-meta margin-xsmall-left">x</span> <span class="uk-text margin-xsmall">В</span></div>
            <div><InputNumber bind:value={data.height} /></div>
          </div>
        </div>

        <div>
          <div class="uk-flex uk-flex-right uk-margin-auto-left uk-width-2-3">
            <div>Объем: {size} м3</div>
          </div>
        </div>

      </div>
    </div>

    <div slot="footer" class="uk-flex uk-flex-right">
      <Button name="Сохранить" on:click={ sendData } status="success"/>
      <Button name="Отменить" on:click={ ()=> dispatch('close')} status="cancel"/>
    </div>
  </Modal>

<style>
  .wrapper{
    font-size: 14px;
  }
  .circle-image {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
  }
  .text__params{
    font-size: 12px;
  }

  .margin-xsmall{
    margin-right: 3px;
    margin-left: 3px;
    font-size: 16px;
  }

  .margin-xsmall-right {
    margin-right: 3px;
    font-size: 16px;
  }

  .margin-xsmall-left {
    margin-left: 3px;
    font-size: 14px;
    line-height: 1.8;
  }
</style>