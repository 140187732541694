<script>
  import { createEventDispatcher } from 'svelte';

  import textTransform from "modules/textTransform.js";

  import Accordion from "UI/Accordion.html"
  import Button from "UI/ButtonOld.html"

  export let test = null;
  export let endTime;
  
  const dispatch = createEventDispatcher();

  let price = 0;

  function countToStr(num) {
   return textTransform.modifier.num2endStr(num,["пользователь","пользователя","пользователей"]);
  }

  $: price = parseInt(test.price).toFixed(0) - (test.discount || 0);
  // $: if (test && test.expire) {
  //   endTime = moment(test.expire).format('DD.MM.YY HH:mm');
  // }
</script>

<div class:wrapper__focus="{test.popular}" class="wrapper uk-position-relative  wrap">

  {#if test.popular}
    <div class="label uk-text-center uk-width-1-1">Самый популярный</div>
  {/if}

  <div  class:active="{test.active}"  class="uk-card uk-card-body uk-card-default uk-padding-small">

    <slot name="title">
      <h2 class="uk-card-title uk-text-center uk-text-bold">Тариф "{test.name}"</h2>
    </slot>

    <slot name="users">
      <div class="uk-text-center">
      <div class="uk-text-lead">{test.limits[0].min}</div>
      <div >{countToStr(test.limits[0].value)} включено</div>
      </div>
    </slot>

    <slot name="price">
      <div class="uk-text-center" >
        {#if test.discount > 0}
          <del>
            {parseInt(test.price * 6).toFixed(0)}
          </del>
          <div class="price" style="margin-top: -15px">
            {price}
            <span class="currency">₽</span>
          </div>
        {:else}
          <div class="price">
            {price}
            <span class="currency">₽</span>
          </div>
        {/if}
        <div class="timeCount">за 6 месяцев</div>
      </div>
    </slot>

    <slot name="action">
      <div class="uk-text-center uk-margin">

        {#if test.next && !test.active}
          <Button on:click="{ ()=> dispatch('abort', {id: test.id})}"
                  name="Отменить"
                  status="disable" size="tariff" />
        {:else }
          <Button on:click="{ ()=> dispatch('submit', {id: test.id, name: test.name, price: test.price}) }"
                  isDisabled="{test.active || test.next}"
                  name="{test.active ? 'Активно' : 'Подключить'}"
                  status="success" size="tariff"/>
        {/if}

        {#if test.expire && test.key !== 'free'}
          <div class="uk-text-muted uk-text-small">Активен до {endTime}</div>
        {:else if test.next}
          <div class="uk-text-muted uk-text-small">Вступит в силу с {endTime}</div>
        {/if}

      </div>
    </slot>

    <slot name="options">
      <ul>
        {#each test.features as item}
          <li>
            <div>
              <img src="/svg/icons/check.svg" alt="">{item}
            </div>
          </li>
        {/each}
      </ul>
    </slot>

    <slot name="confines">
      {#if test.features_limits}
        <div class="confines">
          <h5>Ограничения</h5>
          <ul>
            {#each test.features_limits as item}
            <li><img src="/svg/icons/uncheck.svg" alt="">{item}</li>
            {/each}
          </ul>
        </div>
      {/if}
    </slot>

    <slot name="adds">
<!--      <Accordion bind:test="{test.limits}" bind:data={data.adds} name="Допольнительные опции"/>-->
    </slot>

  </div>

</div>

<style lang="scss">.wrap {
  min-width: 200px;
  max-width: 270px; }
  .wrap .active {
    background: rgba(251, 187, 0, 0.1); }

.uk-accordion-title {
  margin-top: 40px;
  font-size: 16px; }

.wrapper {
  margin-top: 25px;
  font-size: 16px; }

.currency {
  font-size: 40px; }

.price {
  font-size: 54px; }

.wrapper__focus {
  margin-top: 25px; }
  .wrapper__focus .uk-card {
    border: 2px solid #e30613;
    border-radius: 0; }

img {
  vertical-align: baseline;
  margin-right: 3px; }

.uk-card {
  padding-top: 40px; }

.uk-text-lead {
  color: #e30613;
  font-size: 54px;
  line-height: 63px; }

h1 {
  margin: 0;
  font-size: 48px; }

h2 {
  margin: 0px; }

ul {
  margin: 0;
  padding: 0; }

li {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #000000;
  list-style: none; }

.timeCount {
  font-size: 16px;
  color: #c4c4c4; }

.label {
  height: 30px;
  line-height: 32px;
  background: #e30613;
  position: absolute;
  top: -30px;
  color: #ffffff;
  padding: 0;
  margin: 0; }

.confines li,
.confines h5 {
  color: #c4c4c4;
  margin: 0;
  padding: 0; }

del {
  font-size: 30px; }
</style>


