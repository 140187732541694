<script>
  import { restore } from "effector"
  import { onMount } from "svelte"
  import {
    TableBodyItem,
    View,
    Button,
    Icon,
    Input,
    TableRow,
    Form,
    Checkbox,
    CalendarPicker,
    Dropdown,
  } from "svelte-metro-ui"
  import { DatePicker, DatePickerInput } from "carbon-components-svelte"
  import { Russian } from "flatpickr/dist/l10n/ru.js"
  import { Spinner, Modal, Icon as UIcon } from "svelte-uikit-ui"
  import { currency, phoneFormat } from "../../../helpers/format.helper"
  import cashflowStore, {
    createCashflow,
    loading,
  } from "../../../storesEffector/cashflow"
  import "../../../storesEffector/cashflow/rex.init"
  import { rentUsersContractEnd } from "../../../servicesEffector/rentUsers"
  import { updateRentUser } from "../../../storesEffector/rentUsers/rex.init"
  import {
    RentUsersEditForm,
    RentUsersCreateTariffForm,
    RentUsersEditTariffForm,
  } from "."
  import { EditForm } from "../vehicles"
  import {
    createCashflowModalSetDataEv,
    createCashflowModalShowToggleEv,
  } from "./Modals.svelte"
  import { getRentUsersTariffs } from "../../../servicesEffector/rentUsersTariffs"

  export let item = {}
  const createCashflowLoading = loading.map((state) => state.create)
  const createCashflowResult = restore(
    cashflowStore.createFx.doneData,
    null
  ).map((result) => (result?.payload.rent_users_id == item.id ? result : null))
  let id = undefined
  let phone = undefined
  let name = undefined
  let lastName = undefined
  let vehicleName = undefined
  let vehicleId = undefined
  let vehicles = []
  let balance = undefined
  let deposit = undefined
  let amount = undefined
  let tariff = undefined
  let allTariffs = []
  let isAgree = undefined
  let vehicleAmount = 0
  let monthPayment = 0
  let dayPayment = 0
  let term = undefined
  let isDepositCashflow = false
  let showEditModal = false
  let showCreateTariffModal = false
  let showEditTariffModal = false
  let showVehicleEditModal = false
  let contractDate = undefined
  let contractEndAt = undefined
  let date = moment().format("YYYY-MM-DD")
  let conractEndLoading = false
  let tariffVehicle
  let vehicleTariff
  let editVehicle
  let dropdownEl

  const handleRentUserEdit = () => (showEditModal = true) // showEditWindow(item)
  const handleShowCashflowModal = () => {
    createCashflowModalSetDataEv({
      lastName,
      name,
      phone,
      vehicleName,
      rentUserId: id,
    })
    createCashflowModalShowToggleEv(true)
  }
  const handleVehicleTariffEdit = (vehicle) => async () => {
    UIkit.dropdown(dropdownEl).hide(0)

    tariffVehicle = vehicle

    const result = await getRentUsersTariffs({
      rent_users_id: id,
      vehicles_id: vehicle.id,
    })
    console.log({ result })

    if (result.status == 200) {
      if (result.payload.rent_user_tariff) {
        vehicleTariff = result.payload.rent_user_tariff
        showEditTariffModal = true
      } else {
        showCreateTariffModal = true
      }
    }
  }
  const handleShowEditVehicleModal = (vehicle) => () => {
    if (vehicle.id) {
      editVehicle = vehicle
      showVehicleEditModal = true
    }
  }
  const handleRentUserEditTariff = () => {
    if (item.tariff) {
      // showEditTariffWindow(item)
      showEditTariffModal = true
    } else {
      // showCreateTariffWindow(item)
      showCreateTariffModal = true
    }
  }
  const handleContractEnd = async () => {
    if (!confirm("Вы уверены что хотите отменить договор?")) return
    conractEndLoading = true
    await rentUsersContractEnd(id)
    conractEndLoading = false
  }
  const handleCreateCashflow = () => {
    let description = isDepositCashflow
      ? `Операция "Залог" по`
      : `Пополнение баланса арендатора`
    if (lastName) description += ` ${lastName}`
    if (name) description += ` ${name}`
    if (phone) description += ` ${phoneFormat(phone)}`
    if (vehicleName) description += ` по машине ${vehicleName}`

    console.log({ isDepositCashflow })
    createCashflow({
      category_id: 10,
      description,
      amount,
      cashflow_boxes_id: 9,
      date,
      rent_users_id: id,
      deposit: isDepositCashflow,
    })
  }

  onMount(() => {
    const unwatch = createCashflowResult.watch((result) => {
      if (result) {
        amount = undefined
        updateRentUser({
          ...item,
          balance:
            balance +
            (result.payload.deposit ? 0 : parseFloat(result.payload.amount)),
          deposit:
            deposit +
            (result.payload.deposit ? parseFloat(result.payload.amount) : 0),
        })
      }
    })

    return () => unwatch()
  })

  function init() {
    id = item.id
    phone = item.phone
    name = item.first_name
    lastName = item.last_name
    isAgree = item.is_agree
    contractDate = item.contract_date
    contractEndAt = item.contract_end_at
    if (item.vehicle) {
      vehicleName = `${item.vehicle.mark} ${item.vehicle.model} ${item.vehicle.number}`
      vehicleId = item.vehicle.id
    }
    if (item.all_vehicles) {
      vehicles = item.all_vehicles
    }
    if (item.all_tariffs) {
      allTariffs = item.all_tariffs
    }
    balance = item.balance ? parseFloat(item.balance) : 0
    deposit = item.deposit ? parseFloat(item.deposit) : 0
    if (allTariffs.length) {
      vehicleAmount = allTariffs.reduce((acc, item) => {
        return acc + parseInt(item.total_vehicle_amount)
      }, 0)
      monthPayment = allTariffs.reduce((acc, item) => {
        return acc + parseInt(item.month_payment)
      }, 0)
      dayPayment = allTariffs.reduce((acc, item) => {
        return acc + parseInt(item.day_payment)
      }, 0)
      tariff = allTariffs[0]
      term = tariff.term
    } else if (item.tariff) {
      tariff = item.tariff
      vehicleAmount = tariff.total_vehicle_amount || 0
      monthPayment = tariff.month_payment || 0
      dayPayment = tariff.day_payment || 0
      term = tariff.term
    }
  }

  $: item, init()
</script>

<Modal bind:show={showVehicleEditModal} bgClose={false} close>
  <EditForm
    id={editVehicle.id}
    on:done={() => (showVehicleEditModal = false)}
  />
</Modal>

<Modal bind:show={showEditModal} bgClose={false} close>
  <RentUsersEditForm {id} on:done={() => (showEditModal = false)} />
</Modal>

<Modal bind:show={showEditTariffModal} bgClose={false} close>
  <RentUsersEditTariffForm
    rentUser={item}
    tariff={vehicleTariff}
    on:done={() => (showEditTariffModal = false)}
  />
</Modal>

<Modal bind:show={showCreateTariffModal} bgClose={false} close>
  <RentUsersCreateTariffForm
    rentUser={item}
    vehicle={tariffVehicle}
    on:done={() => (showCreateTariffModal = false)}
  />
</Modal>

<TableRow class={contractEndAt ? "ribbed-red fg-white" : ""}>
  <TableBodyItem>{id}</TableBodyItem>
  <TableBodyItem>
    <div class="uk-flex uk-flex-middle" style="min-width: 180px;">
      {#if phone}
        <a class:white={contractEndAt} href="tel:{phoneFormat(phone)}"
          >{phoneFormat(phone)}</a
        >
        {#if isAgree}
          <div
            class="green-circle uk-margin-small-left uk-flex uk-flex-middle uk-flex-center"
            uk-tooltip="Телефон подтвержден"
          >
            <UIcon>check</UIcon>
          </div>
        {/if}
      {:else}
        -
      {/if}
    </div>
  </TableBodyItem>
  <TableBodyItem>{name || "-"}</TableBodyItem>
  <TableBodyItem>{lastName || "-"}</TableBodyItem>
  <TableBodyItem>
    {#each vehicles as vehicle}
      <a
        class:white={contractEndAt}
        on:click={handleShowEditVehicleModal(vehicle)}
      >
        {vehicle.mark}
        {vehicle.model}
        {vehicle.number}
      </a>
    {/each}
  </TableBodyItem>
  <TableBodyItem>{currency(vehicleAmount)}</TableBodyItem>
  <TableBodyItem>{currency(dayPayment)}</TableBodyItem>
  <TableBodyItem>
    <DatePicker
      bind:value={contractDate}
      dateFormat="d.m.Y"
      locale={Russian}
      datePickerType="single"
    >
      <a>
        {#if contractDate}
          {moment(contractDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY")}
        {:else}
          Назначить
        {/if}
      </a>
    </DatePicker>
  </TableBodyItem>
  <TableBodyItem>
    {#if term}
      {term}
    {:else}
      -
    {/if}
  </TableBodyItem>
  <TableBodyItem>{currency(balance)}</TableBodyItem>
  <TableBodyItem>
    <span class:white={contractEndAt} style="color: red">
      {moment()
        .add(Math.floor(balance / dayPayment), "days")
        .format("DD.MM.YYYY")}
    </span>
  </TableBodyItem>
  <TableBodyItem>{currency(deposit)}</TableBodyItem>
  <TableBodyItem>
    <Form on:submit={handleCreateCashflow} validator>
      <View style="min-width: 160px" flex>
        <Input
          placeholder="Сумма"
          bind:value={amount}
          required
          number
          min={1}
        />
        <Button success submit>
          {#if $createCashflowLoading}
            <Spinner />
          {:else}
            <Icon value="plus" />
          {/if}
        </Button>
      </View>
      <CalendarPicker bind:value={date} dialogMode />
      <Checkbox caption="Залог" bind:checked={isDepositCashflow} />
    </Form>
  </TableBodyItem>
  <TableBodyItem>{tariff ? "Да" : "Нет"}</TableBodyItem>
  <TableBodyItem>
    <View flex>
      <Button on:click={handleRentUserEdit} warning>
        <Icon value="pencil" />
      </Button>

      <div class="uk-inline">
        <Button class="uk-margin-small-left" primary={tariff}>Тариф</Button>
        <div
          class="uk-padding-small"
          uk-dropdown="mode: click"
          bind:this={dropdownEl}
        >
          <ul class="uk-list">
            {#each vehicles as item}
              <li>
                <a on:click={handleVehicleTariffEdit(item)}
                  >{item.mark} {item.model} {item.number}</a
                >
              </li>
            {/each}
          </ul>
        </div>
      </div>

      <Button class="uk-margin-small-left" on:click={handleShowCashflowModal}>
        Платежи
      </Button>
      {#if !contractEndAt}
        <Button class="uk-margin-small-left" on:click={handleContractEnd} alert>
          {#if conractEndLoading}
            <Spinner />
          {:else}
            Завершение договора
          {/if}
        </Button>
      {/if}
    </View>
  </TableBodyItem>
</TableRow>

<style>
  .green-circle {
    color: white !important;
    background-color: #6feb6f !important;
    width: 18px;
    height: 18px;
    padding: 2px;
    border-radius: 100%;
  }
  .white {
    color: white !important;
  }
</style>
