<script>
    import { createEventDispatcher } from 'svelte';

    import Phalcon from "modules/phalcon.js";
    import Router from 'modules/router.js'
    import MapHelper from "modules/mapHelper.js";

    import FileImportDefProp from "components/files/FilesImportDefaultProp.html"

    import Table from 'UI/Table.html';
    import Button from 'UI/ButtonOld.html';

    export let columnTemplate = [];
    export let essence;

    const dispatch = createEventDispatcher();
    MapHelper.initGeocoder();

    let formData = {};
    let file;
    let container;
    let tableData;
    let columns = [];
    let items;
    let selectedProps = {};
    let dataToSend = {};
    let defaultData = {
        takeout: 'По умолчанию'
    };
    let options = {
        limit:100,
        attachment: "Эксель:",
        striped: true,
        small: true,
        divider: true,
    };

    const fileImport = (e) => {

        let reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);

        reader.onload = function(e) {
            let data = new Uint8Array(reader.result);
            let wb = XLSX.read(data,{type:'array'});
            let htmlstr = XLSX.write(wb,{type:'binary',bookType:'html'});
            let object;
            wb.SheetNames.forEach(function(name) {
                object = XLSX.utils.sheet_to_json(wb.Sheets[name]);
            });

            object.map(item =>{
                for(var key in item){
                    if(!columns.some(c => c.title == key)) columns.push({
                        title: key,
                        prop: key
                    });
                }
            });
            items = object;
        }
    };
    const sendData = async () => {
        if (items === undefined) {
            UIkit.notification({
                message: 'Выберите файл',
                status: 'warning',
                pos: 'bottom-right',
                timeout: 5000
            });
            return;
        }
        let dataToSend = [];
        let requestObj = {};
            requestObj[essence] = dataToSend;

        items.map((item)=> {
            let temp = JSON.parse(JSON.stringify(formData));
            let isFindByKey = {};

                for(var key in item) {
                    if(selectedProps[key]) {
                        if(!isFindByKey[selectedProps[key]]){
                            temp[selectedProps[key]] = null;
                            isFindByKey[selectedProps[key]] = true;
                        }
                        if (!temp[selectedProps[key]]){
                            if(selectedProps[key+'_divider']) {
                                temp[selectedProps[key]] = item[key].split(selectedProps[key+'_divider']);
                            } else {
                                temp[selectedProps[key]] = item[key];
                            }
                        } else {
                            if(selectedProps[key+'_divider']) {
                                temp[selectedProps[key]] = temp[selectedProps[key]].concat(item[key].split(selectedProps[key+'_divider']));
                            } else {
                                temp[selectedProps[key]] += item[key];
                            }
                        }
                    }
                }
            dataToSend.push(temp);
        });

        if (essence === 'orders') {
            let orders = await modifyToGQL(dataToSend);
            Phalcon.graphql.query(
                `query($orders:[OrderInput]!) {createOrders(order: $orders) {id}}`,
                { orders }).then( answer => {
                if (answer.status === 200) {
                    UIkit.notification({
                        message: 'Импорт успешно завершен',
                        status: 'success',
                        pos: 'bottom-right',
                        timeout: 5000
                    });
                    dispatch('success');
                }
            });
        } else {
            Phalcon.request(essence, 'insert', 'POST', requestObj
            ).then(answer => {
                if(answer.status === 200) {
                    UIkit.notification({
                        message: 'Импорт успешно завершен',
                        status: 'success',
                        pos: 'bottom-right',
                        timeout: 5000
                    });
                    dispatch('success');
                } else {
                    UIkit.notification({
                        message: 'Что-то пошло не так',
                        status: 'warning',
                        pos: 'bottom-right',
                        timeout: 5000
                    });
                }
            });
        }
    };
    function getCoords(container,data) {
        return new Promise(resolve => {
            let temp = {};
            if (container.has(data)){
                resolve(container.get(data));
            } else {
                MapHelper.geocode( data, 'address').then( answer => {
                    if (answer) {
                        temp.lat = answer.lat();
                        temp.lon = answer.lng();
                        resolve(temp);
                    }
                });
            }
        })

    }
    async function modifyToGQL(data) {
        let addressCache = new Map();

        for(var i = 0; i < data.length; i++){
            if(typeof data[i].takeout != "object"){
                let address = data[i].takeout;
                data[i].takeout = { address };
            }

            if(typeof data[i].delivery != "object"){
                let address = data[i].delivery;
                data[i].delivery = { address };
            }

            if(!data[i].takeout.address || !data[i].delivery.address) {
                data[i] = null;
                continue;
            }

            data[i].takeout.address = data[i].takeout.address.toString();
            data[i].delivery.address = data[i].delivery.address.toString();

            if(data[i].takeout.address) {
                if(!addressCache.has(data[i].takeout.address)) {
                    await MapHelper.geocode(data[i].takeout.address, 'address').then(answer => {
                        if (answer) {
                            data[i].takeout.lat = answer.lat();
                            data[i].takeout.lon = answer.lng();
                            addressCache.set(data[i].takeout.address, data[i].takeout);
                        }
                    });
                } else {
                    data[i].takeout = addressCache.get(data[i].takeout.address);
                }
            }

            delete data[i].takeout.code;
            delete data[i].takeout.index;

            if(data[i].delivery.address) {
                if(!addressCache.has(data[i].delivery.address)) {
                    await MapHelper.geocode(data[i].delivery.address, 'address').then(answer => {
                        if (answer) {
                            data[i].delivery.lat = answer.lat();
                            data[i].delivery.lon = answer.lng();
                            addressCache.set(data[i].delivery.address, data[i].delivery);
                        }
                    });
                } else {
                    data[i].delivery = addressCache.get(data[i].delivery.address);
                }
            }

            delete data[i].delivery.code;
            delete data[i].delivery.index;

            data[i] = {
                number: Date.now().toString(),
                pay_type: "cash",
                delivery: {
                    client: {
                        name: "data.client_name",
                        phone: "data.client_phone"
                    },
                    date: data[i].delivery_date,
                    point: data[i].delivery,
                    time_end: data[i].delivery_time_end,
                    time_start: data[i].delivery_time_start
                },
                takeouts: [
                    {
                        point: data[i].takeout,
                        date: data[i].takeout_date,
                        time_end: data[i].takeout_time_end,
                        time_start: data[i].takeout_time_start,
                        client: {
                            name: "data.providers_name",
                            phone: "data.providers_phone"
                        }
                    }
                ]};
            };

        data = data.filter(item => {
            if(!item) return false;
            if(!item.delivery.point.lon) return false;
            for(var i = 0; i < item.takeouts.length; i++){
                if(!item.takeouts[i].point.lon) return false;
            }
            return true;
        });

        UIkit.notification({
            message: `Загружено ${data.length} заказа`,
            status: 'success',
            pos: 'bottom-right',
            timeout: 5000
        });
        return data;
    }
    function markKey(prop, index) {
        if (!prop) return;
        columnTemplate.map(ct => {
            if(ct.prop === selectedProps[prop]) {
                if (ct.hasOwnProperty('type')){
                    items[index].divider = ''; // split/join
                } else {
                    delete items[index]['divider']; // split/join
                }
            }
        })
    }
</script>

<style lang="scss">thead {
  background: #c4c4c4;
  border: none !important; }
  thead th {
    padding: 5px 5px !important;
    vertical-align: top; }
  thead select {
    height: 25px !important;
    font-size: 12px;
    padding: 0 3px !important;
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 3px; }
    thead select:focus {
      outline: none; }

ul {
  overflow-y: auto; }

.divider__block {
  font-size: 12px;
  width: 100%;
  height: 25px; }
</style>

<div class="wrapper">
    <div class="uk-text-right uk-flex uk-flex-between uk-margin-small-bottom" >
        <input type="file" bind:this={file} on:change={fileImport} />
        <div bind:this={container}></div>
        <div class="uk-position-bottom-right">
            <Button name="Загрузить" size="default" on:click={sendData}></Button>
        </div>
    </div>

    <div class="uk-flex">

        {#if essence === "orders" && items}
            <FileImportDefProp bind:formData />
        {/if}

<!--        {#if requiredColumns && items}-->
<!--            <div class="uk-width-1-4">-->
<!--                <ul class="uk-list uk-margin-small-right">-->
<!--                    {#each columnTemplate as column}-->
<!--                    <li title="Полностью заполнит колонку `${column.title}` введенными данными">-->
<!--                        {column.title} <input type="text" placeholder="Заполнитель колонки">-->
<!--                    </li>-->
<!--                    {/each}-->
<!--                </ul>-->
<!--            </div>-->
<!--        {/if}-->

        {#if items}
        <Table {columns} {columnTemplate} {...options} bind:items>
            <thead slot="caption">
            <tr>
                {#each columns as item, index}
                <th >
                    <select  on:change="{ () => markKey(item.prop, index) }" class="uk-select" bind:value={selectedProps[item.prop]}>
                        <option selected value="">Игнорировать колонку</option>
                        {#each columnTemplate as column}
                        <option value={column.prop}>{column.title}</option>
                        {/each}
                    </select>
                    {#if item.hasOwnProperty('divider')}
                    <div>
                        <input class="divider__input" type="text" placeholder="Введите разделитель" bind:value="{selectedProps[item.prop+'_divider']}">
                    </div>
                    {/if}
                </th>
                {/each}
            </tr>
            </thead>
        </Table>
        {/if}
    </div>
</div>