<script>
  import { onMount, createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let urls = [];
  export let formData = new FormData();
  
  let activeImage = urls[0] || '/img/none.png';
  let fileInput;
  let files = [];

  function read() {
    let reader = new FileReader();

    if (fileInput.files[0]) {
      let file = fileInput.files[0];
      let name = file.name;
      let size = Math.round(file.size / 1024) + ' КБ';
      let ext = name.split('.').pop().toLowerCase();

      reader.onload = function (e) {
        let image = e.target.result;
        activeImage = image;
        urls = [...urls, image];
        files = [...files, { name, size, ext, image, file }];
        formData.append("file" + files.length, file);
      };

      reader.readAsDataURL(file);
    }
  }

  onMount(() => {
    fileInput.onchange = read;
  });
</script>

<style lang="scss">.slider__arrow {
  width: 11px;
  margin-left: 0;
  margin-right: 0;
  background: #eeeeee !important;
  border-radius: 50%;
  color: #4f4f4f !important;
  opacity: 0.5; }
  .slider__arrow:hover {
    color: #4f4f4f !important;
    opacity: 1; }
  .slider__arrow--right {
    position: absolute !important;
    right: -20px;
    top: 0; }
  .slider__arrow--left {
    position: absolute !important;
    left: -20px;
    top: 0; }

.border {
  border: 1px solid #e2e2e2; }

.uk-margin-small-right {
  margin-right: 30px !important; }
</style>
<div class="uk-margin-small-bottom uk-margin-small-right" uk-lightbox>
  <div class="uk-text-center">
    <a class="uk-inline uk-height-small uk-width-small thumbnail" href={activeImage}>
      <img class="portrait" src={activeImage} alt="">
    </a>
  </div>
</div>
<div class="uk-margin-small-bottom uk-margin-small-right uk-text-center">
  {#if urls.length > 1}
  <div class="uk-margin-small-bottom" uk-slider>
    <div class="uk-position-relative">
      <div class="uk-slider-container uk-light">
        <ul class="uk-slider-items uk-child-width-1-3">
          {#each urls as url}
                  <li on:click="{() => activeImage = url}">
                    <img src="{url}" alt="">
                  </li>
                {/each}
            </ul>
        </div>

        <a class="slider__arrow slider__arrow--left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="slider__arrow slider__arrow--right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
  </div>
  {/if}
  <a class="uk-link-muted uk-text-small" on:click="{() => fileInput.click()}">Добавить изображение</a>
  <input type="file" name="photo" class="uk-hidden" bind:this={fileInput} accept="image/jpeg,image/png,image/gif"/>
</div>