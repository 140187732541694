<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let id;
  export let checked = false;
</script>

<style>

  input[type="checkbox"]:checked,
  input[type="checkbox"]:not(:checked),
  input[type="radio"]:checked,
  input[type="radio"]:not(:checked)
  {
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:not(:checked) + label,
  input[type="radio"]:checked + label,
  input[type="radio"]:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    line-height: 20px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label:before,
  input[type="checkbox"]:not(:checked) + label:before,
  input[type="radio"]:checked + label:before,
  input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 18px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
  }

  input[type="checkbox"]:checked + label:before,
  input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
  }

  input[type="radio"]:checked + label:before,
  input[type="radio"]:not(:checked) + label:before {
    border-radius: 100%;
  }

  input[type="checkbox"]:checked + label:after,
  input[type="checkbox"]:not(:checked) + label:after,
  input[type="radio"]:checked + label:after,
  input[type="radio"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type="checkbox"]:checked + label:after,
  input[type="checkbox"]:not(:checked) + label:after {
    left: 3px;
    top: 4px;
    width: 10px;
    height: 5px;
    border-radius: 1px;
    border-left: 4px solid #515151;
    border-bottom: 4px solid #515151;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  input[type="radio"]:checked + label:after,
  input[type="radio"]:not(:checked) + label:after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #515151;
  }

  input[type="checkbox"]:not(:checked) + label:after,
  input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }

  input[type="checkbox"]:checked + label:after,
  input[type="radio"]:checked + label:after {
    opacity: 1;
  }

</style>

<div class="wrapper">
  <input bind:checked="{checked}" type="checkbox" id="{id}"> <label for="{id}"><slot></slot></label>
<!--  <input on:click="{() => dispatch('check', {check})}" class="uk-checkbox" type="checkbox" bind:checked={check}>-->
</div>