<script>
    import { onMount, onDestroy } from 'svelte'
    import { Form, Grid, Row, Cell, Panel, Input, Group, Button, Icon } from 'svelte-metro-ui'
    import { getDataForCollection, createCustomFields, customFieldsStore, fetchAllCustomFields, deleteCustomField, createCollection } from '../../stores/CollectionsStore'
    import validator from 'modules/validator.js';

    export let date = null
    export let deliveryUserId = null
    
    let loading = false

    let products = []
    let services = []
    let totals = {}
    let servicesMap = {}

    let totalAmount = 0
    let cashAmount = 0
    let cardAmount = 0
    let prepayAmount = 0

    let totalPoints = 0
    let pickupPoints = 0
    let deliveryPoints = 0
    let canceledPoints = 0

    let actualPassedAmount = 0
    let terminalPassedAmount = 0

    let toPassedAmount = 0
    let deficitPassedAmount = 0

    let customFieldsKeys = []
    let customFields = {}

    const handleAddCustomField = () => {
        let name = `Наименование поля ${customFieldsKeys.length + 1}`
        customFieldsKeys = [...customFieldsKeys, {
            name: name,
            key: validator.translate.rus_to_latin(name).replace(/[^a-zA-Z0-9- ]/g, "").replaceAll(' ', '_').toLowerCase()
        }]
    }

    const handelRemoveCustomField = item => () => {
        if (item.id) {
            deleteCustomField(item)
        } else {
            customFieldsKeys = customFieldsKeys.filter(cf => cf.key != item.key)
            delete customFields[item.key]
        }
    }

    const handleCustomFieldChange = item => () => {
        item.key = validator.translate.rus_to_latin(item.name).replace(/[^a-zA-Z0-9- ]/g, "").replace(' ', '_').toLowerCase()
    }

    const handleSubmit = async () => {
        console.log("handleSubmit")

        let answer = await createCustomFields(customFieldsKeys)
        console.log({answer})
        answer = await createCollection({
            total_amount: totalAmount,
            cash_amount: cashAmount,
            card_amount: cardAmount,
            prepay_amount: prepayAmount,
            actual_passed_amount: actualPassedAmount,
            terminal_passed_amount: terminalPassedAmount,
            services_quantity: totalPoints,
            delivered_quantity: deliveryPoints,
            pickups_quantity: pickupPoints,
            canceled_services_quantity: canceledPoints
        })
        console.log({answer})
    }

    // validator.translate.rus_to_latin(domain).replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase()
    
    const unsubscribe = customFieldsStore.subscribe(v => {
        console.log({v})
        customFieldsKeys = v
    })
    
    onMount(async () => {
        await fetchAllCustomFields()
        if (date && deliveryUserId) {
            const answer = await getDataForCollection(date, deliveryUserId)
            console.log({answer})
            if (answer.status == 200) {
                services = answer.payload.delivery_services
                products = answer.payload.products
                totals = answer.payload.totals
            }
        }
    })

    onDestroy(() => {
        unsubscribe()
    })

    $: console.log({date, deliveryUserId})
    $: {
        totalAmount = totals['total_amount'] || 0
        cashAmount = totals['cash_amount'] || 0
        cardAmount = totals['card_amount'] || 0
        prepayAmount = totals['prepay_amount'] || 0
        
        totalPoints = totals['total_points'] || 0
        pickupPoints = totals['pickup_points'] || 0
        deliveryPoints = totals['delivery_points'] || 0
        canceledPoints = totals['canceled_points'] || 0
    }
    $: toPassedAmount = cashAmount
    $: deficitPassedAmount = toPassedAmount - (parseInt(actualPassedAmount) + parseInt(terminalPassedAmount))
    $: console.log({customFieldsKeys})
    $: console.log({customFields})
</script>

<Form on:submit={handleSubmit} validator>
    <Grid>
        <Row>
            <Cell size="sm-3">
                <p>Сумма заказов</p>
                <Panel class="bg-gray fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{totalAmount}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-3">
                <p>Сумма наличных</p>
                <Panel class="bg-teal fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{cashAmount}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-3">
                <p>Сумма картой</p>
                <Panel class="bg-olive fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{cardAmount}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-3">
                <p>Сумма предоплаты</p>
                <Panel class="bg-crimson fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{prepayAmount}</strong>
                </Panel>
            </Cell>
        </Row>
        <Row>
            <Cell size="sm-3">
                <p>Фактических точек</p>
                <Panel class="bg-gray fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{totalPoints}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-3">
                <p>Точек доставки</p>
                <Panel class="bg-orange fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{deliveryPoints}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-3">
                <p>Точек заборов</p>
                <Panel class="bg-emerald fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{pickupPoints}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-3">
                <p>Точек отказов</p>
                <Panel class="bg-crimson fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{canceledPoints}</strong>
                </Panel>
            </Cell>
        </Row>
        <Row>
            <Cell size="sm-6">
                <Group label="Сдано по терминалу">
                    <Input bind:value={terminalPassedAmount} />
                </Group>
            </Cell>
            <Cell size="sm-6">
                <Group label="Фактически сдано">
                    <Input bind:value={actualPassedAmount} />
                </Group>
            </Cell>
        </Row>
        <Row>
            <Cell size="sm-12">Пользовательские поля</Cell>
            {#each customFieldsKeys as item, i (i)}
                <Cell size="sm-3">
                    <div class="uk-flex">
                        <Input placeholder="Название поля" bind:value={item.name} on:change={handleCustomFieldChange(item)} />
                        <Icon value="bin" on:click={handelRemoveCustomField(item)} />
                    </div>
                    <Input placeholder="Значение поля" bind:value={customFields[item.key]} />
                </Cell>
            {/each}
            <Cell size="sm-3">
                <Button on:click={handleAddCustomField} success>Добавить поле</Button>
            </Cell>
        </Row>
        <Row>
            <Cell size="sm-6">
                <p>Сумма к инкассации</p>
                <Panel class="bg-emerald fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{toPassedAmount}</strong>
                </Panel>
            </Cell>
            <Cell size="sm-6">
                <p>Недостача</p>
                <Panel class="bg-emerald fg-white p-2 d-flex flex-justify-center flex-align-center">
                    <strong>{deficitPassedAmount}</strong>
                </Panel>
            </Cell>
        </Row>
    </Grid>

    

    <div class="uk-padding-small uk-position-bottom-left uk-background-default uk-text-right uk-width uk-margin-small-top footer">
        <slot name="submitButton">
            <Button success submit>
                {#if loading}
                    <div uk-spinner></div>
                {:else}
                    Создать инкассацию
                {/if}
            </Button>
        </slot>
    </div>
</Form>

<style>
    .footer {
        border-top: 1px solid #dfdfdf; 
        position: sticky !important;
        z-index: 999;
    }
</style>