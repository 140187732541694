<li class="subject-divider">
  <span>
    <slot />
  </span>
</li>

<style>
  .subject-divider {
    color: #525252;
    padding-bottom: 4px;
    border-bottom: 1px solid #525252;
    margin: 32px 1rem 8px;
  }

  .subject-divider span {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.32px;
    color: #c6c6c6;
  }
</style>
