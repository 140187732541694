<script>
  export let name = "Button",
    isDisabled = false,
    filled = false,
    status = "success",
    size = "full",
    loader,
    type = "button",
    customClass = "";

  $: if (loader) loader.then(answer => {
    loader = null;
  })
</script>

<div class="wrapper">
  <button class="{status} {size} {customClass}" disabled="{isDisabled || loader}" class:disabled={isDisabled}
    class:filled="{filled}" type={type} on:click>
    {#await loader}
        <div uk-spinner></div>
      {:then data}
        {name}
      {/await}
  </button>
</div>

<style>
  button {
    background-color: rgba(255,255,255, 0);
    border: 1px #e30613;
    width: 100px;
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
  }

  .wrapper{
  }

button.disabled{
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
}

button.disabled:hover{
  cursor: default;
  color: #c4c4c4;
  background-color: rgba(255,255,255, 0);
}

/* types*/

  .success{
    font-size: 14px;
    color: #e30613;
    border: 1px solid #e30613;
  }
  .success:hover{
    cursor: pointer;
    background-color: #e30613;
    color: #ffffff;
  }

  .disable{
    font-size: 14px;
    color: #414141;
    border: 1px solid #414141;
  }

  .disable:hover{
     cursor: pointer;
     background-color: #414141;
     color: #ffffff;
  }

  .cancel{
    font-size: 14px;
    color: #c4c4c4;
  }
  .cancel:hover{
       cursor: pointer;
       text-decoration: underline;
    }

  .submit {
    background-color: #ffffff;
    border: 1px solid #e30613;
    border-radius: 5px;
    color: #e30613;
  }
  .submit:hover{
       cursor: pointer;
       color: #ffffff;
       background-color:#e30613;
     }
/*  /types*/

/* sizes */
  .auto{
    width: auto;
  }

  .full {
    width: 100%;
  }

  .md {
    height: 45px;
    width: 100%;
  }

  .half {
    width: 50%;
  }

  .default {
    width: 100px;
  }

  .tariff{
    width: 180px;
    height: 50px;
  }
/* /sizes */
</style>