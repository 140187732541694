<script>
    import { createEventDispatcher } from 'svelte';

    import View from "components/applications/ApplicationsDetailMain.html"
    import Other from "components/applications/ApplicationsDetailOther.html"
    import Product from "components/products/ProductsSelectList.html"

    import Input from "UI/Input.html"
    import Button from "UI/ButtonOld.html"
    import Add from "UI/ButtonAdd.html"


    export let data;
    export let isDelivery = false;
    export let takeoutNumber;
    export let requiredCallbacks = {};
    export let tkCount;
    export let modal
    export let addressDisabled;

    const dispatch = createEventDispatcher();
    const intro = introJs();

    let elem;
    let amount = 0;
    let size = 0;
    let weight = 0;
    let count = 0;

    $: {
        count = 0;

        data.items.map(item => {
            if(item.count) count += parseInt(item.count);
        });

        count = Math.round(count * 100)/100;
    }
    
    const focusProd = () => {
        UIkit.tab(elem).show(1);
    }

    const showProductsIntro = () => {
      // intro.setOption("skipLabel", "Пропустить обучение");
      // intro.setOption("nextLabel", "Дальше");
      // intro.setOption("prevLabel", "Назад");
      // intro.setOption("doneLabel", "Завершить");
      
      // intro.setOptions({
      //   steps: [{
      //     element: document.getElementById('order-legal'),
      //     intro: 'Заказчик - это ваше Юр.лицо, выбирете его из списка'
      //   }, {
      //     element: document.getElementById('takeout-legal'),
      //     intro: 'Здесь вы можете ввести юр.лицо поставщика'
      //   }, {
      //     element: document.getElementById('takeout-address-code'),
      //     intro: 'Можно присвоить "Код адреса", который сохранится для дальнейшего быстрого ввода'
      //   }, {
      //     element: document.getElementById('takeout-address'),
      //     intro: 'Поле "Адрес" подставляется при вводе "Кода адреса" или "Контрагента", его можно изменить на нужный'
      //   }, {
      //     element: document.getElementById('takeout-add-link'),
      //     intro: 'Можно добавить еще склады - "Точки забора", если товары нужно забрать из нескольких точек и отвезти в одну'
      //   }, {
      //     element: document.getElementById('takeout-goto-products'),
      //     intro: 'Добавить товары можно в соответствующей вкладке, создав новый или выбрав из справочника'
      //   }, {
      //     element: document.getElementById('products-tab'),
      //     intro: 'Перейти во вкладку "Товары", чтобы добавить товары, которые нужно забрать'
      //   }]
      // });

      // intro.start();

      // this.set({intro});

      // localStorage.setItem('new_order_intro', 'true');
    };

    let tabs = document.querySelector('.tabs__header');
</script>

<style lang="scss">.box-opacity {
  opacity: 0.5; }

a {
  text-transform: unset; }

h2 {
  margin: 0;
  padding: 0; }

input {
  width: 35%;
  border: 1px solid #c4c4c4;
  border-radius: 4px; }

textarea {
  border-radius: 3px;
  resize: none;
  font-family: Roboto; }

.uk-padding-small {
  padding: 10px; }
</style>

<div class="wrapper uk-flex uk-flex-start uk-position-relative">
    <div class="uk-card uk-card-default uk-card-body uk-padding-remove uk-width-large" >
      
        <ul uk-tab bind:this="{elem}" class="uk-flex-around uk-card-title uk-margin-remove-bottom">
            <li class="uk-width-expand uk-active"><a class="uk-padding-small">Контакты</a></li>
            <li class="uk-width-expand" id="products-tab"><a class="uk-padding-small">Товары<span class="uk-text-primary uk-text-bold uk-margin-small-left">{count}</span></a></li>
            <li class="uk-width-expand" ><a class="uk-padding-small">Комментарий</a></li>
        </ul>
        <ul class="uk-margin-remove-top uk-switcher uk-padding-small">
            <li class="uk-active">
                <View bind:data {isDelivery} {takeoutNumber} {tkCount} bind:requiredCallbacks on:focusProd={focusProd} {addressDisabled} on:remove/>
            </li>
            <li on:click={showProductsIntro}>
                <Product bind:items={data.items} bind:modal {isDelivery} bind:requiredCallbacks/>
            </li>
            <li>
                <Other bind:filesFormData={data.filesFormData} bind:comment={data.comment} bind:files={data.files} />
            </li>
        </ul>
    </div>
</div>