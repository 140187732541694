<script>
  import { Link, Button } from "carbon-components-svelte"
  import { infoToast } from "svelte-metro-ui"
  import Clipboard from "svelte-clipboard"
  import { Icon } from "svelte-uikit-ui"
  import { phoneFormat } from "../../../helpers/format.helper"
  import { showModalWithData as showModalRentUserEditWithData } from "../rentUsers/modals/ModalEdit.svelte"
  import { showModalWithData as showModalVehicleEditWithData } from "../vehicles/modals/ModalEdit.svelte"
  import { showModalWithData as showModalAgreeWithData } from "./modals/ModalAgree.svelte"
import phalcon from "../../../modules/phalcon";
import { deleteVehicleOrder } from "../../../storesEffector/vehiclesOrders";

  export let cell
  export let row
  export let headersMap

  const handleShowEditRentUserModal = () => {
    if (row.rent_user && row.rent_user.id) {
      showModalRentUserEditWithData({
        id: row.rent_user.id,
      })
    }
  }
  const handleShowEditVehicleModal = () => {
    if (row.vehicle && row.vehicle.id) {
      showModalVehicleEditWithData({
        id: row.vehicle.id,
      })
    }
  }
  const handleShowAgreeOrderModal = () => {
    if (row.vehicle && row.vehicle.id) {
      showModalAgreeWithData({
        vehicleOrder: row,
        vehicleId: row.vehicle.id,
      })
    }
  }
  const handleDelete = async () => {
    console.log({row})
    if (!confirm('Вы уверены что хотите удалить заявку?')) return
    await deleteVehicleOrder(row)
  }
</script>

{#if cell.key === "rent_user"}
  {#if row.rent_user}
    <Link on:click={handleShowEditRentUserModal}
      >{row.user_name} {row.user_last_name}</Link
    >
  {:else}
    {row.user_name} {row.user_last_name}
  {/if}
{:else if cell.key === "vehicle"}
  <Link on:click={handleShowEditVehicleModal}>
    {row.vehicle.mark}
    {row.vehicle.model}
    {row.vehicle.number}
  </Link>
{:else if cell.key === "verify_url"}
  {#if row.is_agree}
    <Clipboard
      text={`https://rexpro.ru?loginModal=true&phone=${phoneFormat(
        row.user_phone,
        "%2b",
        "%20"
      )}`}
      let:copy
      on:copy={() => infoToast("Ссылка скопирована")}
    >
      <a href="#" on:click={copy}> Скопировать </a>
    </Clipboard>
  {:else}
    -
  {/if}
{:else if cell.key === "phone"}
  <div class="uk-flex uk-flex-middle">
    {#if row.user_phone}
      <a href="tel:{phoneFormat(row.user_phone)}"
        >{phoneFormat(row.user_phone)}</a
      >
      {#if row.rent_user && row.rent_user.is_agree}
        <div
          class="green-circle uk-margin-small-left uk-flex uk-flex-middle uk-flex-center"
          uk-tooltip="Телефон подтвержден"
        >
          <Icon>check</Icon>
        </div>
      {/if}
    {:else}
      -
    {/if}
  </div>
{:else if cell.key === "agree"}
  {#if row.is_agree}
    Согласовано
  {:else}
    <Button on:click={handleShowAgreeOrderModal}>Согласовать</Button>
  {/if}
  <Link on:click={handleDelete}>Удалить</Link>
{:else if headersMap[cell.key].display}
  {headersMap[cell.key].display(cell.value)}
{:else}
  {cell.value}
{/if}

<style>
  .green-circle {
    color: white !important;
    background-color: #6feb6f !important;
    width: 18px;
    height: 18px;
    padding: 2px;
    border-radius: 100%;
  }
</style>
