<svelte:options immutable={true} />

<script>
  import { TextInput, Button } from "carbon-components-svelte"
  import { currency, phoneFormat } from "../../../helpers/format.helper"
  import { print } from "../../../helpers/print.helper"

  export let el
  export let items
  export let hidden = false
  let search = ""
  let filteredItems = []
  let categoriesMap = {}

  const handlePrint = () => print(el)
  $: {
    filteredItems = items.filter((item) => !item.archive_at)
    if (search && search != "") {
      filteredItems = filteredItems.filter(
        (item) =>
          item.description &&
          item.description.toLowerCase().search(search.toLowerCase()) != -1
      )
    }
  }
</script>

<div class:uk-hidden={hidden} class="uk-flex">
  <TextInput placeholder="Поиск..." bind:value={search} />
  <Button on:click={handlePrint}>Печать</Button>
</div>
<div class:uk-hidden={hidden} bind:this={el}>
  <table>
    <thead>
      <th>Дата</th>
      <th>Категория</th>
      <th>Тип</th>
      <th>Описание</th>
      <th>Сумма</th>
      <th>Кто провел</th>
    </thead>
    <tbody>
      {#each filteredItems as row}
        <tr>
          <td style="padding-right: 10px">
            {moment(row.date, "YYYY-MM-DD HH:mm:ss")
              .add(3, "hours")
              .format("DD.MM.YYYY HH:mm")}
          </td>
          <td>
            {row.name}
          </td>
          <td>
            {row.cash_type == "cash" ? "Нал." : "Р/С"}
          </td>
          <td style="padding-right: 10px">
            {row.description}
          </td>
          <td style="padding-right: 10px">
            {currency(row.amount)}
          </td>
          <td style="padding-right: 10px">
            {row.last_name} {row.first_name}
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<style>
  td {
    padding: 5px;
  }
</style>
