<script>
    import { onMount } from 'svelte'
    import CheckboxSemantic from '../checkbox'

    export let checked = false

    let el
    let radio = true
    let checkbox = true

    onMount(() => {
        CheckboxSemantic.init(el)
    })
</script>

<div 
    class="ui"
    class:radio
    class:checkbox
    bind:this={el}
>
  <input type="checkbox" bind:checked={checked}>
  <label><slot></slot></label>
</div>

<style>
    .ui.radio.checkbox .box:after, 
    .ui.radio.checkbox label:after {
        top: 2px;
        left: 1px;
    }
</style>