<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import Backdrop from 'UI/Backdrop.html'

    let class_name = '';
    let el;

    export let type = 'default';
    export let size = 'default';
    export let width = 'auto';
    export let height = 'auto';
    export {class_name as class};
    export let loading = false;
    export let accentColor;

    const dispatch = createEventDispatcher()
    const onClick = () => {
        if (!loading) dispatch('click')
    }

    onMount(() => {
        if(accentColor) el.classList.add('accent-color')
    })
</script>

<button

    class="uk-button uk-position-relative {class_name}"
    class:uk-button-default="{type == 'default'}"
    class:uk-button-primary="{type == 'primary'}"
    class:uk-button-secondary="{type == 'secondary'}"
    class:uk-button-danger="{type == 'danger'}"
    class:uk-button-text="{type == 'text'}"
    class:uk-button-link="{type == 'link'}"
    class:uk-button-small="{size == 'small'}"
    class:uk-button-large="{size == 'large'}"
    on:click={onClick}

    style="--color:{accentColor}"
    bind:this={el}
>
    {#if loading}
        <Backdrop>
            <div uk-spinner></div>
        </Backdrop>
    {/if}
    <slot>Клик</slot>
</button>

<style lang="scss">.accent-color {
  color: var(--color); }

.uk-button-primary {
  background-color: #e30613; }

.uk-button-primary:hover {
  background-color: #c70017; }
</style>