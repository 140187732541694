<script>
    import { applicationStatusList } from 'main/stores.js';
    import { createEventDispatcher } from 'svelte';
    
    import Phalcon from 'modules/phalcon.js';

    const dispatch = createEventDispatcher();

    export let status;
    export let orderStatus;
    export let application;
    export let disabled;

    if(!status) status = {
        id: 8,
        color: '#515151',
        name: 'В обработке'
    };

    let statusId = (status.id ? status.id : null);

    const setStatus = () => {
        if(application && application.id && statusId){
            disabled = true;
            Phalcon.applications.status.set({ applicationId: application.id, statusId }).then(answer => {
                if(answer.status == 200) {
                    disabled = false;
                    status = answer.application_status;
                    orderStatus = answer.order_status;
                    dispatch('orderStatusChange', answer.order_status);
                }
            });
        }
    }

    $: status, statusId = status.id;
</script>

<div class="uk-container">
    <select bind:value={statusId} on:change={setStatus} on:click|stopPropagation {disabled} style="background:{status.color}33; color:{status.color};">
        {#each $applicationStatusList as item}
            <option value={item.id}>{item.name}</option>
        {/each}
    </select>
</div>

<style>
    .wrapper {
      position: relative;
      z-index: 1;
      width: calc(100% - 23px);
      border-radius: 6px;
      background: white;
    }

    .uk-container select {
      white-space: nowrap;
      border: none;
      border-radius: 6px;
      padding: 3px;
    }
    
    option {
        background: white !important;
        color: black;
    }
</style>