<script>
    import { createEventDispatcher } from 'svelte'
    import Icon from "../../text/Icon.svelte"

    const dispatch = createEventDispatcher()
    let cls = ''

    export {cls as class}
    export let key = 'collapse-' + Math.random().toString(36).substring(7)
    export let toggle = false

    const handleClick = () => {
        toggle = !toggle
        dispatch('click')
    }

    $: toggle, dispatch('collapsed', toggle)
</script>

<div class="{cls}" id={key} on:click={handleClick}>
    <slot>
        <Icon value={toggle ? "arrow-drop-up" : "arrow-drop-down"} />
    </slot> 
</div>

<style>
    div {
        width: fit-content !important;
        cursor: pointer;
    }
</style>