<script>
  export let min;
  export let value = min;
</script>

<div class="wrapper">
  <div class="uk-width-1-1 uk-flex">
    <div class="uk-width-4-5">
      <input class="uk-range" type="range" bind:value="{value}" min="{min}" max="100" step="1" on:change>
      <div class="uk-flex uk-flex-between">
        <button class="btn__area">{min}</button>
        <button class="btn__area">100</button>
      </div>
    </div>
    <div class="uk-width-1-5 uk-text-right">
      <button class="btn__container">{value}</button>
    </div>
  </div>
</div>

<style>
  .wrapper{
    width: 100%;
  }
  .btn__area{
    border: none;
    background: rgba(0,0,0,0);
  }
  .btn__container{
    width: 40px;
    height: 20px;
    background: #f5f5f5;
    border: none;
  }
</style>