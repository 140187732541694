<div class="uk-flex uk-flex-center uk-flex-middle">
    <slot>
    </slot>
</div>

<style>
    div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        pointer-events: unset !important;
        background-color: rgba(0,0,0,0.05);
        font-size: 2rem;
    }
</style>