<script>
    export let items = false
</script>

<ul 
    class:items-list={items}
    data-role="list"
>
    <slot></slot>
</ul>

<style>
    ul {
        list-style-type: none;
    }
</style>