<script>
  /**
   * @Orders component
   *
   */
  import { applicationStatusList, orderStatusList, ordersCache } from 'main/stores.js';
  
  /**
   * @Orders import modules
   *
   */
  import Phalcon from 'modules/phalcon.js';
  import Router from 'modules/router.js';
  import TextTransform from 'modules/textTransform.js';

  /**
   * @Orders import components
   *
   */
  import TableLayout from 'components/layouts/TableLayout.html';
  import NewOrder from 'components/orders/OrdersEdit.html';
  import ViewLegal from 'components/legals/LegalsEditModal.html';
  import ApplicationStatusSelect from 'components/applications/ApplicationsStatusSelect.html';
  import OrderStatusSelect from 'components/orders/OrdersStatusSelect.html';

  /**
   * @Orders import UI
   *
   */
  import FileUploadZone from 'UI/FileUploadZone.html';
  import Table, {parseToType} from 'UI/Table.html';
  import Loading from 'UI/Loader.html';
  import Empty from 'UI/Empty.html';
  import Modal from 'UI/Modal.html';
  import Button from 'UI/Button.html';
  import WindowButton from 'UI/ButtonWindowClose.html';
  import { successMsg } from 'UI/Messages.html';

  export let modal;

  /**
   * @Orders {private} params
   * @param {MapHelper} MapHelper
   * @param {orderModal} strings
   * @param {legalModal} strings
   * @param {fileModal} strings
   * @param {uploadForm} strings
   * @param {bar} strings
   * @param {requestHeaders} strings
   * @param {filteredItems} strings
   * @param {token} strings
   * @param {orders} strings
   * @param {date_start} strings
   * @param {date_end} strings
   * @param {columns} strings
   * @param {items} strings
   */
    let orderModal;
    let legalModal;
    let fileModal = false;
    let uploadForm;
    let bar;
    let requestHeaders = '';
    let filteredItems;
    let token;
    let checkTokenInterval;
    let orders = [];
    let date_start = moment().format('YYYY-MM-DD');
    let date_end = moment().format('YYYY-MM-DD');
    let columns = [
      {
        title: '№ заказа',
        prop: 'number'
      },
      {
        title: '№ в системе',
        prop: 'id'
      },
      {
        title: 'Статус',
        class: 'uk-width-small',
        prop: 'status',
      },
      {
        title: 'Заказчик',
        prop: 'order_legal'
      },
      {
        title: 'Контактные лица Поставщиков',
        prop: 'providers'
      },
      {
        title: 'Контактные лица Клиентов',
        prop: 'client'
      },
      {
        title: 'Откуда',
        class: 'uk-width-medium',
        prop: 'takeout'
      },
      {
        title: 'Куда',
        class: 'uk-width-medium',
        prop: 'delivery'
      },
      {
        title: 'Когда забрать',
        prop: 'takeout_date'
      },
      {
        title: 'Когда доставить',
        prop: 'delivery_date'
      },
      {
        title: 'Тип оплаты',
        prop: 'pay_type'
      },
      {
        title: 'Маршрут',
        prop: 'routes'
      },
      {
        title: 'Курьер',
        prop: 'dlboy'
      },
      {
        title: 'Груз',
        prop: 'cargo'
      }
    ]
    let items;
    let activeOrderID;
    let orderModalEl;

  /**
   * @Orders component logic
   */
  function checkToken() {
    if (window.session && window.session.token){
      token = window.session.token;
      clearInterval(checkTokenInterval);
    }
  }
  const refresh = () => {
    items = null;

    Phalcon.request('orders', 'all', 'GET', {
      params: {
        with: 'legals,applications.all',
        date_start, date_end
      }
    }).then(answer => {
      if (answer.status == 200) {
        orders = answer.items;
        items = orders.map(item => {
          let temp = {};

          temp.cargo = 0;
          temp.id = item.id;
          temp.number = item.number;
          temp.order_legal = item.legals ? item.legals.short_name : 'Нет данных';
          temp.order_legal_obj = item.legals;
          temp.status = item.status;
          temp.providers = [];
          temp.client = '';
          temp.comment = item.comment;
          temp.applications = [];
          temp.routes = [];
          temp.dlboy = [];
          item.applications.map(application => {
            let temp_applic = {};

            if (application.type === 'dl') temp.cargo = application.products_count || 0;

            temp_applic.id = application.id;
            temp_applic.number = application.id;
            temp_applic.client = (application.legals ? application.legals.short_name + " "  : '') + (application.clients ? application.clients.name : '');
            temp_applic.legals = application.clients && application.clients.legals && application.clients.legals ? application.clients.legals.short_name : '';
            temp_applic.comment = application.comment;
            temp_applic.address = application.points.address;
            temp_applic.date_time = moment(application.date_start).format('DD.MM.YY') + '<br>'  + '<span style="white-space:nowrap">' + moment(application.date_start).format('HH:mm') + ' - ' + moment(application.date_end).format('HH:mm') + '</span>';
            temp_applic.status = application.status;
            temp_applic.cargo = application.products_count ? application.products_count : 0;
            temp_applic.routes = application.route ? application.route.name : "Маршрут не составлен";
            temp_applic.dlboy = application.route ? application.route.dlboy ? application.route.dlboy.last_name : "Курьер не назначен" : "Маршрут не составлен";

            if(application.route){
              if(!temp.routes.includes(application.route.name)) temp.routes.push(application.route.name);
              if(application.route.dlboy){
                if(!temp.dlboy.includes(application.route.dlboy.last_name)) temp.dlboy.push(application.route.dlboy.last_name);
              }
            }

            if (application.points.type == 'tk') {
              temp_applic.type = 'tk';
              temp.providers.push(application.legals ? application.legals.short_name : (application.clients ? application.clients.name : ''));
              temp.takeout = application.points.address;
              temp.takeout_date = moment(application.date_start).format('DD.MM.YY') + '<br>' + '<span style="white-space:nowrap">' + moment(application.date_start).format('HH:mm') + ' - ' + moment(application.date_end).format('HH:mm') + '</span>';
            } else if (application.points.type == 'dl') {
              temp_applic.type = 'dl';
              temp.client = application.legals ? application.legals.short_name : (application.clients ? application.clients.name : '');
              temp.delivery = application.points.address;
              temp.delivery_date = moment(application.date_start).format('DD.MM.YY') + '<br>'  + '<span style="white-space:nowrap">' + moment(application.date_start).format('HH:mm') + ' - ' + moment(application.date_end).format('HH:mm') + '</span>';
            }
            temp.applications.push(temp_applic);
          })

          if(item.pay_type == 'cash') temp.pay_type = 'Наличные';
          if(item.pay_type == 'card') temp.pay_type = 'Карта';
          if(item.pay_type == 'prepay-full') temp.pay_type = 'Полная предоплата';
          if(item.pay_type == 'prepay-part') temp.pay_type = 'Частичная предоплата';

          temp.options = false;

          if(temp.routes.length){
            temp.routes = temp.routes.join(',');

            if(temp.dlboy.length){
              temp.dlboy = temp.dlboy.join(',');
            } else {
              temp.dlboy = "Курьер не назначен";
            }
          } else {
            temp.routes = "Маршрут не составлен";
            temp.dlboy = "Маршрут не составлен";
          }

          return temp;
        });
        filteredItems = items.slice();
      }
    })
  }
  function completeUpload(){
    setTimeout(() =>{
      closeFileModalHandle();
      successMsg('Заявки загружены успешно');
    }, 500);
    refresh();
  };
  function customFilterItems(items, search) {
    return items.filter(item => {
      for (var key in item) {
        if (search[key]) {
          if (key == 'status' && (item[key].name.toString().toLowerCase().search(search[key].toLowerCase()) !== -1)) return true;
          if (item[key] === null || typeof item[key] === 'undefined' || item[key].length === 0) return false;
          if (item[key].toString().toLowerCase().search(search[key].toLowerCase()) == -1) return false;
        }
      }
      return true;
    });
  }
  function customSortItems(payload) {
    return payload.items.sort((a, b) => {
      if (payload.sort.prop === 'status') {
        return payload.sortDirection(
          parseToType(a[payload.sort.prop].name, payload.sort.type),
          parseToType(b[payload.sort.prop].name, payload.sort.type))
      } else if (payload.sort.prop === 'cargo') {
        return payload.sortDirection(
                parseToType(a[payload.sort.prop], 'int'),
                parseToType(b[payload.sort.prop], 'int'))
      } else {
        return payload.sortDirection(
          parseToType(a[payload.sort.prop], payload.sort.type),
          parseToType(b[payload.sort.prop], payload.sort.type))
      }
    });
  }
  function getCalculation(data) {
    filteredItems = data;
  };
  function showOptions(id) {
    let result = filteredItems.findIndex( item => item.id == id);
    filteredItems[result].options = !filteredItems[result].options;
  }
  function showOrderModal() {
    if(orderModalEl) {
      UIkit.modal(orderModalEl).show()
    }
  }

  /**
   * @Orders component lifeCycle block
   *
   */
    import { onDestroy, onMount } from 'svelte';
    onMount(()=>{
      checkTokenInterval = setInterval(() => checkToken(), 300);
    });
    onDestroy(() => {
      clearInterval(checkTokenInterval);
      window.nav.listener.cancel();
      listener.cancel();
      window.nav.listener = null;
      window.nav.set({
        actions: []
      });
    });

  /**
   * @Orders component computed block
   *
   */
    $: if (token) {
      requestHeaders = {'Authorization': 'Bearer ' + token};
    }
    $: items, ordersCache.set(items);
    $: activeOrderID, setTimeout(() => showOrderModal());
    // $: filteredItems = items;

    if(!$applicationStatusList || !$applicationStatusList.length){
      Phalcon.applications.status.list().then(answer => {
        $applicationStatusList = answer;
      })
    }

    if(!$orderStatusList || !$orderStatusList.length){
      Phalcon.orders.status.list().then(answer => {
        $orderStatusList = answer;
      })
    }

    refresh();
    let statusFields = [
      "ready", "canceled", "inProgress", "new"
    ];
    let pos = [' позиция',' позиции',' позиций'];
    const num2str = (num, map) => {
      return `${num} ${TextTransform.modifier.num2endStr(num, map)}`;
    };
    let options = {
      limit: 100,
      attachment: "Заказов:",
      striped: false,
      small: true,
      divider: true,
      actions:['upload']
    };
    window.nav.set({
      breadcrumb: [{
        name: 'Заказы'
      }],
      actions: ['add','datepicker'],
      actionAddText: 'Новый заказ'
    });
    window.nav.listener = window.nav.on('add', () => {
      Router.goTo('orders/new');
    });
    let listener = window.nav.on('update', ({ changed, current, previous }) => {
      if (changed.date_start || changed.date_end) {
        date_start = current.date_start;
        date_end = current.date_end;
        refresh();
      }
    });

  /**
   * @Orders component eventHandlers
   *
   */
    const closeOrderModalHandle = () => {
      orderModal = null;
    };
    const closeLegalModalHandle = () => {
      legalModal = null;
    };
    const closeFileModalHandle = () => {
      fileModal = null;
    };
    const saveOrderModalHandle = () => {
      refresh();
      orderModal = null;
    };
    const saveLegalModalHandle = () => {
      refresh();
      legalModal = null;
    };
    const uploadFileHandle = () => {
      fileModal = true;
    };
    const uploadTemplateFileHandle = () => {
      Phalcon.go.download.ordersTemplate()
    };
    const saveOrderHandle = () => {
      activeOrderID = null
      refresh()
    }
</script>

{#if activeOrderID}
  <div class="uk-modal-full" bind:this={orderModalEl} uk-modal>
    <div class="uk-modal-dialog uk-flex uk-flex-middle uk-height-1-1" style="background-color: rgba(0,0,0,0.5);">
      <button class="uk-modal-close-full uk-close-large" type="button" uk-close on:click="{() => activeOrderID = null}"></button>
      <div class="uk-width uk-overflow-hidden uk-padding">
        <NewOrder orderID={activeOrderID} isModal={true} on:save={saveOrderHandle} bind:modal />
      </div>
    </div>
  </div>
{/if}

{#if legalModal}
  <ViewLegal
    modal={legalModal}
    on:close="{closeLegalModalHandle}"
    on:save={saveLegalModalHandle}
/>
{/if}

{#if fileModal}
  <Modal>
  <div class="uk-flex uk-flex-between" slot="header">
    <h3>Загрузка файлом</h3>
    <WindowButton type="plus" on:click={closeFileModalHandle}></WindowButton>
  </div>


<!--// FileUploadZone-->

  <div slot="body" class="uk-padding-remove">
  {#if token}
    <FileUploadZone
        bind:requestHeaders={requestHeaders}
        url={Phalcon.go.upload.orders.link}
        name="file"
        multiple="false"
        on:complete={refresh}
        on:completeAll={completeUpload}
    >
      <!--      <span slot="hint" class="uk-text-middle" >RefactorSlot Перетащите заполненный файл по загруженному шаблону, или </span>-->
      <!--      <span slot="content" class="uk-link" >choose file</span>-->
    </FileUploadZone>
  {/if}


<!--    <div class="js-upload uk-placeholder uk-text-center" bind:this={uploadForm}>-->
<!--      <span uk-icon="icon: cloud-upload"></span>-->
<!--      <span class="uk-text-middle">Перетащите заполненный файл по загруженному шаблону, или </span>-->
<!--      <div uk-form-custom>-->
<!--        <input type="file">-->
<!--        <span class="uk-link">выберите файл</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden bind:this={bar}></progress>-->
  </div>

  <div slot="footer" class="uk-flex uk-flex-right">
    <Button type="default" on:click={closeFileModalHandle}>ОК</Button>
  </div>
</Modal>
{/if}

{#if items}
  {#if items.length || (filteredItems && filteredItems.length)}
    <Table name="{{title:'Заказы', prop:'orders'}}"
    on:upload={uploadFileHandle}
    on:uploadTemplate={uploadTemplateFileHandle}
    on:filter="{event => getCalculation(event.detail)}"
    on:sort="{event => getCalculation(event.detail)}"
    {customSortItems}
    {customFilterItems}
    {columns}
    {...options}

    bind:items
  >
    <tbody slot="tbody">
    {#each filteredItems as item, index}
      <tr on:click="{() => showOptions(item.id)}" class:shadow="{item.options}">
        <td>
          <div class="uk-flex uk-flex-between">{item.number}<span class="id__options">{item.applications.length}</span></div>
        </td>
        <td>{item.id}</td>
        <td>
          {#if $orderStatusList.length}
            <OrderStatusSelect bind:status={item.status} bind:applications={item.applications} order={item} />
          {:else}
            <div uk-spinner="ratio:1"></div>
          {/if}
        </td>
        {#if item.order_legal.toLowerCase() !== "нет данных"}
        <td><a on:click|stopPropagation="{(event) => legalModal = {props: item.order_legal_obj}}">{item.order_legal}</a></td>
        {:else}
        <td>{item.order_legal}</td>
        {/if}
        <td>{item.providers || "нет данных"}</td>
        <td>{item.client || "нет данных"}</td>
        <td>{item.takeout || "нет данных"}</td>
        <td>{item.delivery || "нет данных"}</td>
        <td>{@html item.takeout_date || "нет данных"}</td>
        <td>{@html item.delivery_date || "нет данных"}</td>
        <td>{item.pay_type}</td>
        <td>{item.routes}</td>
        <td>{item.dlboy}</td>
        <td>{num2str(item.cargo ,pos)}</td>
      </tr>

    {#if item.options}
      {#each item.applications as application, i}
        <tr on:click="{(event) => activeOrderID = item.id}"
          class="tr__option" class:tr__shadow--top="{i == 0}">
          <td>{application.type == 'tk' ? 'Забор' : 'Доставка'}</td>
          <td>{application.number}</td>
          <td class="uk-position-relative">
            {#if $applicationStatusList.length}
              <ApplicationStatusSelect bind:status={application.status} on:orderStatusChange="{(event) => item.status = event.detail}" {application}/>
            {:else}
              <div uk-spinner="ratio:1"></div>
            {/if}
          </td>
          <td>{application.legals}</td>
          <td>{application.type == 'tk' ? application.client : ''}</td>
          <td>{application.type == 'tk' ? '' : application.client}</td>
          <td>{application.type == 'tk' ? application.address : ''}</td>
          <td>{application.type == 'tk' ? '' : application.address}</td>
          <td>{@html application.type == 'tk' ? application.date_time : ''}</td>
          <td>{@html application.type == 'tk' ? '' : application.date_time}</td>
          <td>{item.pay_type}</td>
          <td>{application.routes}</td>
          <td>{application.dlboy}</td>
          <td><div class="uk-flex">{num2str(application.cargo ,pos)}</div></td>
        </tr>
      {/each}
    {/if}

    {/each}
    </tbody>
  </Table>
  {:else}
      <Empty
          actionLabel="Добавить новый «Заказ»"
          label="За выбранный период пока нет данных..."
          subActions="{[
            {title: 'Загрузить файлом',eventName: 'upload', modifier: 'default'},
            {title: 'Скачать шаблон', eventName: 'upload-template', modifier: 'link'}
          ]}"
          on:upload={uploadFileHandle}
          on:upload-template={uploadTemplateFileHandle}
          on:click="{() => {
            Router.goTo('orders/new');
          }}"
      />
  {/if}
{:else}
    <Loading />
{/if}

  <style lang="scss">table,
td,
th {
  position: relative;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  background: #ffffff;
  padding: 8px 8px; }

.status-pill {
  width: calc(100% - 23px);
  border-radius: 6px;
  background: white; }

.status-pill .content {
  white-space: nowrap;
  border-radius: 6px;
  padding: 3px; }

th {
  box-sizing: border-box;
  text-transform: unset;
  font-size: 14px;
  color: #515151; }
  th:nth-child(1) {
    min-width: 115px; }

td {
  box-sizing: border-box;
  color: #7f7f7f;
  font-size: 14px; }
  td a {
    font-size: 13px;
    color: #7f7f7f; }
    td a:hover {
      color: #7f7f7f; }

.tr__option td {
  width: auto;
  box-sizing: border-box;
  border: 1px solid #e2e2e2;
  background: #fafafa;
  padding-left: 15px; }

.tr__shadow--top td {
  box-shadow: inset 0 3px 5px -5px black; }

.id__options {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 1px solid #515151;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0;
  line-height: 1.3; }

.field {
  padding: 5px 6px; }
  .field__status {
    color: #7f7f7f;
    padding: 3px 6px;
    border-radius: 6px;
    white-space: nowrap !important; }
    .field__status-cancel {
      background: rgba(227, 6, 19, 0.2); }
    .field__status-delivered {
      background: rgba(116, 158, 66, 0.2); }
    .field__status-assign {
      background: rgba(21, 121, 210, 0.2); }
    .field__status-progress {
      background: rgba(251, 187, 0, 0.2); }
    .field__status-process {
      background: #C4C4C4; }
</style>