<script>
    import router from "modules/router.js";
    import Phalcon from "modules/phalcon.js";
    
    import Button from "UI/ButtonOld.html";

    window.nav.set({
        breadcrumb: [{
            name: 'Мой профиль',
            link: '/profile'
        },
        // {
        //     name: 'Тариф',
        //     link: '/my-tariff'
        // }
        ],
        actions: [],
        actionAddText: ''
    });

    let selfProps = {};
    let fullPrice = 0;
    let selfValue = 0;
    let confinePrice = 0;
    let confinePrice2 = 0;
    let initialConfine = 0;
    let yearPay = 0;
    let discountPay = 0;
    let diffPay = 0;
    let price = 0;
    let discount = 0;
    let tariffVal = 0;
    let itemCounts = [];

    refreshSelf();
    function refreshSelf() {
        Phalcon.system.tariff()
            .then( answer => {
                if (answer.status === 200){
                    selfProps = answer;
                    selfValue = answer.limits[0].value;
                    discount = selfProps.year_discount;
                    itemCounts = selfProps.limits.map( (item) => {
                        return {id: item.id, value: item.value};
                    });
                    calcPrice();
                }else{
                    throw new Error('tariff')
                }
            })
            .catch( err => {});
    }

    function goToYearPay() {
    }

    function anyToInt(item) {
        if ( typeof parseInt(item) && !isNaN(item)){
            item = Math.floor(parseInt(item));
            return item;
        } else {
            throw new Error(`Не число!!!${item}`);
        }
    }

    function confirmChange(){

        price = anyToInt(price);
        fullPrice = anyToInt(fullPrice);
        let ratio;
        if (price < fullPrice) {
            ratio = `C баланса будет списано: ${fullPrice - price}₽ Вы уверены?`
        } else {
            ratio = 'Вы уверены?';
        }

        Swal.fire({
            title: 'Изменение настроек тарифа!',
            text: `${ratio}`,
            showCancelButton: true,
            cancelButtonColor: '#e2e2e2',
            cancelButtonText: 'Отменить',
            confirmButtonColor: '#E30613',
            confirmButtonText: 'Сменить настройки',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                changeLimits();
            }
        });
    }

    function changeLimits(){

        if (price !== fullPrice){
            Phalcon.system.changeTariffLimits({
                limits: setLimits()
            }).then( answer => {
                if ( answer.status === 200) {
                    refreshSelf();
                }
            });
        }
    }
    function calcPrice () {
        let sum = 0;
        let temp = 0;
        sum = selfProps.limits.reduce( (sum, item, index) => {
            sum += (anyToInt(item.value) - anyToInt(item.min)) * anyToInt(item.system_tables.price);
            return sum;
        },temp);
        price = Math.floor(selfProps.price) + sum;
    }

    function setLimits (){
        let limits = {};
        itemCounts.map( (item) => {
            limits[item.id] = item.value;
        });
        return limits;
    }

    $: {
        if (selfProps.limits){
           confinePrice = selfProps.limits.reduce( (total, item, index) => {
               total += (anyToInt(itemCounts[index].value) - anyToInt(item.value)) * anyToInt(item.system_tables.price);
               return total;
           }, initialConfine);
            fullPrice = price + confinePrice;
        }
    }

    $: yearPay = (fullPrice * 12);
    $: discountPay = fullPrice - ((fullPrice * discount) / 100);
    $: diffPay =  Math.floor(((fullPrice * discount) / 100)* 12);
</script>

<style>
    .text__middle{
        font-size: 16px;
    }

    .tariff__current{
        width: 700px;
    }
    .tariff__offer{
        background: rgba(251, 187, 0, 0.1);
        width: 350px;
    }
    .range__total{
        color: #515151;
        line-height: 1.1;
        padding: 0;
        margin-left: 20px;
        width: 40px;
        height: 20px;
        background: #f5f5f5;
    }
    .range__area{
        color: #515151;
        border: none;
        background: rgba(0,0,0,0);
    }

    .uk-card-title{
        font-weight: 500;
        font-size: 20px;
        color: #515151;
    }

    .text__red {
        color: #e30613;
    }
</style>

<div class="wrapper">
    <div uk-grid class="uk-grid-small">
        <div class="tariff__current">
            <div class="uk-card uk-card-default uk-card-body uk-padding-small">
                <div class="uk-flex uk-flex-right"></div>
                <div class="uk-card-title uk-flex uk-flex-between uk-margin-small-bottom">
                    <div class="uk-width-3-4">Тариф "{selfProps.name}" <a on:click="{() => router.goTo('tariff')}" class="uk-text-muted uk-text-small">сменить тариф</a></div>
                </div>
                <div uk-grid>

                    {#if selfProps.limits}
                        {#each selfProps.limits as data, index}
                        <div class="uk-width-1-2">
                            <div>{data.system_tables.name}</div>
                            <div uk-grid>
                                <div class="uk-width-expand">
                                    <input class="uk-range" type="range" bind:value="{itemCounts[index].value}" min="{data.min}" max="{data.max}" step="1">
                                    <div class="uk-flex uk-flex-between">
                                        <button disabled="true" class="range__area">{data.min}</button>
                                        <button disabled="true" class="range__area">{data.max}</button>
                                    </div></div>
                                <div class="uk-text-center range__total">{itemCounts[index].value}</div>
                            </div>
                        </div>
                        {/each}
                    {/if}

<!--                    фиктивная дата-->
<!--                    {#if selfProps.limits}-->
<!--                    {#each selfProps.limits as data}-->
<!--                    <div class="uk-width-1-2">-->
<!--                        <div>{data.system_tables.name}</div>-->
<!--                        <div uk-grid>-->
<!--                            <div class="uk-width-expand">-->
<!--                                <input class="uk-range" type="range" bind:value="{data.value}" min="{data.min}" max="{data.max}" step="{data.value}">-->
<!--                                <div class="uk-flex uk-flex-between">-->
<!--                                    <button disabled="true" class="range__area">{data.min}</button>-->
<!--                                    <button disabled="true" class="range__area">{data.max}</button>-->
<!--                                </div></div>-->
<!--                            <div class="uk-text-center range__total">{data.value}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    {/each}-->
<!--                    {/if}-->
<!--                    {#if selfProps.limits}-->
<!--                    {#each selfProps.limits as data}-->
<!--                    <div class="uk-width-1-2">-->
<!--                        <div>{data.system_tables.name}</div>-->
<!--                        <div uk-grid>-->
<!--                            <div class="uk-width-expand">-->
<!--                                <input class="uk-range" type="range" bind:value="{data.value}" min="{data.min}" max="{data.max}" step="{data.value}">-->
<!--                                <div class="uk-flex uk-flex-between">-->
<!--                                    <button disabled="true" class="range__area">{data.min}</button>-->
<!--                                    <button disabled="true" class="range__area">{data.max}</button>-->
<!--                                </div></div>-->
<!--                            <div class="uk-text-center range__total">{data.value}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    {/each}-->
<!--                    {/if}-->
<!--                    {#if selfProps.limits}-->
<!--                    {#each selfProps.limits as data}-->
<!--                    <div class="uk-width-1-2">-->
<!--                        <div>{data.system_tables.name}</div>-->
<!--                        <div uk-grid>-->
<!--                            <div class="uk-width-expand">-->
<!--                                <input class="uk-range" type="range" bind:value="{data.value}" min="{data.min}" max="{data.max}" step="{data.value}">-->
<!--                                <div class="uk-flex uk-flex-between">-->
<!--                                    <button disabled="true" class="range__area">{data.min}</button>-->
<!--                                    <button disabled="true" class="range__area">{data.max}</button>-->
<!--                                </div></div>-->
<!--                            <div class="uk-text-center range__total">{data.value}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    {/each}-->
<!--                    {/if}-->
<!--            конец фиктивной даты-->



                </div>
                {#if selfProps.limits}
                    <hr>
                    <div class="uk-flex uk-flex-between">
                        <div class="uk-flex uk-width-1-2">

                            <div>
                                <div class="uk-width-1-1 uk-flex uk-flex-between uk-flex-bottom text__middle">
                                    <span>Текущая стоимость:</span>
                                </div>
                                <div class="uk-width-1-3 uk-flex uk-flex-between uk-flex-bottom text__middle">
                                    <span>Пользователей:</span>
                                    <span class="uk-text-right uk-text-bold">{selfValue}</span>
                                </div>
                                <div class="uk-width-1-3 uk-flex uk-flex-between uk-flex-bottom text__middle">
                                    <span>Цена:</span>
                                    <span class="uk-text-right uk-text-bold">{price}₽</span>
                                </div>
                            </div>

                            <div>
                                <div class="uk-width-1-1 uk-margin-left uk-flex uk-flex-between uk-flex-bottom text__middle">
                                    <span >Будущая стоимость:</span>
                                </div>
                                <div class="uk-width-1-3 uk-margin-left uk-flex uk-flex-between uk-flex-bottom text__middle">
                                    <span >Пользователей:</span>
                                    <span class="uk-text-bold">{itemCounts[0].value}</span>
                                </div>
                                <div class="uk-width-1-3 uk-margin-left uk-flex uk-flex-between uk-flex-bottom text__middle">
                                    <span >Цена:</span>
                                    <span class="uk-text-bold">{fullPrice}₽</span>
                                </div>
                            </div>

                        </div>

                        {#if selfValue != itemCounts[0].value}
                            <div class="uk-flex uk-flex-bottom">
                                <Button on:click="{confirmChange}" type="success" name="Подтвердить"></Button>
                            </div>
                        {/if}
                    </div>
                {/if}
            </div>

        </div>


<!--    Year tariff    -->
<!--        <div class="uk-width-2-5">-->
<!--            <div class="uk-card uk-card-default uk-card-body tariff__offer">-->
<!--                <div class="uk-card-title">Хотите платить меньше?</div>-->
<!--                <div class="uk-margin-small-bottom uk-flex uk-flex-between"><div>- Платить <span class="text__red">{discountPay} ₽</span></div><div>вместо: {fullPrice}₽</div></div>-->
<!--                <div class="uk-margin-small-bottom">- Экономия <span class="text__red">{diffPay} ₽</span> в год</div>-->
<!--                <Button name="Перейти на годовую оплату" on:click="{goToYearPay}"/>-->
<!--            </div>-->
<!--        </div>-->
<!--        -->
    </div>
</div>