<script>
    import { onMount } from "svelte";
    
    import Router from 'modules/router.js';
    import Phalcon from 'modules/phalcon.js'
    
    import Adds from "components/tariffs/TariffsServiceCard.html";
    import PaymentModal from "components/profile/ProfilePaymentModal.html";

    import Table from "UI/Table.html";
    import Button from "UI/ButtonOld.html";

    window.nav.set({
        breadcrumb: [{
            name: 'Мой профиль',
            link: '/profile'
        },{
            name: 'Баланс',
            link: '/my-balance'
        }],
        actions: [],
        actionAddText: ''
    });

    export let hi = "Hello",
        history = {
            columns:[
                {title:"Дата",prop:"date"},
                {title:"Вид операции и описание",prop:"description"},
                {title:"Сумма (руб.)",prop:"amount"},
                // {title:"Документ",prop:"files_id"},
            ],
            items:[
                {isBalance:true, date:"24.01.19", description: "Пополнение баланса", sum:"3000", document: ""},
                {isBalance:false, date:"24.02.19", description: "Ежемесячное списание денежных средств в соответствии тарифному плану", sum:"4500", document: "Stub"},
                {isBalance:true, date:"24.03.19", description: "Пополнение баланса", sum:"3000", document: ""},
                {isBalance:false, date:"24.04.19", description: "Ежемесячное списание денежных средств в соответствии тарифному плану", sum:"4500", document: "Stub"},
                {isBalance:true, date:"24.05.19", description: "Пополнение баланса", sum:"3000", document: ""},
                {isBalance:false, date:"24.06.19", description: "Ежемесячное списание денежных средств в соответствии тарифному плану", sum:"4500", document: "Stub"},
            ]
        },
        selfProps = {},
        payment = {
            tariff: 0,
            balance: 0,
        },
        modal;

    let paymentHistory;

    $: nextSum = ( payment.balance < payment.tariff) ? "не хватает для последующией оплаты" : false;

    onMount(() => {
        Phalcon.system.balance()
            .then( answer => {
                payment.balance = answer;
            })
            .catch( err => {});

        Phalcon.system.tariff()
            .then( answer => {
                if (answer.status === 200){
                    selfProps = answer;
                    // discount = selfProps.year_discount;
                    // itemCounts = selfProps.limits.map( (item) => {
                    //     return {id: item.id, value: item.value};
                    // });
                    payment.tariff = calcPrice();
                }else{
                    throw new Error('tariff')
                }
            })
            .catch( err => {});
    });

    function anyToInt(item) {
        if ( typeof parseInt(item) && !isNaN(item)){
            item = Math.floor(parseInt(item));
            return item;
        } else {
            throw new Error(`Не число!!!${item}`);
        }
    }

    function calcPrice() {
        let sum = 0;
        let temp = 0;
        let price = 0;
        sum = selfProps.limits.reduce( (sum, item, index) => {
            sum += (anyToInt(item.value) - anyToInt(item.min)) * anyToInt(item.system_tables.price);
            return sum;
        },temp);
        price = Math.floor(selfProps.price) + sum;
        return price;
    }

    function takeDate(date){
        return moment(date).format('DD.MM.YY HH:mm')
    }

    Phalcon.request('system-payments', 'all', 'GET')
        .then( answer => {
            if (answer.status === 200) {
                delete answer.status;
                answer.map( (item) => {
                });
                paymentHistory = answer;
            } else {
                throw new Error('Payments');
            }
        })
        .catch( err => {});
</script>

<style lang="scss">.timeCount {
  font-size: 16px;
  font-weight: 300;
  color: #c4c4c4; }

.price {
  font-size: 36px;
  color: yellowgreen; }

.currency {
  font-size: 24px; }

th {
  text-transform: unset;
  color: #c4c4c4;
  font-weight: bold;
  border: 1px solid #e2e2e2; }

td {
  border: 1px solid #e2e2e2;
  color: #515151;
  font-size: 14px;
  font-weight: bold; }
  td a {
    color: #515151;
    font-size: 14px;
    font-weight: bold; }
  td span {
    line-height: 1.5;
    font-weight: bold; }

.isBalance td {
  color: yellowgreen; }

.isPayment td {
  color: #515151; }

.text__head {
  font-size: 16px;
  color: #000; }
</style>

<div class="wrapper">
    <div class="uk-margin-small-bottom">
        <div class="text__head">Финансы</div>
        <div uk-grid>
            <Adds data="{{price: payment.tariff}}" >
                <div slot="title">
                    Ежемесячная оплата
                </div>
                <!-- <div slot="action">
                    <Button name="Мой тариф" on:click="{ () => Router.goTo('my-tariff')}" status="cancel"/>
                </div> -->
            </Adds>

            <Adds >
                <div slot="title">
                    Текущий баланс
                </div>
                <div slot="price" class="uk-height-1-1">
                    <div class="price uk-text-center ">
                        {parseInt(payment.balance).toFixed()}
                        <span class="currency">₽</span>
                    </div>
                    {#if nextSum}
                    <div class="timeCount uk-text-center">{nextSum}</div>
                    {/if}
                </div>
                <div slot="action">
                    <Button name="Пополнить баланс" on:click="{() => modal = {component: PaymentModal}}" status="success"/>
                </div>
            </Adds>
        </div>
    </div>

    <div>
        <div class="text__head">История операций</div>
        {#if paymentHistory}
        <Table columns="{history.columns}" small="true" bind:items="{paymentHistory}" >

            <div slot="confines"></div>

            <tbody slot="tbody">
            {#if paymentHistory}
            {#each paymentHistory as item}
            <tr
                class:isBalance="{parseInt(item.amount, 10) > 0}"
                class:isPayment="{parseInt(item.amount, 10) < 0}"
            >
                {#each history.columns as column}
                {#if column.prop === "files_id" && item[column.prop] === null}
                <td class="uk-text-middle">
                    <span class="uk-text-middle" uk-icon="file-text"></span><a class="uk-text-middle" href="{item[column.prop]}"> Скачать </a>
                </td>
                {:else if (column.prop === "date")}
                <td>
                    {takeDate(item.updated_at)}
                </td>
                {:else}
                <td>
                    {item[column.prop]}
                </td>
                {/if}
                {/each}
            </tr>
            {/each}
            {/if}
            </tbody>
        </Table>
        {:else}
            <div>Грузимся...</div>
        {/if}
    </div>
</div>