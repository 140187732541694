<script context="module">
  export const parseToType = (value, type) => {
    if (type == 'string' && value) {
      return value.toString().toLowerCase()
    } else {
      return "";
    }

    if (type == 'int' && value) {
      return parseFloat(value);
    } else {
      return 0
    }

    return value;
  };
  export const sortASC = (a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };
  export const sortDESC = (a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  };
</script>

<script>
  import { createEventDispatcher } from 'svelte';

  /**
   * @Orders import modules
   *
   */
  import Router from 'modules/router.js';
  import Phalcon from "modules/phalcon.js";
  import Validator from "modules/validator.js";

  /**
   * @Orders import components
   *
   */
  import FilePrint from 'components/files/FilesImportModal.html';
  import Grouplist from "components/groups/GroupsList.html"

  /**
   * @Orders import UI
   *
   */
  import TablePrint from "UI/PrintTable.html";
  import Button from "UI/Button.html"

  /**
   * @Orders {public} params
   * @param {name} strings
   * @param {customSortItems} function
   * @param {customFilterItems} function
   * @param {modal} object
   * @param {deleteField} bool
   * @param {restoreFromArchive} bool
   * @param {columns} array
   * @param {items} array
   * @param {attachment} bool
   * @param {limit} strings
   * @param {divider} bool
   * @param {striped} bool
   * @param {hover} bool
   * @param {small} bool
   * @param {responsive} bool
   * @param {actions} array
   * @param {withLimits} bool
   * @param {tableKey} strings
   */
  export let name ;
  export let customSortItems;
  export let customFilterItems;
  export let modal,
             deleteField = false,
             restoreFromArchive = false,
             columns,
             items,
             attachment,
             limit,
             divider,
             striped,
             hover,
             small,
             responsive,
             actions,
             withLimits = false,
             tableKey = 'groups';

  /**
   * @Orders {private} params
   * @param {test_v3} bool
   * @param {isArchive} bool
   * @param {filterGroup} Array
   * @param {sort} object
   * @param {search} object
   * @param {printElem} node
   */
  const dispatch = createEventDispatcher();
  let test_v3 = false;
  let isArchive = false;
  let filterGroup;
  let sort = {
        prop: null,
        asc: true
      },
      search = {},
      printElem;

/**/
  if(actions && actions.includes('limit')) Phalcon.system.tariff() //Вопрос к Андрею
    .then( answer => {
      if( answer.status === 200){
        delete answer.status;
        if(answer.limits){
          limit = answer.limits;
        }
      } else {
        throw new Error ('something went wrong');
      }})
    .catch( err => console.error);
  //TODO разобраться что это такое!!!
/**/



  /**
   * @Orders component logic
   *
   */
  const sortIt = column => () => {
    sort = {
      prop: column.prop,
      asc: column.prop == sort.prop ? !sort.asc : true,
      type: column.sortType || 'string'
    }
  };
  const refreshFilters = () => {
    for(let prop in search) search[prop] = null
    sort.prop = null
  }
  const refreshSearch = column => () => {
    if (search[column.prop] === '') search[column.prop] = null;
  };
  const print = () => {
    const myPrint = window.open('','myPrint','');
    myPrint.document.write(printElem.innerHTML);
    myPrint.print();
    myPrint.close();
  };
  function setModal() {
    modal = {component: FilePrint, columns: columns, props:{name: name.title,prop: name.prop}};
  };
  function templatePhone(data, phone){
    if (data.prop === "phone"){
      return Validator.modifier.phoneToMask(phone[data.prop]);
    }
  }
  function deleteConfirmation() {
    let result = confirm('При удалении из архива товар не будет доступен для восстановления! Действительно удалить навсегда?');
    return result;
  }
  function filterList (e) {
    filterGroup = items.filter( item => {
      if (e.detail.key === 'all') return true;
      if(item[e.detail.key]){
        return item[e.detail.key] || false;
      } else {
        return true;
      }
    });
  };
  function searchScope() {
    filterGroup = _isCustomFunc(customFilterItems)
      ? customFilterItems(items, search)
      : _defaultFilterItems();
    dispatch('filter', filterGroup);
  }
  function sortScope() {
    const payload = {
      items: filterGroup,
      sortDirection: (sort.asc) ? sortASC : sortDESC,
      sort
    };

    if (sort.prop && sort.asc) {
      (_isCustomFunc(customSortItems))
        ? filterGroup = customSortItems(payload)
        : filterGroup = filterGroup
          .sort((a, b) => sortASC(
            parseToType(a[sort.prop], sort.type),
            parseToType(b[sort.prop], sort.type)));
    } else {
      (_isCustomFunc(customSortItems))
        ? filterGroup = customSortItems(payload)
        : filterGroup = filterGroup
          .sort((a, b) => sortDESC(
            parseToType(a[sort.prop], sort.type),
            parseToType(b[sort.prop], sort.type)));
    }
    dispatch('sort', filterGroup);
  }

  /**
   * @Orders {private} helpers
   *
   */
  function _isCustomFunc(fn){
    return (fn && typeof fn == 'function') ? true : false;
  };
  function _defaultFilterItems(){
    return items.filter(item => {
      for (var key in item) {
        if (search[key]) {
          if (item[key] === null || typeof item[key] === 'undefined' || item[key].length === 0) return false;
          if (item[key].toString().toLowerCase().search(search[key].toLowerCase()) == -1) return false;
        }
      }
      return true;
    });
  }

  /**
   * @Orders computed params
   *
   */
  $: search, searchScope();
  $: items, searchScope();
  $: sort, sortScope();
  $: isArchive, dispatch('loadArchive', isArchive);


</script>

<TablePrint  {columns} {items} bind:elem="{printElem}" />

<div class="wrapper">

  {#if actions && actions.includes('grouplist')}
    <Grouplist      on:filterGroup="{ (event) => filterList(event) }" groups="{[{key: 'ryka', name: 'Рука'}, {key: 'lico', name: 'Лицо'}, {key: 'noga', name: 'Нога'}]}" />
  {/if}

  <slot name="confines">
    <div class="uk-position-relative">
      {#if actions && Array.isArray(actions)}
        <div class="uk-text-muted uk-flex uk-flex-between">
          <div class="uk-width-expand uk-margin-small-bottom">
            {#if actions.includes('print')}
              <a class="uk-icon-button uk-margin-small-right" on:click="{print}" uk-icon="icon: print"></a>
            {/if}
            {#if actions.includes('upload')}
              <Button on:click="{() => dispatch('upload')}" type="default">Загрузить файлом</Button>
              <Button class="uk-margin-small-left" on:click="{() => dispatch('uploadTemplate')}" type="link">Скачать шаблон</Button>
            {/if}
            {#if actions.includes('archive')}
            <input class="uk-margin-remove uk-text-middle" bind:checked="{isArchive}" type="checkbox">
            <span class="uk-text-middle">Показать архив</span>
            {/if}
          </div>
            {#if actions.includes('limit') && limit.length > 0}
              {#each limit as point}
                {#if point.system_tables.key === 'users'}
                  <div class="uk-width-auto uk-text-right">
                    {point.system_tables.name} {items.length} из {point.value}
                    <!-- <br><a href="#" on:click|preventDefault="{ () => Router.goTo('my-tariff') }" class="uk-text-meta">Мой тариф</a> -->
                  </div>
                {/if}
              {/each}
            {/if}
        </div>
      {/if}

      {#if sort.prop || Object.values(search).some(s => s)}
      <div class="uk-position uk-position-right">
        <Button size="small" type="secondary" on:click={refreshFilters}>Сброс фильтров</Button>
      </div>
      {/if}
    </div>
  </slot>

  <div class="uk-overflow-auto" style="max-height: 80vh">
    <table class="uk-table"
          class:uk-table-divider={divider}
          class:uk-table-striped={striped}
          class:uk-table-hover={hover}
          class:uk-table-small={small}
          class:uk-table-responsive={responsive} >
      <slot name='caption'></slot>

      <slot name='thead'>
        <thead>
        <tr>
          {#each columns as column}
          <th on:click="{sortIt(column)}" title="Сменить сортировку">
            <div class="border-bottom {column.class ? column.class : ''}">
            {#if search[column.prop] !== null && typeof search[column.prop] !== 'undefined' }
            <input on:click|stopPropagation autofocus
                  bind:value={search[column.prop]}
                  on:focusout={refreshSearch(column)}
                  class="input__search" type="text" placeholder="Что ищем?" />
            {:else}
            <span >{column.title}</span>
            <span on:click|stopPropagation="{() => search[column.prop] = ''}" class="icon-search" uk-icon="icon: search; ratio: 0.75" title="Поиск по столбцу"></span>
              {#if column.prop === sort.prop}
                <span class="chevron icon-chevron" uk-icon="{sort.asc ? `icon: chevron-up` : `icon: chevron-down`}"></span>
              {/if}
            {/if}
            </div>
          </th>
          {/each}
          {#if deleteField || restoreFromArchive}
            <th class="column__fixed" style="width: 50px"><div class="border-bottom">Действие</div></th>
          {/if}
        </tr>
        </thead>
      </slot>
      <slot name='tbody'>
        <tbody>
        {#each filterGroup as item, index (item.id)}
        <tr on:click="{() => dispatch('infoModal', item)}">
          {#each columns as column}
          <td>
            {#if column.prop === "phone"}
            {templatePhone(column, item)}
            {:else}
            {item[column.prop] || 'Нет данных'}
            {/if}
          </td>
          {/each}

          {#if deleteField}
            <td class="column__fixed uk-text-center">
              {#if restoreFromArchive}
                <a on:click|stopPropagation="{() => dispatch('restoreItem', item.id)}" uk-icon="icon: refresh"></a>
<!--                <a on:click|stopPropagation="{() => deleteConfirmation()}" uk-icon="icon: trash"></a>-->
              {:else}
                <a on:click|stopPropagation="{() => dispatch('deleteItem', item.id)}" uk-icon="icon: trash"></a>
              {/if}
            </td>
          {/if}
        </tr>
        {/each}
        </tbody>
      </slot>
      <slot name='tfoot'></slot>
    </table>
  </div>
</div>

<style lang="scss">/*a[uk-icon="icon: print"]{*/
/*  background: #ffffff;*/
/*}*/
a[uk-icon] {
  margin-right: 10px; }

table {
  margin: 0; }

.wrapper {
  margin-bottom: 25px;
  max-height: 80vh; }

table,
td,
th {
  border: 1px solid #E2E2E2;
  border-top: 0 !important;
  box-sizing: border-box;
  background: #ffffff;
  text-transform: unset;
  font-size: 14px;
  color: #515151; }
  tableinput,
  tdinput,
  thinput {
    box-sizing: border-box; }

th {
  font-weight: bold !important;
  position: sticky;
  z-index: 9;
  top: 0;
  padding: 0 !important;
  cursor: pointer; }

td {
  color: #7f7f7f;
  font-size: 14px;
  padding: 8px; }
  td a {
    color: #c4c4c4; }
    td a:hover {
      color: #c4c4c4; }

.icon-search {
  color: #c4c4c4;
  position: absolute;
  right: 5px;
  bottom: 11px;
  vertical-align: bottom;
  display: none;
  cursor: pointer; }

th:hover .icon-search {
  display: block; }

.icon-chevron {
  color: #c4c4c4;
  position: absolute;
  right: 20px;
  bottom: 8px; }

.input__search {
  margin: 0;
  padding: 0;
  width: 150px !important; }

.uk-select:not([multiple]):not([size]) {
  background-image: none; }

select {
  box-shadow: 1px 1px 1px #515151;
  height: 25px !important;
  font-size: 12px;
  padding: 0 3px !important;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 3px; }
  select:focus {
    outline: none; }

.border-bottom {
  border-bottom: 1px solid #E2E2E2;
  padding: 8px; }
</style>