<script>
  import {createEventDispatcher} from 'svelte';
  const dispatch = createEventDispatcher();

  export let min,max,count;

  if(typeof count !== "number") count = parseInt(max);

  $: {
    if(count > max) count = parseInt(max);
    if(count < min || !count) count = parseInt(min);
  }


</script>

<style lang="scss">button {
  width: 1.5rem;
  height: 1.5rem; }
</style>

<div class="uk-flex uk-flex-between">
  <button on:click="{() => count--}">-</button>
  <input type="number" min={min} max={max} bind:value={count}>
  <button on:click="{() => count++}">+</button>
</div>