<script>
    import { get } from 'svelte-v3/store'
    import { createEventDispatcher, onMount, onDestroy } from 'svelte'
    import { Panel, View, Dropdown, CollapseToggle, CollapseContent, DragItems, Checkbox, CheckboxGroup, Button, Icon, Switch, successToast, alertToast, Activity } from 'svelte-metro-ui'
    import { HsvPicker } from 'svelte-color-picker'
    import { filteredItems, ListKey, selectedItems } from './MultiListStore'
    import { routesShowOnMap } from './ConfigStore'
    import { focusServices, loading } from './PageStore'
    import { createRoute, saveRoute, deleteAllRoutes, routesStore } from '../../stores/RoutesStore'
    import { servicesStore, editAllServices } from '../../stores/DeliveryPlannerStore'
    import { usersMapStore, usersStore } from '../../stores/UsersStore'
    import Backdrop from 'UI/Backdrop.html'
    import { updateRouteOnMapStoreInFilteredItems, editableRoutesMap, addRouteFromEditableRoutes, deepRefreshRoutes, removeRouteFromEditableRoutes, removeRouteStoresFromFilteredItems } from './RoutesStore'
    import { updateServicesInStoreByMap, updateServicesInStoreByRoutesId } from './ServicesStore'
    import { DeliveryUserSelect } from '../users'
    import { rgbToHex } from '../../../helpers/colors.helper'
    import { userRoutes } from './UserRoutesStore'

    let pickColorDropdownEl = null
    const dispatch = createEventDispatcher()

    export let routeStore = null
    export let route = routeStore ? $routeStore : null
    export let showOnMap = true

    let services = route && route.services ? route.services.sort((a,b) => a.routes_order - b.routes_order) : []
    let servicesLoading = false
    let selected = []
    let distanceText = '0 км'
    let durationText = '0 мин'
    let pickColor = route.color
    let pickColorPanel = false
    let deleteLoading = false
    let circle = route.circle
    let deliveryUserId = route && route.delivery_user_id ? route.delivery_user_id : null
    let saveLoading = false

    let routeStoreUnsubscribe = null

    if (routeStore) routeStoreUnsubscribe = routeStore.subscribe(v => {
        if (v && v.isChanged) {
            v.delivery_services_ids = services.map(s => s.id).join(',')
            if (!v.delivery_user_id) v.delivery_user_id = deliveryUserId
            addRouteFromEditableRoutes(v)
        }
    })

    let servicesStoreUnsubscribe = servicesStore.subscribe(v => {
        let withPickups = []
        v.forEach(s => {
            if (s.pickups) s.pickups.forEach(p => withPickups.push(p))
            withPickups.push(s)
        })

        servicesLoading = true
        services = withPickups
            .filter(s => {
                if (!s.routes_id) return false
                if (s.routes_id == route.id) return true
                if (s.routes_id == route.uid) return true
                return false
            })
            .sort((a,b) => a.routes_order - b.routes_order)

        if (routeStore) $routeStore.services = services
        setTimeout(() => servicesLoading = false, 50)
    })

    const getSelectedUserFullname = () => {
        let userId, user
        let fullname = ''

        if ($selectedItems[ListKey.Users]) userId = $selectedItems[ListKey.Users][0]
        if (userId) user = $usersMapStore[userId]
        if (user) fullname = `${user.last_name} ${user.first_name}`

        return fullname
    }
    
    const handleSortedRoute = e => {
        let sortedServices = e.detail
        let servicesMap = {}
        let routes_id = route.id || route.uid
        
        sortedServices.forEach((ss, idx) => {
            servicesMap[ss.id] = {
                routes_order: idx
            }
        })

        updateServicesInStoreByMap(servicesMap)
        updateRouteOnMapStoreInFilteredItems(routes_id, {
            geometry: null,
            points: sortedServices.map(s => {
                return {
                    id: s.id,
                    lat: s.lat,
                    lng: s.lng
                }
            })
        })
    }

    const handleSaveRoute = async () => {
        if (saveLoading) return
        let answer = null
        let params = {...route}
        saveLoading = true

        params.delivery_services_ids = services.map(s => s.id).join(',')

        if (route.id) {
            answer = await saveRoute(route.id, params)
        } else {
            let routes_id = route.uid
            answer = await createRoute(params)

            if (answer.status == 200) {
                if (answer.payload.id) {
                    updateServicesInStoreByRoutesId(routes_id, {
                        routes_id: answer.payload.id
                    })
                }
            }
        }
            
        if (answer.status == 200) {
            route.isChanged = false
            $loading[ListKey.Routes] = true
            setTimeout(() => $loading[ListKey.Routes] = false, 50)
        }
        
        removeRouteFromEditableRoutes(route)

        saveLoading = false
    }
    
    const handleDeleteRoute = async () => {
        deleteLoading = true

        let routes_id = route.id || route.uid
        updateServicesInStoreByRoutesId(routes_id, {
            routes_id: null,
            routes_order: null,
            delivery_user_id: null
        })

        if (route.id) {
            const answer = await deleteAllRoutes(route.id)
            await deepRefreshRoutes()
        } else if (route.uid) {
            removeRouteStoresFromFilteredItems(route.uid)
        }

        deleteLoading = false
    }

    const handleShowOnMapChange = () => {
        dispatch('showOnMap', {route, showOnMap})
    }

    const confirmColor = () => {
        let routes_id = route.id || route.uid
        route.color = pickColor
        route.isChanged = true

        updateRouteOnMapStoreInFilteredItems(routes_id, { 
            color: pickColor
        })
    }

    const handleChangeColor = e => {
        pickColor = rgbToHex(e.detail.r, e.detail.g, e.detail.b)
        
        if (route.color != pickColor) {
            confirmColor()
        }
    }

    const handleSetUserToRoute = async () => {
        let userId, user

        if ($selectedItems[ListKey.Users]) {
            userId = $selectedItems[ListKey.Users][0]
        } else {
            alertToast('Произошла ошибка: не обнаружен выбранный курьер')
        }

        if (userId && services) {
            user = $usersMapStore[userId]

            const answer = await editAllServices(services.map(s => s.id).join(','), {
                delivery_user_id: userId
            })

            if (answer.status == 200) {
                successToast(`Курьер "${user.last_name} ${user.first_name}" назначен на маршрут ${route.uid || route.id}`)
            } else {
                alertToast(`Произошла ошибка: ${answer.message}`)
            }
        } else {
            alertToast('Произошла ошибка: не обнаружен идентификатор пользователя или точки')
        }
    }

    const handleRemoveUserFromRoute = async () => {
        if (!confirm(`Вы уверены что хотите открепить текущих курьеров от маршрута ${route.uid || route.id}`)) return

        const answer = await editAllServices(services.map(s => s.id).join(','), {
            delivery_user_id: 0
        })

        if (answer.status == 200) {
            successToast(`От маршрута ${route.uid || route.id} откреплены текущие курьеры`)
        } else {
            alertToast(`Произошла ошибка: ${answer.message}`)
        }
    }

    const handleAddServicesToRoute = async () => {
        let selected = $selectedItems[ListKey.Services]
        let idx = 0
        let selectedServices = []
        let hasRoute = false

        $servicesStore.forEach(s => {
            s.pickups.forEach(p => {
                if (selected.includes(p.id)) {
                    if (p.routes_id || p.routes_order) {
                        hasRoute = true
                    }
                }
            })

            if (selected.includes(s.id)) {
                if (s.routes_id || s.routes_order) {
                    hasRoute = true
                }
            }
        })

        if (hasRoute) {
            alertToast("Нельзя добавить точки уже состоящие в маршруте, сначала удалите их из маршрута")
            return
        }

        $servicesStore = $servicesStore.map(s => {
            if (s.pickups) s.pickups = s.pickups.map(p => {
                if (selected.includes(p.id)) {
                    p.routes_order = services.length + idx
                    p.routes_id = route.id || route.uid
                    selectedServices.push(p)
                    idx++
                }

                return p
            })

            if (selected.includes(s.id)) {
                s.routes_order = services.length + idx
                s.routes_id = route.id || route.uid
                selectedServices.push(s)
                idx++
            }

            return s
        })

        if (!selectedServices.length) {
            return
        }
        
        servicesLoading = true
        services = services.concat(selectedServices)

        let routeOnMapStore = $filteredItems[ListKey.RoutesOnMap]
            .find(rs => 
                get(rs).id == route.id || 
                get(rs).uid == route.uid
            )

        if (routeOnMapStore) {
            let routeOnMap = get(routeOnMapStore)
            routeOnMap.geometry = null
            routeOnMap.points = services.map(s => {
                return {
                    lat: s.lat,
                    lng: s.lng
                }
            })
            routeOnMapStore.set(routeOnMap)
        }

        if (routeStore) {
            let route = get(routeStore)
            route.delivery_services_ids = services.map(ss => ss.id).join(',')
            routeStore.set(route)
        }

        setTimeout(() => servicesLoading = false, 300)
    }

    const handleRemoveServicesToRoute = async () => {
        let selected = $selectedItems[ListKey.Services]
        let selectedServices = []
        let idx = 0
        let notHasRoute = false
        
        servicesLoading = true
        services = services.filter(s => !$selectedItems[ListKey.Services].includes(s.id))
        let currentServicesIds = services.filter(s => !$selectedItems[ListKey.Services].includes(s.id)).map(s => s.id)

        $servicesStore = $servicesStore.map(s => {
            if (s.pickups) s.pickups = s.pickups.map(p => {
                if (selected.includes(p.id)) {
                    if (p.routes_id == route.id || route.uid) {
                        p.routes_order = null
                        p.routes_id = null
                        selectedServices.push(p)
                    }
                }

                if (currentServicesIds.includes(p.id)) {
                    p.routes_order = idx++
                }

                return p
            })

            if (selected.includes(s.id)) {
                if (s.routes_id == route.id || route.uid) {
                    s.routes_order = null
                    s.routes_id = null
                    selectedServices.push(s)
                }
            }

            if (currentServicesIds.includes(s.id)) {
                s.routes_order = idx++
            }

            return s
        })

        // if (hasRoute) {
        //     alertToast("Нельзя добавить точки уже состоящие в маршруте, сначала удалите их из маршрута")
        //     return
        // }


        let routeOnMapStore = $filteredItems[ListKey.RoutesOnMap]
            .find(rs => 
                get(rs).id == route.id || 
                get(rs).uid == route.uid
            )

        if (routeOnMapStore) {
            let routeOnMap = get(routeOnMapStore)
            routeOnMap.geometry = null
            routeOnMap.points = services.map(s => {
                return {
                    lat: s.lat,
                    lng: s.lng
                }
            })
            routeOnMapStore.set(routeOnMap)
        }

        if (routeStore) {
            let route = get(routeStore)
            route.delivery_services_ids = services.map(ss => ss.id).join(',')
            routeStore.set(route)
        }

        setTimeout(() => servicesLoading = false, 300)
    }

    const handleMouseenter = service => {
        let fServices = []
        
        fServices.push(service.id)

        $focusServices = fServices
    }

    const handleMouseleave = service => {
        $focusServices = []
    }

    const handleOptimizedRoute = () => {
        let routes_id = route.id || route.uid
        updateRouteOnMapStoreInFilteredItems(routes_id, {
            geometry: null,
            optimize: true,
            points: services.map(s => {
                return {
                    lat: s.lat,
                    lng: s.lng
                }
            })
        })
    }

    const handleToggleRouteCircle = () => {
        circle = !circle
        route.circle = circle

        let routes_id = route.id || route.uid
        updateRouteOnMapStoreInFilteredItems(routes_id, {
            geometry: null,
            circle: circle,
            points: services.map(s => {
                return {
                    lat: s.lat,
                    lng: s.lng
                }
            })
        })
    }

    const handleUserChange = async () => {
        let routes_id = route.id || route.uid
        if (route.id) {
            const answer = await editAllServices(services.map(s => s.id).join(','), {
                delivery_user_id: deliveryUserId
            })

            if (answer.status == 200) {
                successToast(`Курьер назначен на маршрут ${routes_id}`)
            } else {
                alertToast(`Произошла ошибка: ${answer.message}`)
            }
        } else {
            if (routeStore) {
                $routeStore.delivery_user_id = deliveryUserId
                updateServicesInStoreByRoutesId(routes_id, {
                    delivery_user_id: deliveryUserId
                })
            }
        }
    }

    onMount(() => {
        let tmp = []
        $servicesStore.forEach(s => {
            if (s.pickups) s.pickups.forEach(p => {
                if (p.routes_id == (route.id || route.uid)) tmp.push(p)
            })
            if (s.routes_id == (route.id || route.uid)) tmp.push(s)
        })

        services = tmp.sort((a,b) => a.routes_order - b.routes_order)

        UIkit.util.on(pickColorDropdownEl, 'show', function () {
            pickColorPanel = true
        })
        UIkit.util.on(pickColorDropdownEl, 'hide', function () {
            pickColorPanel = false
        })
    })

    onDestroy(() => {
        if (servicesStoreUnsubscribe) servicesStoreUnsubscribe()
        if (routeStoreUnsubscribe) routeStoreUnsubscribe()
        removeRouteFromEditableRoutes(route)
    })

    $: if (route.distance !== null) {
        distanceText = (route.distance / 1000).toFixed(2).toString() + ' км';
    } else {
        distanceText = '0 км'
    }

    $: if (route.duration !== null) {
        let seconds = route.duration
        let hours = Math.floor(seconds / 60 / 60);
        if (hours) seconds -= (hours * 60 * 60);

        let minutes = Math.ceil(seconds / 60);
        durationText = (hours ? hours + ' ч ' : '') + minutes + ' мин';
    } else {
        durationText = '0 мин'
    }

    $: if (routeStore && $routeStore) {
        route = $routeStore
    }

    // $: if (route.services && (route.services != services || route.forceServicesUpdate)) {
    //     console.log("SERVICES_UPDATE")
    //     route.forceServicesUpdate = false
    //     servicesLoading = true
    //     services = route.services
    //     setTimeout(() => servicesLoading = false, 300)
    // }

    $: deliveryUserId = $servicesStore.filter(s => s.routes_id == (route.id || route.uid)).map(s => s.delivery_user_id)[0] || null
</script>

<Panel class="mb-2 pos-relative">
    {#if deleteLoading}
        <Backdrop>
            <Activity />
        </Backdrop>
    {/if}
    <View class="uk-flex uk-flex-between uk-flex-middle uk-width">
        <View class="uk-flex uk-flex-middle">
            <CollapseToggle key="routes-{route.uid}" />
            <div class="uk-inline">
                <div class="color-circle" style="background-color: {route.color}"></div>
                <div uk-dropdown="mode: click" bind:this={pickColorDropdownEl}>
                    {#if pickColorPanel}
                        <HsvPicker on:colorChange={handleChangeColor} startColor={route.color}/>
                    {/if}
                </div>
            </div>
            <Checkbox 
                caption="Маршрут {route.uid}" 
                value={route.id}
            />
        </View>
        <Dropdown left>
            <ul class="v-menu ml-0" style="display: block; position:relative !important; right: 0 !important;">
                <li>
                    <a on:click={handleDeleteRoute}>
                        <span class="mif-bin icon"></span> Удалить маршрут
                    </a>
                </li>
                <li>
                    <a on:click={handleRemoveUserFromRoute}>
                        <span class="mif-undo icon"></span> Открепить курьеров
                    </a>
                </li>
                <li>
                    <a on:click={handleOptimizedRoute}>
                        <span class="mif-calculator icon"></span> Оптимизировать
                    </a>
                </li>
                {#if $selectedItems[ListKey.Users].length == 1}
                    <li class="menu-title">Курьер "{getSelectedUserFullname()}"</li>
                    <li>
                        <a on:click={handleSetUserToRoute}><span class="mif-motorcycle icon"></span> Назначить на маршрут</a>
                    </li>
                {/if}
                {#if $selectedItems[ListKey.Services].length}
                    <li class="menu-title">Выбрано {$selectedItems[ListKey.Services].length} точек</li>
                    <li>
                        <a on:click={handleAddServicesToRoute}><span class="mif-file-download icon"></span> Добавить в маршрут</a>
                    </li>
                    <li>
                        <a on:click={handleRemoveServicesToRoute}><span class="mif-file-upload icon"></span> Убрать из маршрута</a>
                    </li>
                {/if}
                <li class="menu-title">Настройки</li>
                <li>
                    <a on:click={handleToggleRouteCircle}>
                        <span class="icon menu-checkbox" style="pointer-events: none !important;">
                            <Checkbox bind:checked={circle} />
                        </span>
                        Кругорейс
                    </a>
                </li>
            </ul>
        </Dropdown>
    </View>
    <View>
        <span class="uk-text-meta">
            <Icon value="enlarge2" />
            <span style="margin-left: 3px">{distanceText}</span>
        </span>
        <span class="uk-text-meta uk-margin-small-left">
            <Icon value="alarm" />
            <span style="margin-left: 3px">{durationText}</span>
        </span>
        {#if route.weight}
            <span class="uk-text-meta uk-margin-small-left">
                <Icon value="barbell" />
                <span style="margin-left: 3px">{route.weight} кг</span>
            </span>
        {/if}
        {#if route.volume}
            <span class="uk-text-meta uk-margin-small-left">
                <Icon value="codepen" />
                <span style="margin-left: 3px">{parseFloat(route.volume).toFixed(2)} м³</span>
            </span>
        {/if}
    </View>
    <View>
        <DeliveryUserSelect
            on:change={handleUserChange}
            bind:value={deliveryUserId}
            users={$usersStore.filter(u => !$userRoutes[u.id])}
            selectedUser={$usersStore.find(u => u.id == deliveryUserId)}
        />
    </View>
    {#if $routesShowOnMap}
        <View class="w-100 text-right">
            <Switch bind:checked={showOnMap} on:change={handleShowOnMapChange} left>Показать на карте</Switch>
        </View>
    {/if}
    <CollapseContent toggleKey="routes-{route.uid}" collapsed>
        <CheckboxGroup bind:group={selected}>
            {#if servicesLoading}
                <div uk-spinner></div>
            {:else}
                <DragItems items={services} on:sorted={handleSortedRoute} numeric let:item>
                    <div 
                        class="pt-2"
                        on:mouseenter={handleMouseenter(item)}
                        on:mouseleave={handleMouseleave(item)}
                    >
                        <Panel class="d-flex delivery-planner-service-item">
                            {#if item.pickups}
                                <b style='color:darkorange;'>Доставка</b> <i>{item.number}</i>
                            {:else}
                                <b style='color:darkgreen;'>Забор</b> <i>{item.number}</i>
                            {/if}
                        </Panel>
                    </div>
                </DragItems>
            {/if}
        </CheckboxGroup>
    </CollapseContent>
    {#if route.isChanged}
        <View class="mt-2">
            <div class="uk-alert-warning uk-margin-remove" uk-alert>
                <p>Маршрут отредактирован но не сохранен</p>
                {#if !$editableRoutesMap || Object.keys($editableRoutesMap).length < 2}
                    <View class="w-100 text-center mt-2">
                        <Button on:click={handleSaveRoute} warning>
                            {#if saveLoading}
                                <div uk-spinner></div>
                            {:else}
                                Сохранить маршрут
                            {/if}
                        </Button>
                    </View>
                {/if}
            </div>
        </View>
    {/if}
</Panel>

<style>
    .color-circle {
        width: 10px;
        height: 10px; 
        border-radius: 100%;
        margin-left: 7px;
        margin-right: 7px;
        cursor: pointer;
    }
</style>