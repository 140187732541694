<script>

</script>

<div class="uk-padding-small uk-position-bottom-left uk-background-default uk-text-right uk-width uk-margin-small-top footer">
    <slot>
        
    </slot>
</div>

<style>
    .footer {
        border-top: 1px solid #dfdfdf; 
        position: sticky !important;
        z-index: 999;
    }
</style>