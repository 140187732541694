<script>
    import { Dropdown, Panel, Icon, Checkbox, alertToast, Activity } from "svelte-metro-ui"
    import { editAllServices, statusesMapStore, statusesStore, archiveAllServices, setTypeToAllServices } from '../../stores/DeliveryPlannerStore'
    import { routesMapStore } from '../../stores/RoutesStore'
    import { usersStore, usersMapStore } from '../../stores/UsersStore'
    import { addEditableService } from './PageStore'
    import { servicesShowStatusAndUserPreview } from './ConfigStore'
    import { userRoutes } from './UserRoutesStore'
    import { serviceChangeUser } from './ServicesStore'
    import { OrdersListItemInfo } from '.'
    import { DeliveryUserSelect } from "../users"
    import Backdrop from 'UI/Backdrop.html'

    export let service = null
    export let selected = []
    let userChangeLoading = false
    let prevDeliveryUserId = service ? service.delivery_user_id : null
    let showDetail = false
    let isLoad = false

    const handleAddEditableService = () => addEditableService(service)
    const handleUserChange = async () => {
        let delivery_user_id = service.delivery_user_id
        userChangeLoading = true
        isLoad = true

        await serviceChangeUser(
            service, 
            delivery_user_id,
            prevDeliveryUserId,
            $userRoutes[delivery_user_id],
            $userRoutes[prevDeliveryUserId]
        );
        
        userChangeLoading = false
        isLoad = false
        prevDeliveryUserId = delivery_user_id
    }
    const handleArchiveService = async () => {
        if (service.pickups) {
            if (service.pickups.some(p => p.routes_id)) {
                alertToast("Нельзя удалить точки состоящие в маршруте, сначала удалите маршрут")
                return
            }
        }
        if (service.routes_id) {
            alertToast("Нельзя удалить точки состоящие в маршруте, сначала удалите маршрут")
            return
        }

        if (service.pickups) {
            if (service.pickups.some(p => p.order_number)) {
                alertToast("Нельзя удалить точки состоящие в заказе, сначала удалите заказ")
                return
            }
        }
        if (service.order_number) {
            alertToast("Нельзя удалить точки состоящие в заказе, сначала удалите заказ")
            return
        }

        isLoad = true
        service.loading = true
        const answer = await archiveAllServices([...(service.pickups ? service.pickups.map(p => p.id) : []), service.id].join(','))
        service.loading = false
        isLoad = false
    }
    
    const handleStatusChange = async () => {
        isLoad = true
        service.statusLoading = true
        const answer = await editAllServices(service.id, {
            status_id: service.status_id
        })
        service.statusLoading = false
        isLoad = false
    }
    const handleSetType = type => async () => {
        const answer = await setTypeToAllServices(service.id, type)
    }
    const handleShowDetail = () => {
        showDetail = !showDetail
    }
</script>

{#if service}
    <Panel class="mb-2 pos-relative delivery-planner-service-item">
        {#if isLoad}
            <Backdrop>
                <Activity />
            </Backdrop>
        {/if}
        {#if $servicesShowStatusAndUserPreview}
            <div class="uk-width uk-flex uk-flex-between pl-1 pr-1">
                {#if service.statusLoading}
                    <div class="uk-margin-small-left" uk-spinner="ratio: 0.5;"></div>
                {:else}
                    <div uk-form-custom="target: null">
                        <select bind:value={service.status_id} on:change={handleStatusChange}>
                            {#each $statusesStore as status (status.id)}
                                <option style="color: {status.color};" value={status.id}>{status.name}</option>
                            {/each}
                        </select>
                        <span class="uk-flex uk-flex-middle">
                            <span style="color: {$statusesMapStore.hasOwnProperty(service.status_id) ? $statusesMapStore[service.status_id].color : ''}; font-size:14px;">
                                {$statusesMapStore.hasOwnProperty(service.status_id) && $statusesMapStore[service.status_id] ? $statusesMapStore[service.status_id].name : 'В ожидании'}
                            </span>
                        </span>
                    </div>
                {/if}
            
                {#if userChangeLoading}
                    <div class="uk-margin-small-right" uk-spinner="ratio: 0.5;"></div>
                {:else}
                    <DeliveryUserSelect
                        on:change={handleUserChange}
                        bind:value={service.delivery_user_id}
                        users={$usersStore}
                        selectedUser={$usersMapStore.hasOwnProperty(service.delivery_user_id) ? $usersMapStore[service.delivery_user_id] : null}
                    />
                    <!-- <div uk-form-custom="target: null" on:change={handleUserChange}>
                        <select bind:value={service.delivery_user_id}>
                            <option value={null}>Нет курьера</option>
                            {#each $usersStore as user (user.id)}
                                <option value={user.id}>{user.last_name} {user.first_name}</option>
                            {/each}
                        </select>
                        <span class="uk-flex uk-flex-middle">
                            <span style="font-size:14px;">
                                {$usersMapStore.hasOwnProperty(service.delivery_user_id) && $usersMapStore[service.delivery_user_id] ? `${$usersMapStore[service.delivery_user_id].last_name} ${$usersMapStore[service.delivery_user_id].first_name}` : 'Нет курьера'}
                            </span>
                        </span>
                    </div> -->
                {/if}
            </div>
        {/if}
        <div class="uk-width uk-flex uk-flex-between uk-flex-middle">
            <div class="uk-flex uk-flex-middle">
                <Icon on:click={handleShowDetail} value={showDetail ? "arrow-drop-up" : "arrow-drop-down"} pointer />
                <!-- {#if service.service_type == 'delivery'}
                    <Checkbox caption="<b style='color:darkorange;'>Доставка</b> <i>{service.number}</i>" value={service.id} />
                {:else}
                    <Checkbox caption="<b style='color:darkgreen;'>Забор</b> <i>{service.number}</i>" value={service.id} />
                {/if} -->
                <div class="uk-inline">
                    <div class="color-circle" style="background-color: {service.service_type == 'pickup' && !service.pickups ? 'darkgreen' : 'darkorange'}"></div>
                </div>
                <Checkbox caption="<span style='font-size:14px;'>{service.address}</span>" value={service.id} />
            </div>
            <div class="uk-flex">
                <Dropdown left>
                    <ul class="d-menu context ml-0" style="display: block; position:relative !important; right: 0 !important;">
                        <li>
                            <a on:click={handleAddEditableService}><span class="mif-eye icon"></span> Детали</a>
                        </li>
                        <li>
                            <a on:click={handleArchiveService}><span class="mif-bin icon"></span> Удалить</a>
                        </li>
                        <li>
                            <a on:click={handleSetType('delivery')}>Отметить как <b style="color: darkorange">Доставка</b></a>
                        </li>
                        <li>
                            <a on:click={handleSetType('pickup')}>Отметить как <b style="color: darkgreen">Забор</b></a>
                        </li>
                    </ul>
                </Dropdown>
            </div>
        </div>
        {#if service.routeNotSave}
            <span style="color: #faa05a !important; font-size: 11px !important;">
                <span class="uk-margin-small-right" uk-icon="icon: info; ratio: 0.8;"></span>
                Маршрут {service.routes_id} не сохранен
            </span>
        {/if}
        {#if showDetail}
            <div class='p2'>
                <div class="uk-width">
                    <div class="uk-flex uk-flex-between">
                        <span class="uk-margin-right" style="font-size:14px;">Статус</span>
                        <div>
                            {#if service.statusLoading}
                                <div class="uk-margin-small-left" uk-spinner="ratio: 0.5;"></div>
                            {:else}
                                <div uk-form-custom="target: null">
                                    <select bind:value={service.status_id} on:change={handleStatusChange}>
                                        {#each $statusesStore as status (status.id)}
                                            <option style="color: {status.color};" value={status.id}>{status.name}</option>
                                        {/each}
                                    </select>
                                    <span class="uk-flex uk-flex-middle">
                                        <span style="color: {$statusesMapStore.hasOwnProperty(service.status_id) ? $statusesMapStore[service.status_id].color : ''}; font-size:14px;">
                                            {$statusesMapStore.hasOwnProperty(service.status_id) && $statusesMapStore[service.status_id] ? $statusesMapStore[service.status_id].name : 'В ожидании'}
                                        </span>
                                        <span uk-icon="chevron-down"></span>
                                    </span>
                                </div>
                            {/if}
                        </div>
                    </div>

                    <div class="uk-flex uk-flex-between">
                        <span class="uk-margin-right" style="font-size:14px;">Курьер</span>
                        <div>
                            {#if service.userLoading}
                                <div class="uk-margin-small-right" uk-spinner="ratio: 0.5;"></div>
                            {:else}
                                <DeliveryUserSelect
                                    on:change={handleUserChange}
                                    bind:value={service.delivery_user_id}
                                    users={$usersStore}
                                    selectedUser={$usersMapStore.hasOwnProperty(service.delivery_user_id) ? $usersMapStore[service.delivery_user_id] : null}
                                />
                            {/if}
                        </div>
                    </div>
                </div>
                <div class="uk-width">
                    {#if service.routes_id && $routesMapStore[service.routes_id]}
                        <div class="uk-flex">
                            <div class="color-circle" style="background-color: {$routesMapStore[service.routes_id].color}"></div>
                            <span style="font-size:11px;">
                                Машрут {$routesMapStore[service.routes_id].uid || $routesMapStore[service.routes_id].id}
                            </span>
                        </div>
                    {/if}
                    {#if service.order_number}
                        <span style="font-size:11px;">
                            Заказ {service.order_number}
                        </span>
                    {/if}
                </div>
                <OrdersListItemInfo {service} />
            </div>
        {/if}
    </Panel>
{/if}

<style>
    .color-circle {
        width: 7px;
        height: 7px; 
        border-radius: 100%;
        margin-right: 7px;
        margin-left: 7px;
        cursor: pointer;
    }
</style>