<script>
    import Input from "UI/Input.html"

    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let isRanged = false;
    export let value;
    export let requireName;
    export let required;
    export let min;
    export let inputSize;
    export let isValidCallback = () => {
        if (value == "" || !value) {
            setDanger();
            return false;
        }
        return true;
    };
    export let date_start = moment().format('YYYY-MM-DD');
    export let date_end = moment().format('YYYY-MM-DD');

    let date = value;
    let container;
    let ranged;
    let input;
    let formatDate;

    let periods = ['Сегодня', 'Завтра', 'Вчера', 'Тек.неделя', 'Прошл.неделя', 'Тек.месяц', 'Прошл.месяц'];

    function setDanger() {
        input.classList.add('uk-animation-shake', 'uk-text-danger');
        setTimeout(() => {
            input.classList.remove('uk-animation-shake');
        }, 300);

        dispatch('danger');
    }

    function removeDanger() {
        if (input) input.classList.remove('uk-animation-shake', 'uk-text-danger');
    }

    $: {
        date_start = moment(date_start).format('DD.MM.YYYY');
        date_end = moment(date_end).format('DD.MM.YYYY');

        if(date_start == date_end) formatDate = date_start;
        else formatDate = date_start + ' - ' + date_end;
    };

    $: value, removeDanger();

    onMount(() => {
        let templates;
        if (isRanged) {
            templates = {
                container: [
                    '<div class="datepicker__container" uk-grid>',
                    '<% for (var i = 0; i <= 1; i++) { %>',
                    '<div class="datepicker__pane uk-width-expand">',
                    '<%= renderHeader(i) %>',
                    '<%= renderCalendar(i) %>',
                    '</div>',
                    '<% } %>',
                    '<div><button onclick="window.datepicker.hide()" class="uk-button uk-button-primary uk-button-small uk-position-bottom-right">Применить</button><div>',
                    '</div>'
                ].join('')
            };
        } else {
            templates = {
                container: [
                    '<div class="datepicker__container" uk-grid>',
                    '<% for (var i = 0; i < 1; i++) { %>',
                    '<div class="datepicker__pane uk-width-expand">',
                    '<%= renderHeader(i) %>',
                    '<%= renderCalendar(i) %>',
                    '</div>',
                    '<% } %>',
                    '</div>'
                ].join('')
            };
        }

        ranged = new Datepicker(input, {
            inline: false,
            ranged: isRanged,
            weekStart: 1,
            separator: '-',
            templates: templates,
            i18n: {
                months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
            },
            onChange(date) {
                if (isRanged) {
                    if(date.length > 1){
                        date_start = moment(date[0]).format('YYYY-MM-DD');
                        date_end = moment(date[date.length - 1]).format('YYYY-MM-DD');
                    } else {
                        date_start = moment(date[0]).format('YYYY-MM-DD');
                        date_end = moment(date[0]).format('YYYY-MM-DD');
                    }
                }
                   
                value = moment(date).format('YYYY-MM-DD');
            }
        });

        if(date) ranged.setDate(new Date(date));

        window.datepicker = ranged;

    });
</script>

<Input 
    {requireName} {required}

    size={inputSize}
    onkeydown="if(event.key == 'Backspace') this.value = ''; return false;" 
    bind:input value={formatDate} 
/>

<style>
    :global(.datepicker) {
        width: 100%;
    }

    :global(.datepicker__wrapper) {
        min-width: 100%;
        max-width: 600px;
        width: fit-content !important;
    }

    :global(.datepicker__container) {
        padding-bottom: 30px;
        position: relative;
    }
</style>