<script>
    import SelfEmployeesCards from 'components/self_employees/SelfEmployeesList.html';
</script>

<div id="wrapper" class="uk-grid-small uk-height-1-1" uk-grid>
    <div class="uk-width-auto uk-background-default" style="width: 200px">
        <ul class="uk-tab-left" uk-tab>
            <li class="uk-active"><a href="#">Все самозанятые</a></li>
            <li><a href="#">Избранное</a></li>
            <li><a href="#">История заказов</a></li>
        </ul>
    </div>
    <div class="uk-width-expand uk-margin-small-top">
        <SelfEmployeesCards />
    </div>
</div>

<style>
    #wrapper {
        margin-top: -15px;
    }
</style>
