<script>
    import { v4 as uuidv4 } from 'uuid';

    import { onDestroy, onMount, createEventDispatcher } from 'svelte';
    import { fly, fade } from 'svelte/transition';
    import EmblaCarousel from 'embla-carousel'
    import ENV from "config/env.js";

    import Phalcon from "modules/phalcon.js";
    import Router from "modules/router.js";

    import OrderInfo from "components/orders/OrdersDetail.html"
    import Direction from "components/applications/ApplicationsDetail.html"

    import Loader from "UI/Loader.html";

    export let modal;
    export let order = localStorage.getItem('order');
    export let orderID;
    export let pointsID;
    export let isModal;
    export let takeoutPoint;
    export let deliveryPoint;

    const dispatch = createEventDispatcher();

    let loading = false;
    let emblaEl;

    const getOrder = async () => {
        loading = true;

        let answer = await Phalcon.go.orders.get(orderID, {
            with: 'legal,applications.all,files',
        });
    
        if (answer.status === 200) {
            let formattedPayType = answer.pay_type.split('-');

            info.number = answer.number;
            info.files = answer.files;
            info.pay = {
                type: formattedPayType[0],
                prepay: formattedPayType.length > 1 ? formattedPayType[1] : null,
                surcharge_sum: info.surcharge_sum,
                surcharge_type: info.surcharge_type
            }
            
            if(answer.legal){
                info.legal = answer.legal
                info.legal_short_name = answer.legal.short_name
            }

            takeouts = [];

            answer.applications.map(applic => {
                applic.point.code = applic.point.code || ""

                if(applic.client && applic.client.legal) {
                    applic.legal = applic.client.legal
                }
                
                if(!applic.client){
                    applic.client = {
                        address: applic.point.address,
                        name: "",
                        phone: ""
                    }
                }
                
                if(applic.type == 'tk') {
                    applic.items = applic.tk_products.map(row => {
                        row.item.count = row.count
                        return row.item
                    })
                    takeouts = [...takeouts, applic]
                } else {
                    applic.items = applic.dl_products.map(row => {
                        row.item.count = row.count
                        return row.item
                    })
                    delivery = applic
                }
            })
        }

        loading = false;
    }

    const getPoints = async () => {
        loading = true;
        
        let answer = await Phalcon.go.points.get(pointsID, {
            with: 'takeouts',
        });
    
        if (answer.status === 200) {
            delivery.point = answer
            delivery.client.address = answer.address

            takeouts = answer.relation_takeouts.map((rt, idx) => {
                return {
                    id: idx + 1,
                    point: rt.takeout,
                    client: {
                        address: rt.takeout.address,
                        name: "",
                        phone: ""
                    },
                    date: "",
                    time_start: "",
                    time_end: "",
                    items: []
                }
            })
        }

        loading = false;
    }

    const intro = introJs();

    let requiredCallbacks = {};
    let info, takeouts, delivery;
    let embla, activeSheet = 0;
    let tkWrapper;

    const setEmptyData = () => {
        info = {
            number: "",
            pay: {},
            files: [],
        };

        takeouts = [{
            id: 1,
            point: {
                code: "",
                address: ""
            },
            client: {
                address: "",
                name: "",
                phone: ""
            },
            date: "",
            time_start: "",
            time_end: "",
            items: [],
            files: [],
        }];

        delivery = {
            point: {
                code: "",
                address: ""
            },
            client: {
                address: "",
                name: "",
                phone: ""
            },
            date: "",
            time_start: "",
            time_end: "",
            items: [],
            files: [],
        };
    }
    const addTakeout = () => {
        takeouts = [...takeouts, {
            id: takeouts.length + 1,
            point: {
                code: "",
                address: ""
            },
            client: {
                address: "",
                name: "",
                phone: ""
            },
            date: "",
            time_start: "",
            time_end: "",
            items: []
        }];
        
        setTimeout(() => {
            tkWrapper.scrollTop = tkWrapper.scrollHeight;
        }, 50);
    }

    const createOrder = async () => {
        // console.log('createOrder')
        for (var key in requiredCallbacks) {
            if (requiredCallbacks[key]) {
                if (!requiredCallbacks[key]()) {
                    setTimeout(() => {
                        embla.scrollTo(0);
                    });
                    
                    return;
                }
            }
        }
        
        if(orderID){
            info.id = orderID;
            info = Object.assign(info, info.pay);
            info.pay_type = info.pay.type;
            if(info.pay.prepay) info.pay_type += '-' + info.pay.prepay;
            info.surcharge_type = info.pay.surcharge_type;
            info.surcharge_sum = parseFloat(info.pay.surcharge_sum);

            if(info.legal && info.legal.id) {
                info.legal_id = info.legal.id
            }

            takeouts = takeouts.map(applic => {
                if(applic.legal) {
                    applic.client.legal = applic.legal

                    delete applic.client.legal.created_at
                    delete applic.client.legal.updated_at
                    delete applic.client.legal.deleted_at
                }

                applic.tk_products = [];
                applic.tk_products = applic.items.map(pr => {
                    let relation = {
                        count: parseInt(pr.count),
                    }

                    if(pr && pr.id) {
                        relation.products_id = pr.id
                    } else if(pr) {
                        relation.item = pr
                    }

                    relation.takeout_id = applic.id
                    relation.delivery_id = delivery.id

                    return relation;
                })

                if(applic.point){
                    if(applic.point.lat) applic.point.lat = applic.point.lat.toString()
                    if(applic.point.lon) applic.point.lon = applic.point.lon.toString()
                    applic.point.type = "tk";
                    applic.point.id = applic.points_id;
                }

                return applic
            })

            if(delivery.legal) {
                delivery.client.legal = delivery.legal

                delete delivery.client.legal.created_at
                delete delivery.client.legal.updated_at
                delete delivery.client.legal.deleted_at
            }

            if(delivery.point){
                if(delivery.point.lat) delivery.point.lat = delivery.point.lat.toString()
                if(delivery.point.lon) delivery.point.lon = delivery.point.lon.toString()
                delivery.point.type = "dl";
                delivery.point.id = delivery.points_id;
            }

            delivery.dl_products = [];

            info.applications = [delivery, ...takeouts];

            // console.log({info});

            // let request = {
            //     id: orderID,
            //     number: info.number,
            //     pay_type: info.pay.type + (info.pay.prepay ? '-' + info.pay.prepay : ''),
            //     surcharge_type: info.pay.surcharge_type,
            //     surcharge_sum: info.pay.surcharge_sum ? parseFloat(info.pay.surcharge_sum) : null,   
            // }

            // if(info.legal && info.legal.id) {
            //     request.legal_id = info.legal.id
            // } else if(info.legal) {
            //     request.legal = info.legal
            // }

            // request.applications = takeouts.map(applic => {
            //     let replic = {
            //         id: applic.id,
            //         number: applic.number,
            //         date: applic.date,
            //         time_start: applic.time_start,
            //         time_end: applic.time_end,
            //         tk_products: [],
            //         client: {}
            //     }

            //     if(applic.client && applic.client.id) {
            //         replic.client = {
            //             id: applic.client.id
            //         }
            //     }

            //     if(applic.client){
            //         replic.client.number = applic.client.number;
            //         replic.client.name = applic.client.name;
            //         replic.client.phone = applic.client.phone;
            //         replic.client.email = applic.client.email;
            //         replic.client.address = applic.client.address;
            //     }

            //     if(applic.legal) {
            //         replic.client.legal = applic.legal

            //         delete replic.client.legal.created_at
            //         delete replic.client.legal.updated_at
            //         delete replic.client.legal.deleted_at
            //     }

            //     replic.tk_products = applic.items.map(pr => {
            //         let relation = {
            //             count: parseInt(pr.count),
            //         }

            //         if(pr && pr.id) {
            //             relation.products_id = pr.id
            //         } else if(pr) {
            //             relation.item = pr
            //         }

            //         relation.takeout_id = applic.id
            //         relation.delivery_id = delivery.id

            //         return relation;
            //     })

            //     return replic
            // });

            // let replicDelivery = {
            //     id: delivery.id,
            //     number: delivery.number,
            //     date: delivery.date,
            //     time_start: delivery.time_start,
            //     time_end: delivery.time_end,
            //     tk_products: [],
            //     client: {}
            // }

            // if(delivery.client && delivery.client.id) {
            //     replicDelivery.client = {
            //         id: delivery.client.id
            //     }
            // }

            // if(delivery.client){
            //     replicDelivery.client.number = delivery.client.number;
            //     replicDelivery.client.name = delivery.client.name;
            //     replicDelivery.client.phone = delivery.client.phone;
            //     replicDelivery.client.email = delivery.client.email;
            //     replicDelivery.client.address = delivery.client.address;
            // }

            // if(delivery.legal) {
            //     replicDelivery.client.legal = delivery.legal

            //     delete replicDelivery.client.legal.created_at
            //     delete replicDelivery.client.legal.updated_at
            //     delete replicDelivery.client.legal.deleted_at
            // }

            // request.applications.push(replicDelivery);

            // console.log({info})
            // return
            let answer = await Phalcon.go.orders.save(info);

            if (answer.status == 200) {
                UIkit.notification({
                    message: 'Заказ ' + info.number + ' обновлен успешно!',
                    status: 'success',
                    pos: 'bottom-right'
                });

                if(info.filesFormData) {
                    let orderFilesAnswer = await Phalcon.go.orders.files(info.id, info.filesFormData)
                    if(orderFilesAnswer.status != 200){
                        UIkit.notification({
                            message: 'Файлы к заказу ' + info.uuid + ' не прикреплены!',
                            status: 'error',
                            pos: 'bottom-right'
                        });
                    }
                }

                for (var i = 0; i < takeouts.length; i++){
                    let tk = takeouts[i];
                    if(tk.filesFormData) {
                        let tkFilesAnswer = await Phalcon.go.applications.files(tk.id, tk.filesFormData)
                        if(tkFilesAnswer.status != 200){
                            UIkit.notification({
                                message: 'Файлы к заявке ' + tk.uuid + ' не прикреплены!',
                                status: 'error',
                                pos: 'bottom-right'
                            });
                        }
                    }
                }

                if(delivery.filesFormData) {
                    let dlFilesAnswer = await Phalcon.go.applications.files(delivery.id, delivery.filesFormData)
                    if(dlFilesAnswer.status != 200){
                        UIkit.notification({
                            message: 'Файлы к заявке ' + delivery.uuid + ' не прикреплены!',
                            status: 'error',
                            pos: 'bottom-right'
                        });
                    }
                }
                
                dispatch('save', answer);
            }
        } else {
            // console.log({ info, takeouts, delivery })
            // return

            info.uuid = uuidv4();

            takeouts = takeouts.map(tk => {
                tk.uuid = uuidv4()
                return tk
            })

            delivery.uuid = uuidv4();
                        
            let orderCreateAnswer = await Phalcon.orders.create({ info, takeouts, delivery })

            if (orderCreateAnswer.status == 200) {
                delete orderCreateAnswer.status;

                ym(ENV.YM_ID, 'reachGoal', 'new_order', {params: orderCreateAnswer});
                UIkit.notification({
                    message: 'Заказ ' + info.number + ' создан успешно!',
                    status: 'success',
                    pos: 'bottom-right'
                });

                if(orderID || pointsID) {
                    setEmptyData();
                    dispatch('save', orderCreateAnswer);
                } else {
                    // console.log('info.filesFormData', info.filesFormData)
                    
                    if(info.filesFormData) {
                        let orderFilesAnswer = await Phalcon.go.orders.files(orderCreateAnswer[0].orders_id, info.filesFormData)
                        if(orderFilesAnswer.status != 200){
                            UIkit.notification({
                                message: 'Файлы к заказу ' + info.number + ' не прикреплены!',
                                status: 'error',
                                pos: 'bottom-right'
                            });
                        }
                    }

                    for (var i = 0; i < takeouts.length; i++){
                        let tk = takeouts[i];
                        if(tk.filesFormData) {
                            let applic = orderCreateAnswer.find(a => a.uuid == tk.uuid)
                            let tkFilesAnswer = await Phalcon.go.applications.files(applic.id, tk.filesFormData)
                            if(tkFilesAnswer.status != 200){
                                UIkit.notification({
                                    message: 'Файлы к заявке ' + applic.uuid + ' не прикреплены!',
                                    status: 'error',
                                    pos: 'bottom-right'
                                });
                            }
                        }
                    }

                    if(delivery.filesFormData) {
                        let applic = orderCreateAnswer.find(a => a.uuid == delivery.uuid)
                        let dlFilesAnswer = await Phalcon.go.applications.files(applic.id, delivery.filesFormData)
                        if(dlFilesAnswer.status != 200){
                            UIkit.notification({
                                message: 'Файлы к заявке ' + applic.uuid + ' не прикреплены!',
                                status: 'error',
                                pos: 'bottom-right'
                            });
                        }
                    }

                    setEmptyData();
                    Router.goTo('orders-list')
                }
            }
        }

        
    };

    const removeTakeout = index => () => {
        if(takeouts.length > 1) {
            takeouts = takeouts.filter((tk, i) => i != index);
        } else {
            UIkit.notification({
                message: 'Точек забора не может быть меньше 1',
                status: 'danger',
                pos: 'bottom-right'
            });
        }
    }

    if(!isModal){
        window.nav.set({
            breadcrumb: [{
                name: 'Заказы',
                link: '/orders-list'
            }, {
                name: 'Новый заказ',
                link: '/orders/new'
            }],
            actions: [''],
            actionAddText: ''
        });
    }

    const showIntro = () => {
      intro.setOption("skipLabel", "Пропустить обучение");
      intro.setOption("nextLabel", "Дальше");
      intro.setOption("prevLabel", "Назад");
      intro.setOption("doneLabel", "Завершить");
      
      intro.setOptions({
        steps: [{
          element: document.getElementById('order-legal'),
          intro: 'Заказчик - это ваше Юр.лицо, выбирете его из списка'
        }, {
          element: document.getElementById('takeout-legal'),
          intro: 'Здесь вы можете ввести юр.лицо поставщика'
        }, {
          element: document.getElementById('takeout-address-code'),
          intro: 'Можно присвоить "Код адреса", который сохранится для дальнейшего быстрого ввода'
        }, {
          element: document.getElementById('takeout-address'),
          intro: 'Поле "Адрес" подставляется при вводе "Кода адреса" или "Контрагента", его можно изменить на нужный'
        }, {
          element: document.getElementById('takeout-add-link'),
          intro: 'Можно добавить еще склады - "Точки забора", если товары нужно забрать из нескольких точек и отвезти в одну'
        }, {
          element: document.getElementById('takeout-goto-products'),
          intro: 'Добавить товары можно в соответствующей вкладке, создав новый или выбрав из справочника'
        }, {
          element: document.getElementById('products-tab'),
          intro: 'Перейти во вкладку "Товары", чтобы добавить товары, которые нужно забрать'
        }]
      });

      intro.start();

      localStorage.setItem('new_order_intro', 'true');
    };

    const prev = () => {
        embla.scrollPrev();
    }

    onDestroy(() => {
        if(!orderID) localStorage.setItem('order', JSON.stringify({ info, takeouts, delivery }));
    });

    onMount(() => {
        let introStorage = localStorage.getItem('new_order_intro');
        if(!introStorage) setTimeout(() => showIntro(), 1000);
        if(!loading) initEmbla()
    })

    const initEmbla = () => {
        if(emblaEl){
            embla = EmblaCarousel(emblaEl, { 
                loop: false, 
                align: 'start', 
                draggable: false,
                startIndex: 0,
                containScroll: true
            });

            embla.on('select', index => {
                activeSheet = embla.selectedScrollSnap();
            })
        }
    }

    setEmptyData();

    if (orderID) {
        getOrder()
    } else if (pointsID) {
        getPoints()
    } else {
        if (order) 
            order = JSON.parse(order);

        if (order) {
            info = order.info;
            takeouts = order.takeouts.map(tk => {
                tk.date = null;
                
                return tk
            });

            order.delivery.date = null;
            delivery = order.delivery;
        }
    }

    $: {
        delivery.items = [];
        takeouts.map(tk => {
            tk.items.map(tkItem => {
                delivery.items.push(tkItem);
            })
        })
    }
    $: takeouts.forEach(takeout => {
        if(!takeout.number) takeout.number = info.number;
    });
    $: if(!delivery.number) delivery.number = info.number;
    $: if(!loading) setTimeout(() => initEmbla())
</script>

{#if loading}
    <Loader />
{:else}
    <div class="embla" bind:this={emblaEl}>
        <div class="embla__container">
            <div class="embla__slide">
                <div class="uk-margin-small-bottom uk-flex uk-flex-middle">
                    <div class="header__step uk-flex uk-flex-center uk-flex-middle" class:active="{activeSheet == 0}">
                        <span>1</span>
                    </div>
                    <div class="uk-text-lead uk-text-bold uk-margin-small-left" class:white="{orderID || pointsID}">Откуда забрать?</div>
                </div>
                <div>
                    <div class="uk-overflow-auto" id="tk-wrapper" bind:this={tkWrapper}>
                        {#each takeouts as takeout, i}
                            <div class="uk-margin-small-bottom" in:fly="{{ y: 200, duration: 800 }}">
                                <Direction 
                                    bind:items="{takeout.items}" 
                                    bind:data="{takeout}" 
                                    takeoutNumber={i} 
                                    bind:requiredCallbacks 
                                    bind:modal 
                                    on:remove="{removeTakeout(i)}" 
                                    tkCount={takeouts.length} 
                                    addressDisabled={pointsID} 
                                />
                            </div>
                        {/each}
                    </div>
                    {#if !pointsID}
                        <div id="takeout-add-link" class="green uk-text-center uk-width-large uk-card uk-card-body uk-padding-small uk-box-shadow-small" on:click="{addTakeout}" style="cursor: pointer; margin-top: 3px;">
                            Добавить точку забора
                        </div> 
                    {/if}
                </div>
            </div>
            <div class="embla__slide">
                <div class="uk-margin-small-bottom uk-flex uk-flex-middle">
                    {#if activeSheet == 1}
                        <span class="uk-icon-button uk-margin-small-right" uk-icon="arrow-left" on:click={prev}></span>
                    {/if}

                    <div class="header__step uk-flex uk-flex-center uk-flex-middle" class:active="{activeSheet == 1}">
                        <span>2</span>
                    </div>
                    <div class="uk-text-lead uk-text-bold uk-margin-small-left" class:white="{orderID || pointsID}">Куда доставить?</div>
                </div>
                <div>
                    <Direction 
                        isDelivery={true} 
                        items="{delivery.items}" 
                        bind:data="{delivery}" 
                        bind:requiredCallbacks 
                        bind:modal 
                        addressDisabled={pointsID} 
                    />
                </div>
            </div>
            <div class="embla__slide">
                <div class="uk-margin-small-bottom uk-flex uk-flex-middle">
                    {#if activeSheet == 2}
                        <span class="uk-icon-button uk-margin-small-right" uk-icon="arrow-left" on:click={prev}></span>
                    {/if}

                    <div class="header__step uk-flex uk-flex-center uk-flex-middle" class:active="{activeSheet == 2}">
                        <span>3</span>
                    </div>
                    <div class="uk-text-lead uk-text-bold uk-margin-small-left" class:white="{orderID || pointsID}">Информация о заказе</div>
                </div>
                <div>
                    <OrderInfo bind:data="{info}" bind:requiredCallbacks />
                </div>
                <div class="uk-text-center uk-width-large uk-card uk-card-body uk-padding-small uk-background-primary uk-light uk-margin-small-top" on:click="{createOrder}" style="cursor: pointer;">
                    Готово
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
    .header__step {
        font-size: 18px;
        width: 40px;
        height: 40px;
        border: 4px solid #c4c4c4;
        border-radius: 50%;
        color: #c4c4c4;
        font-weight: 500;
        background: white;
    }

    .green{
        color: #ffffff;
        background-color: #749e42;
    }

    .active {
        border: 4px solid #749e42;
        color: #749e42;
    }

    .uk-text-lead {
        color: #515151;
    }

    .box-opacity {
        opacity: 0.5;
    }

    .disabled {
        opacity: 0.5 !important;
        pointer-events: all !important;
    }

    .disabled .wrapper {
        pointer-events: none !important;
    }

    .uk-background-primary {
        background-color: #c70017;
        color: white;
    }

    .white {
        color: white !important;
    }

    #tk-wrapper {
        max-height: 72vh;
    }

    .embla {
        position: relative;
    }

.embla__viewport {
  width: 100%;
  overflow: hidden;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  will-change: transform;
  margin-left: -1rem;
}

.embla__slide {
  flex: 0 0 auto;
  position: relative;
  padding-left: 1rem;
  counter-increment: embla;
}

.embla__slide__inner {
  background-color: rgb(40, 44, 52);
  position: relative;
  border-radius: 0.5rem;
  min-height: 200px;
  padding-bottom: 46%;
  font-size: 5rem;
}

.embla__slide__inner:before {
  color: white;
  font-weight: 300;
  line-height: 1;
  content: counter(embla);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.embla__button {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.embla__button:not(:disabled) {
  cursor: pointer;
  fill: #1bcacd;
}

.embla__button:disabled {
  fill: #e9e9e9;
}

.embla__button__svg {
  width: 3.5rem;
  height: 3.5rem;
}

.embla__button--prev {
  left: 0.7rem;
}

.embla__button--next {
  right: 0.7rem;
}

.embla__dots {
  position: absolute;
  margin-top: 1rem;
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: center;
  left: 0;
  right: 0;
  top: 100%;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border: 0;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #efefef;
  width: 100%;
  height: 0.4rem;
  content: "";
}

.embla__dot.is-selected:after {
  background-color: #1bcacd;
  opacity: 1;
}

</style>