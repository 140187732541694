<script>
    import { onMount } from 'svelte'
    import Icon from '../text/Icon.svelte'

    export let id = 'dropdown-' + Math.random().toString(36).substring(7)
    export let left = false
    export let right = false
    
    let contentElement = null
    let wrapperElement = null

    onMount(() => {

        if (!left && !right) {
            let wrapperOffset = m4q(wrapperElement).offset();
            let parentOffset = m4q(wrapperElement).parent().offset();
            let offset = {
                top: wrapperOffset.top - parentOffset.top,
                left: wrapperOffset.left - parentOffset.left
            }

            console.log(offset.left, m4q(contentElement).children().width())

            if (offset.left < m4q(contentElement).children().width()) {
                right = true
            } else {
                left = true
            }
        }
    })
</script>

<div class="dropdown-wrapper" bind:this={wrapperElement}>
    <div id="{id}">
        <slot name="trigger">
            <Icon value="more-vert" pointer />
        </slot>
    </div>

    <div 
        class="content"
        class:left
        class:right
        data-role="dropdown"
        data-toggle-element="#{id}"
        bind:this={contentElement}
    >
        <slot>
            
        </slot>
    </div>
</div>

<style>
    .dropdown-wrapper {
        position: relative;
    }

    .content {
        position: absolute;
        z-index: 99999;
        background: white;
        border: 1px solid #dfdfdf;
        width: fit-content !important;
    }

    .content.left {
        top: 12px;
        right: 7px;
    }

    .content.right {
        top: 12px;
        left: 7px;
    }
</style>