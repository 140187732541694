<script>
    export let value = 'home'
    export let pointer = false
    export let color = null
</script>

<span class="mif-{value} {color ? `fg-${color}` : ''}" class:pointer on:click></span>

<style>
    .pointer {
        cursor: pointer;
    }
</style>