<script>
    import { createEventDispatcher } from 'svelte';
    import Address from "components/addresses/AddressesSelect.html";

    export let item;
    export let index;
    export let end;

    const dispatch = createEventDispatcher();

    const blur = () => {
        let tmp = document.createElement("input");

        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
    }

    const deleteEvent = () => {
        dispatch('delete');
    }
</script>

<div class="uk-flex uk-width-expand point-item">
    <div class="uk-width-auto uk-margin-auto-vertical">
        <div 
            data-point-id="{item.id}" 
            class="uk-margin-auto-vertical uk-margin-small-left uk-badge uk-badge-notification uk-panel-badge idx-icon">

            {#if end} 1 {/if}
            
        </div>
    </div>
    <div class="uk-width-expand uk-position-relative address-region">
        <div class="uk-margin-medium-right" style="margin-top:2px">
            <Address 
                isTextarea={false} 
                isMarginBottom={false}
                placeholder="{index === 0 ? 'Укажите точку отправки' : 'Укажите точку назначения'}"
                bind:text="{item.address}"
                on:change
            />
        </div>
        <!-- <i on:click="{blur}" class="point-search" uk-icon="icon: search; ratio: 1"></i> -->
    </div>
    {#if !end}
    <div style="background-color: rgba(255, 0, 0, 0.1)" class="uk-width-auto uk-tile-muted uk-flex uk-flex-middle uk-flex-center border-left">
        <i on:click="{deleteEvent}" style="color: red" class="uk-margin-small-left uk-margin-small-right" uk-icon="icon: close"></i>
    </div>
    {/if}
</div>

<style>
    .point-item {
        width: 90%;
        background: #ffffff;
        height: 35px;
        user-select: none;
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid #c4c4c4;
        margin: 0 0 8px 0;
        margin-bottom: 0;
    }

    .idx-icon {
        user-select: none;
        cursor: default;
    }

    .green {
        background-color: green !important;
    }

    i {
        cursor: pointer;
    }

    .address-region:hover .point-search {
        display: block;
    }

    .point-search {
        display: none;
        position:absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
    }

    .border-left {
        border-left: 1px solid #c4c4c4;
    }
</style>