<script>
    import { createEventDispatcher, onMount } from 'svelte';
    
    import Phalcon from 'modules/phalcon.js';

    import Button from 'UI/ButtonOld.html';

    const dispatch = createEventDispatcher();
    
    export let formData = new FormData();
    export let files = [];
    
    let fileInput;
    let imgTypes = ['jpg', 'jpeg', 'png'];

    function read() {
        let reader = new FileReader();

        if (fileInput.files && fileInput.files[0]) {
            
            let name = fileInput.files[0].name;
            let size = fileInput.files[0].size;
            let type = name.split('.').pop().toLowerCase();
            let isImage = imgTypes.includes(type);
            let file = fileInput.files[0];

            if(isImage){
                reader.onload = function (e) {
                    let image = e.target.result;
                    files = [...files, { name, size, type, image, file }];
                    formData.append("file"+files.length, file, name);
                    dispatch('change', {formData});
                };

                reader.readAsDataURL(fileInput.files[0]);    
            } else {
                files = [...files, { name, size, type, file }];
                formData.append("file"+files.length, file, name);
                dispatch('change', {formData});
            }

        }
    }

    const downloadFile = file => async () => {
        var x=new XMLHttpRequest();
            x.open("GET", file.url, true);
            x.responseType = 'blob';
            x.onload=function(e){download(x.response, file.name); }
            x.send();
    }

    onMount(() => {
		fileInput.onchange = read;
    });
</script>

<div class="wrapper">
    <div>Файлы</div>
    <div class="uk-flex collection">
        <input type="file" name="file" class="uk-hidden" bind:this={fileInput}/>
        <ul class="uk-list uk-margin-small-top">
            {#each files as file, i}
                <li on:click={downloadFile(file)}>
                    <div class="uk-grid-collapse uk-flex uk-flex-middle" uk-grid>
                        <div class="uk-width-auto">
                            {#if file.image}
                                <a href="#modal-media-{i}" uk-toggle>
                                    <img src="{file.image}" width="50" height="50">
                                </a>
                                <div id="modal-media-{i}" class="uk-flex-top" uk-modal>
                                    <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                                        <button class="uk-modal-close-outside" type="button" uk-close></button>
                                        <img src="{file.image}" alt="">
                                    </div>
                                </div>
                            {:else}
                                <span uk-icon="icon: file-text; ratio: 1.8"></span>
                            {/if}
                        </div>
                        <div class="uk-width-expand uk-text-small uk-margin-small-left">
                            {file.name}<br>
                            <span class="uk-text-muted">{file.size}</span>
                        </div>
                    </div>
                </li>
            {/each}
            <li class="uk-flex uk-flex-middle" on:click="{() => fileInput.click()}" style="cursor: pointer">
                <div class="item add">
                    +
                </div>
                <div class="uk-margin-small-left uk-text-small uk-text-muted">
                    Прикрепить файл
                </div>
            </li>
        </ul>
    </div>
</div>

<style>
    .collection .item.add {
        overflow: hidden;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0);
        border: 1px solid #c4c4c4;
        color: #c4c4c4;
        font-size: 30px;
        line-height: 0.85;
        margin-left: 2.5px;
        text-align: center;
    }
</style>