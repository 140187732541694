<script>
    import Phalcon from 'modules/phalcon.js';
    import { orderStatusList } from 'main/stores.js';
    import { createEventDispatcher, onMount } from 'svelte';

    const dispatch = createEventDispatcher();

    export let status;
    export let applications;
    export let order;
    export let disabled;

    let statusId;

    const setStatus = () => {
        if(order && order.id && statusId){
            disabled = true;
            Phalcon.orders.status.set({ orderId: order.id, statusId }).then(answer => {
                if(answer.status == 200) {
                    disabled = false;
                    status = answer.order_status;

                    applications = applications.map(application => {
                        answer.application_status.map(application_status => {
                            if(application.id == application_status.application_id){
                                application.status = application_status;
                            }
                        });
                        return application;
                    })

                    dispatch('applicationsStatusChange', applications);
                }
            });
        }
    }

    const update = () => {
        if(!status) status = {
            id: 16,
            color: '#515151',
            name: 'В обработке'
        };
        statusId = status.id;
    }

    $: status, update();
</script>

{#if status}
<div class="uk-container">
    <select bind:value={statusId} on:change={setStatus} on:click|stopPropagation {disabled} style="background:{status.color}33; color:{status.color};">
        {#each $orderStatusList as item}
            <option value={item.id}>{item.name}</option>
        {/each}
    </select>
</div>
{/if}

<style>
    .wrapper {
      position: relative;
      z-index: 1;
      width: calc(100% - 23px);
      border-radius: 6px;
      background: white;
    }

    .uk-container select {
      white-space: nowrap;
      border: none;
      border-radius: 6px;
      padding: 3px;
    }
    
    option {
        background: white !important;
        color: black;
    }
</style>