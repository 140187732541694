<script>
  import { onMount } from 'svelte'
  import DropdownSemantic from '../dropdown'

  export let placeholder = "Select..."
  export let search
  export let value

  let el
  let dropdown = true
  let selection = true

  const onChange = event => {
    value = event.target.value
  }

  onMount(() => {
    DropdownSemantic.init(el)
  })
</script>

<div 
  class="ui"
  class:search
  class:selection
  class:dropdown  
  bind:this={el}
>
  <input type="hidden" {value} on:change={onChange}>
  <i class="dropdown icon"></i>
  <div class="default text">{placeholder}</div>
  <div class="menu">
    <slot></slot>
  </div>
</div>

<style>
  .ui.selection.dropdown{
    min-height: auto !important;
  }
</style>
