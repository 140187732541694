<script>
  import { onMount, createEventDispatcher } from 'svelte';

  import Validator from 'modules/validator.js';
  import mask from 'modules/mask.js';

  import Input from 'UI/Input.html';
  
  const dispatch = createEventDispatcher();
  let input, danger;

  export let phone, value = phone, isRequired = false;

  export const validCallback = () => {
    return isRequired ? checkValid() : true;
  };
  
  function checkValid() {
    if (Validator.isValid.phone(phone) === -1){
      UIkit.notification({
        message: 'Поле "Телефон" некорректно',
        status: 'danger',
        pos: 'bottom-right',
        timeout: 5000
      });
      setDanger();
      return false;
    }
    return true;
  }
  function setDanger() {
    input.classList.add('uk-animation-shake', 'uk-text-danger');
    setTimeout(() => {
      input.classList.remove('uk-animation-shake');
    }, 300);

    danger = true;
    dispatch('danger');
  }
  const removeDanger = () => {
    if(input) input.classList.remove('uk-animation-shake', 'uk-text-danger');
  }
  onMount(() => {
    mask.phone(input);
    // imask = Inputmask({"mask": "(999) 999-9999"});
    // imask.mask(input);
  });

  

  function foo () {
    try {
      value = mask.masked(input, phone);
    } catch (e) {

    }
  }
  
  $: phone, foo();
  $: value, setTimeout(() => {
    phone = mask.val(input);
    dispatch('change', phone);
  }, 50);
</script>

<style>
  .wrapper{
    width: 100%;
  }

  input {
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    color: #000000;
    border-radius: 5px;
    font-size: 12px;
    height: 30px;
  }
</style>

<div class="wrapper">
  <Input {value} type="tel" placeholder="+7(XXX)XXX-XX-XX" on:input={removeDanger} on:change="{(event) => value = event.target.value}" bind:input/>
</div>