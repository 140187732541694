<script>
export let  data = {},
            columns = [],
            elem;
</script>

<style>
.wrapper {
  display: none;
}
</style>

<div class="wrapper" bind:this="{elem}">
  <ul>
    {#each columns as item}
      <li style="list-style: none; display: flex; justify-content: space-between"><span>{item.title}</span> <span>{data[item.prop]}</span></li>
    {/each}
  </ul>
</div>