<script>
    let cls = ''
    let captionEl = null

    export { cls as class }
    export let disabled = null
    export let checked = null
    export let material = null
    export let left = null
    export let clsWrapper = null
    export let clsSwitch = null
    export let clsCaption = null
    export let clsCheck = null
</script>

<wrapper class="switch-wrapper {clsWrapper}">
    <hidden>
        <data data-el="captionEl" bind:this={captionEl}>
            <slot>
        
            </slot>
        </data>
    </hidden>
    <input 
        class="{cls}"
        type="checkbox" 
        data-role="switch" 
        data-material={material}
        data-caption={captionEl ? captionEl.textContent : null}
        data-caption-position={left ? 'left' : null}
        data-cls-switch={clsSwitch}
        data-cls-caption={clsCaption}
        data-cls-check={clsCheck}
        bind:checked
        {disabled}
        on:click
        on:change
    />
</wrapper>

<style>
    hidden {
        display: none !important;
    }
</style>