<script>
    import { onMount, onDestroy } from "svelte";
    
    import Phalcon from 'modules/phalcon.js';

    import Loader from 'UI/Loader.html';

    export let items = [];
    export let modal;

    onMount(() => {
        window.nav.set({
            breadcrumb: [{
                name: 'Самозанятые',
                link: '/self-employees'
            }],
            actions: ['add'],
            actionAddText: 'Добавить своего сотрудника в базу'
        });
    },
        //window.nav.listener = window.nav.on('add', (event) => modal = {component: CreateClient}),
    );

    onDestroy(() => {
        if(window.nav.listener){
            window.nav.listener.cancel();
            window.nav.listener = null;
        }
    });

    let promise = Phalcon.request('self-employees', 'all', 'GET').then(answer => {
        items = answer;
    })
</script>

{#await promise}
	<!-- <Loader /> -->
{:then value}
    <div class="uk-width uk-flex uk-flex-wrap">
    {#each items as item}
        <div class="uk-card uk-card-default uk-width-small uk-margin-small-right uk-margin-small-bottom">
            <div class="uk-card-body uk-padding-small">
                <div class="uk-grid-small content" uk-grid>
                    <div class="uk-width-auto">
                        <img width="150" src={item.avatar}>
                    </div>
                    <div class="uk-width-expand">
                        <h3 class="uk-card-title uk-margin-remove-bottom">{item.middle_name} {item.first_name} {item.last_name}</h3>
                        <p class="uk-text-meta uk-margin-remove-top">Был онлайн 4 часа назад</p>
                        <p class="uk-margin-remove-bottom">Программист</p>
                        <a class="uk-text-primary uk-margin-remove-top">ИНН / Паспорт</a><br>
                        <a class="uk-link-muted uk-margin-small-top" href="#">{item.email}</a><br>
                        <a class="uk-link-muted" href="#">{item.phone}</a>
                    </div>
                </div>
            </div>
            <div class="uk-card-footer uk-padding-small">
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-auto">
                        <p class="uk-text-muted uk-margin-remove-bottom">Рейтинг</p>
                        <span class="uk-text-bold uk-text-danger">4.73</span>
                    </div>
                    <div class="uk-width-auto">
                        <p class="uk-text-muted uk-margin-remove-bottom">Отзывы</p>
                        <a class="uk-link uk-text-bold uk-text-danger">32</a>
                    </div>
                    <div class="uk-width-expand uk-text-right">
                        <p class="uk-text-muted uk-margin-remove-bottom">Заказов выполнено: <span>21</span></p>
                        <p class="uk-text-muted uk-margin-remove-top">Заказов провалено: <span>1</span></p>
                        <div class="uk-button-group">
                            <button class="uk-button uk-button-primary uk-button-small">Предложить работу</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/each}
    </div>
{/await}

<style>
    .uk-card-title {
        font-size: 1.3rem;
        line-height: 1.4;
    }

    .uk-card {
        width: 400px;
        max-height: 320px;
    }

    .content {
        height: 165px;
        overflow-y: hidden;
    }
</style>