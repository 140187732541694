<script>
export let
  name = 'Подтвердить',
  width = 'half',
  height = 'def',
  type = 'confirm';

</script>

<style>
.confirm{
  background: #28B446;
  height: 3rem;
  color: #ffffff;
  font-size: 1rem;
  border: none;
}

.passive {
  background: #c4c4c4;
  height: 3rem;
  color: #ffffff;
  font-size: 1rem;
  border: none;
}

.cancel {
  background: #e30613;
  height: 3rem;
  color: #ffffff;
  font-size: 1rem;
  border: none;
}
  .full{
    width: 100%;
  }
  .half{
    width: 50%;
  }

  button {
    text-transform: unset;
  }
</style>

<button
    on:click
    class="{type} {width} uk-button">
  {name}
</button>
