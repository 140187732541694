<script>
  import { createEventDispatcher } from 'svelte';

  import Phalcon from "modules/phalcon.js";

  import Modal from "UI/Modal.html";
  import Button from "UI/ButtonOld.html";
  import WindowButton from 'UI/ButtonWindowClose.html';
  import Area from 'UI/TextArea.html';

  export let modal;

  const dispatch = createEventDispatcher();

  let props = modal.props;
  let arrayIndex = modal.index;
  let requestData = '';
  let theme = '';

  function sendData(){
  }
  
  const close = () => dispatch("close");
</script>

<style>
   select {
      height: 30px;
      font-size: 14px;
      background: #FFFFFF;
      padding-left: 10px;
      box-sizing: border-box;
      border-radius: 5px;
      border-color: #e2e2e2;
   }
   input{
     height: 30px;
     font-size: 12px;
     background: #FFFFFF;
     padding-left: 10px;
     box-sizing: border-box;
     border-radius: 5px;
     border: 1px solid #e2e2e2;
   }
</style>

<div class="uk-section">
  <Modal >
    <div class="uk-flex uk-flex-between" slot="header">
      <div class="uk-text-lead">Создание вопроса</div>
      <WindowButton type="plus" on:click={close}></WindowButton>
    </div>

    <div slot="body" class="uk-padding-remove">
      <div class="uk-margin-small-bottom">
        <select name="name">
          {#each props as item, index}
            <option selected="{arrayIndex === index}">{item.name}</option>
          {/each}
        </select>
      </div>

      <div class="uk-margin-small-bottom">
        <input placeholder="Введите тему вопроса" type="text" bind:value="{theme}">
      </div>

      <div>
        <Area placeholder="Введите вопрос" bind:value="{requestData}" />
      </div>
    </div>

    <div slot="footer" class="uk-flex uk-flex-right">
      <Button name="Отправить" on:click={ sendData } status="success"/>
      <Button name="Отменить" on:click="{() => dispatch('close')}" status="cancel"/>
    </div>
  </Modal>
</div>