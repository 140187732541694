<script>
    import Radio from "UI/Radio.html";
    import Input from "UI/Input.html";

    export let data = {};
    export let inline = false;
    let props = {
        pay: [
            { name: 'pay', title: 'Наличные', prop: 'cash' },
            { name: 'pay', title: 'Оплата картой', prop: 'card' },
            { name: 'pay', title: 'Предоплата', prop: 'prepay' },
        ],
        prePay: [
            { name: 'prepay', title: 'Полностью', prop: 'full' },
            { name: 'prepay', title: 'Частично', prop: 'part' },
        ],
        surcharge: [
            { name: 'surcharge-type', title: 'Наличными', prop: 'cash' },
            { name: 'surcharge-type', title: 'Картой', prop: 'card' },
        ],
    };

    $: if(data && data.type && data.type !== 'prepay') {
        data['prepay'] = null;
        data['surcharge_sum'] = null;
        data['surcharge_type'] = null;
    }

    $: if(data && data.prepay && data.prepay !== 'part') {
        data['surcharge_sum'] = null;
        data['surcharge_type'] = null;
    }
</script>

<style>
    .prepay__margin{
        margin-left: 100px;
    }
</style>

<div>
    <Radio values={props.pay} bind:selected="{data.type}" />
</div>

{#if data.type === 'prepay' && !inline}
    <div class="uk-margin-large-left">
        <div class="uk-flex">
            <div class="uk-width-1-2">
                <Radio type="radio" values={props.prePay} bind:selected="{data.prepay}" />
            </div>
        </div>
        <div class="uk-width-1-2 prepay__margin">
            <!--        -->
            {#if data.prepay === 'part'}
            <div class="uk-width-1-1 ">
                <div class="uk-margin-left ">Сумма предоплаты:</div>
                <div class="uk-width-2-3 uk-flex uk-margin-large-left">
                    <Input bind:value={data.surcharge_sum} isDisabled={data.prepay===`full`} textPos="right" name="0" type="text" /><span>руб</span>
                </div>
            </div>
            {/if}
            <!--        -->
            {#if data.prepay === 'part'}
            <div>
                <div class="uk-width-1-1 uk-margin-left">Тип доплаты:</div>
                <div class="uk-width-1-1 uk-margin-large-left">
                    <Radio type="radio" values={props.surcharge} bind:selected="{data.surcharge_type}" />
                </div>
            </div>
            {/if}
            <!--        -->
        </div>
    </div>
{:else if data.type === 'prepay' && inline}
    <div class="uk-margin-large-left">
        <div class="uk-width-1-1 uk-flex">
            <div class="uk-width-1-2">
                <Radio type="radio" values={props.prePay} bind:selected="{data.prepay}" />
            </div>
            <!--        -->
            <div class="uk-width-expand uk-margin-small-top">
                {#if data.prepay === 'part'}
                <div class="uk-width-1-1 ">
                    <div >Сумма предоплаты:</div>
                    <div class="uk-width-2-3 uk-flex uk-margin-large-left">
                        <Input bind:value={data.surcharge_sum} isDisabled={data.prepay===`full`} textPos="right" name="0" type="text" /><span>руб</span>
                    </div>
                </div>
                {/if}
                <!--        -->
                {#if data.prepay === 'part'}
                <div>
                    <div class="uk-width-1-1 uk-margin-left">Тип доплаты:</div>
                    <div class="uk-width-1-1 uk-margin-large-left">
                        <Radio type="radio" values={props.surcharge} bind:selected="{data.surcharge_type}" />
                    </div>
                </div>
                {/if}
            </div>
            <!--        -->
        </div>
    </div>
{/if}