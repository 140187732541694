<script>
    import { createEventDispatcher } from 'svelte';
    
    import Validator from 'modules/validator.js';

    import Input from 'UI/Input.html';

    const dispatch = createEventDispatcher();
    let input, danger;

    export let email = '', isRequired = false, placeholder = "Почта";

    export const validCallback = () => {
        if (!isRequired && !email) return true;
        if (Validator.isValid.mail(email) == -1 || isRequired && email.length == 0) {
            UIkit.notification({
                message: `Неправильное поле "${placeholder}"`,
                status: 'danger',
                pos: 'bottom-right',
                timeout: 5000
            });
            setDanger();
            return false;
        }
        return true;
    };

    function setDanger() {
        input.classList.add('uk-animation-shake', 'uk-text-danger');
        setTimeout(() => {
            input.classList.remove('uk-animation-shake');
        }, 300);

        danger = true;
        dispatch('danger');
    }

    const removeDanger = () => {
        if (input) input.classList.remove('uk-animation-shake', 'uk-text-danger');
    }

</script>

<style>
    .wrapper {
        width: 100%;
    }

    input {
        padding-left: 10px;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid #c4c4c4;
        background-color: #ffffff;
        color: #000000;
        border-radius: 5px;
        font-size: 12px;
        height: 30px;
    }
</style>

<div class="wrapper">
    <Input on:input={removeDanger} bind:input type="email" placeholder={placeholder} bind:value="{email}" />
</div>