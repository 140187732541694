<script>
    import { onMount } from "svelte";

    import Phalcon from "modules/phalcon.js";
    
    import ProductsSelect from "components/products/ProductsSelect.html"
    import CreateProductModal from "components/products/ProductsCreateModal.html"

    import InputNumber from 'UI/InputNumber.html';
    import Input from "UI/Input.html"
    import Add from "UI/ButtonAdd.html"
    import Button from "UI/ButtonWindowClose.html"
    import Icon from "UI/ButtonIcon.html"


    export let items;
    export let isDelivery;
    export let modal;
    
    let selectItems = [];
    let select = {
        displayProps: [{
            key: "name",
            className: "",
            isLineBreak: true
        },{
            key: "brandName",
            className: "uk-text-bold"
        },{
            key: "number",
            className: "uk-text-muted"
        },{
            key: "test.multi",
            className: "uk-text-muted"
        }]
    };

    let amount = 0;
    let size = 0;
    let weight = 0;
    let count = 0;

    $: {
        amount = 0;
        size = 0;
        weight = 0;
        count = 0;

        items.map(item => {
            if(item.price) amount += parseFloat(item.price) * item.count;
            if(item.weight) weight += parseFloat(item.weight)  * item.count;
            if(item.height && item.width && item.depth) size += (parseFloat(item.height)/100 * parseFloat(item.width)/100 * parseFloat(item.depth)/100)  * item.count;
            if(item.count) count += parseInt(item.count);
        });

        amount = Math.round(amount * 100)/100;
        size = Math.round(size * 100)/100;
        weight = Math.round(weight * 100)/100;
        count = Math.round(count * 100)/100;
    }

    function unMarkSelected(id) {
        items = items.filter(item => item.id != id);
    }

    function totalPrice(event,index) {
        event.preventDefault();
        if (event.target.value.length === 0 || event.target.value === 0){
            items[index].count = 1;
            event.target.value = 1;
            return;
        }
        items[index].count = event.target.value;
        items[index].total = parseInt(items[index].count) * parseInt(items[index].price);
    }

    const changeItemCount = (id, e) => {
        items = items.map(item => {
            if(item.id === id) {
                if(e.target.value > 0) {
                    item.count = e.target.value;
                    item.total = parseInt(item.count) * parseInt(item.price);
                } else {
                    item.total = item.count = 0;
                }
            }

            return item;
        })
    }

    const addItem = (event) => {
        let product = event.detail;
        product.count = 1;
        product.id = parseInt(product.id);

        let isAdded = items.some(item => item.id === product.id);
        
        if(!isAdded) items = [...items, product];
    }

    function successProduct(event) {
        event.detail.count = 1;
        addItem(event);
    }

    function safariInput(event){
        let temp = event.target.value.replace(/^(0)?(\D)/g, '');
        return temp ? parseInt(temp) : '';
    }

    $: if (modal.isReady && modal.self) modal.self.$on('success', event => successProduct(event));
</script>

<style>

    .text__muted{
        font-size: 16px;
        color: #c4c4c4;
    }

    .padding__min--right{
        padding: 0 6px 0 0;
    }
    .padding__min--left{
        padding: 0 0 0 6px;
    }
    input{
        font-family: Roboto;
        font-size: 14px;
        padding-right: 6px;
        height: 21px;
        width: 35px;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
</style>

<div class="wrapper">

    <div class="uk-margin-small-bottom">
        {#if !isDelivery}
            <div class="uk-text-center">Номенклатура</div>
            <ProductsSelect on:selected="{addItem}" on:create="{(event) => modal = {component: CreateProductModal}}"  />
        {/if}
        <div>
        
        {#if !isDelivery}
            {#each items as item (item.id)}
                <div class="uk-flex">
                    <div class="uk-flex uk-width-expand">
                        <div class="text__muted uk-margin-small-right uk-width-1-6">{item.article || ''}</div>
                        <div class="uk-width-3-6">{item.name}</div>
                        <div class="uk-width-2-6">
                            <div class="uk-flex uk-flex-right">
                                <div class="uk-width-3-4 uk-text-right">
                                    <InputNumber
                                            bind:value="{item.count}"
                                            textRight={true} />
                                </div>
                                <div class="uk-margin-small-left uk-width-1-4 uk-text-left">шт</div>
                            </div>
                            <div class="uk-flex uk-flex-right">
                                <div class="uk-width-3-4 uk-text-right padding__min--right">{item.price * item.count}</div>
                                <div class="uk-margin-small-left uk-width-1-4 uk-text-left">руб</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {#if !isDelivery}
                            <Icon type="trash" on:click="{() => unMarkSelected(item.id)}" />
                        {/if}
                    </div>
                </div>
            {:else}
                <div class="uk-width uk-text-muted uk-text-center">Груз не прикреплен</div>
            {/each}
        {:else}
            {#each items as item (item.id)}
                <div class="uk-flex">
                    <div class="uk-flex uk-width-expand">
                        <div class="text__muted uk-margin-small-right uk-width-1-6">{item.article}</div>
                        <div class="uk-width-3-6">{item.name}</div>
                        <div class="uk-width-2-6">
                            <div class="uk-flex uk-flex-right">
                                <div class="uk-width-3-4 uk-text-right">
                                    <input disabled class=" uk-text-right" type="text" bind:value="{item.count}">
                                </div>
                                <div class="uk-margin-small-left uk-width-1-4 uk-text-left">шт</div>
                            </div>
                            <div class="uk-flex uk-flex-right">
                                <div class="uk-width-3-4 uk-text-right padding__min--right">{item.price * item.count}</div>
                                <div class="uk-margin-small-left uk-width-1-4 uk-text-left">руб</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {#if !isDelivery}
                            <Icon type="trash" on:click="{() => unMarkSelected(item.id)}" />
                        {/if}
                    </div>
                </div>
            {:else}
                <div class="uk-width uk-text-muted uk-text-center">Груз не прикреплен</div>
            {/each}
        {/if}
        </div>
    </div>
    <hr>
    <div uk-grid>

        <div class="uk-text-right uk-flex-row uk-width-1-1 uk-padding-small">
            <div class="uk-width uk-text-right">Общий вес: {weight} кг</div>
            <div class="uk-width uk-text-right uk-margin-small-top">Общий объем: {size} м3</div>
            <div class="uk-width uk-text-right uk-margin-small-top">Общая сумма: {amount} руб</div>
        </div>
    </div>
<!--    <hr>-->

</div>



<!--prev worked block-->
<!--<div class="uk-flex">-->
<!--    <div class="uk-flex uk-width-1-1">-->
<!--        <div class="uk-text-meta uk-width-1-5">{item.number}</div>-->
<!--        <div class="uk-width-3-5">{item.name}</div>-->
<!--        <div class="uk-width-1-5">-->
<!--            <div class="uk-flex">-->
<!--                <div>-->
<!--                    {#if step === "3"}-->
<!--                    <input disabled class="uk-input uk-text-right padding__min&#45;&#45;right" type="text" value="{item.count}">-->
<!--                    {:else}-->
<!--                    <input  class="uk-input uk-text-right padding__min&#45;&#45;right" type="text" on:input="{()=> totalPrice(index)}" bind:value="{item.count}">-->
<!--                    {/if}-->
<!--                </div>-->
<!--                <div>шт</div>-->
<!--            </div>-->
<!--            <div class="uk-flex uk-flex-right">-->
<!--                <div class="uk-text-right padding__min&#45;&#45;right">214321321312</div>-->
<!--                <div>руб</div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="uk-width-auto "><Button on:plus="{() => unMarkSelected(item.id)}" type="plus"/></div>-->
<!--    </div>-->
<!--</div>-->