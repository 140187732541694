<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    export let status,
    isDisabled = false;

    const buttonEvent = () => dispatch(status);
</script>

<style lang="scss">.invisible {
  display: none; }

.add {
  overflow: hidden;
  display: block;
  width: 30px;
  height: 30px;
  /*box-sizing: border-box;*/
  border-radius: 2px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  font-size: 30px;
  line-height: 0.85; }
  .add:hover {
    text-decoration: none; }
  .add:disabled {
    display: none; }

.delete {
  overflow: hidden;
  display: block;
  width: 30px;
  height: 30px;
  /*box-sizing: border-box;*/
  border-radius: 2px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #e30613;
  color: #e30613;
  font-size: 30px;
  line-height: 0.75; }
  .delete:hover {
    text-decoration: none; }
  .delete:disabled {
    display: none; }
</style>

<div class:invisible="{isDisabled}" class="wrapper ">
    {#if status === "add"}
    <a class:invisible="{isDisabled}" class="uk-text-center {status}" on:click="{buttonEvent}">+</a>
    {:else}
    <a class:invisible="{isDisabled}" class="uk-text-center {status}" on:click="{buttonEvent}">-</a>
    {/if}
</div>