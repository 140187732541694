<script>
    import { Dropdown, Panel, CollapseContent, CollapseToggle, Checkbox } from "svelte-metro-ui"
    import { statusesMapStore, statusesStore, archiveAllServices, setServiceStatus } from '../../stores/DeliveryPlannerStore'
    import { routesMapStore } from '../../stores/RoutesStore'
    import { usersStore, usersMapStore } from '../../stores/UsersStore'
    import { addEditableService } from './PageStore'
    import { OrdersListItemInfo } from '.'
    import { fitBounds } from 'components/map/GoogleMap.html'
    import { userRoutes } from "./UserRoutesStore"
    import { serviceChangeUser } from "./ServicesStore"
    import { DeliveryUserSelect } from "../users"

    export let service = null
    export let selected = []
    let showServices = false
    let prevDeliveryUserId = service ? service.delivery_user_id : null
    let userChangeLoading = false

    const handleSetMapCenter = service => () => fitBounds([service, service])
    const handleAddEditableService = s => () => addEditableService(s)
    const handleUserChange = async () => {
        let delivery_user_id = service.delivery_user_id
        userChangeLoading = true

        await serviceChangeUser(
            service, 
            delivery_user_id,
            prevDeliveryUserId,
            $userRoutes[delivery_user_id],
            $userRoutes[prevDeliveryUserId]
        )

        userChangeLoading = false
        prevDeliveryUserId = delivery_user_id
    }
    const handleArchiveService = service => async () => {
        service.loading = true
        const answer = await archiveAllServices([...(service.pickups ? service.pickups.map(p => p.id) : []), service.id].join(','))
        service.loading = false
    }
    const handleStatusChange = service => async () => {
        service.statusLoading = true
        const answer = await setServiceStatus(service.id, service.status_id)
        service.statusLoading = false
    }
    const handleFitBounds = () => {
        let services = []
        
        if (service.pickups) service.pickups.forEach(p => services.push(p))
        services.push(service)

        fitBounds(services)
    }
    const handleShowServices = () => setTimeout(() => {
        showServices = !showServices
    }, 50)
</script>

{#if service}
    <Panel class="mb-2 pos-relative delivery-planner-service-item">
        <div class="uk-width uk-flex uk-flex-between pl-1 pr-1">
            {#if service.statusLoading}
                <div class="uk-margin-small-left" uk-spinner="ratio: 0.5;"></div>
            {:else}
                <div uk-form-custom="target: null">
                    <select bind:value={service.status_id} on:change={handleStatusChange(service)}>
                        {#each $statusesStore as status (status.id)}
                            <option style="color: {status.color};" value={status.id}>{status.name}</option>
                        {/each}
                    </select>
                    <span class="uk-flex uk-flex-middle">
                        <span style="color: {$statusesMapStore.hasOwnProperty(service.status_id) ? $statusesMapStore[service.status_id].color : ''}; font-size:14px;">
                            {$statusesMapStore.hasOwnProperty(service.status_id) && $statusesMapStore[service.status_id] ? $statusesMapStore[service.status_id].name : 'В ожидании'}
                        </span>
                    </span>
                </div>
            {/if}
            
            {#if userChangeLoading}
                <div class="uk-margin-small-right" uk-spinner="ratio: 0.5;"></div>
            {:else}
                <DeliveryUserSelect
                    on:change={handleUserChange}
                    bind:value={service.delivery_user_id}
                    users={$usersStore}
                    selectedUser={$usersMapStore.hasOwnProperty(service.delivery_user_id) ? $usersMapStore[service.delivery_user_id] : null}
                />
                <!-- <div uk-form-custom="target: null" on:change={handleUserChange(service)}>
                    <select bind:value={service.delivery_user_id}>
                        <option value={null}>Нет курьера</option>
                        {#each $usersStore as user (user.id)}
                            <option value={user.id}>{user.last_name} {user.first_name}</option>
                        {/each}
                    </select>
                    <span class="uk-flex uk-flex-middle">
                        <span style="font-size:14px;">
                            {$usersMapStore.hasOwnProperty(service.delivery_user_id) && $usersMapStore[service.delivery_user_id] ? `${$usersMapStore[service.delivery_user_id].last_name} ${$usersMapStore[service.delivery_user_id].first_name}` : 'Нет курьера'}
                        </span>
                    </span>
                </div> -->
            {/if}
        </div>
        <div class="uk-width uk-flex uk-flex-between uk-flex-middle">
            <div class="uk-flex uk-flex-middle">
                <CollapseToggle key="service-{service.id}" on:click={handleShowServices} />
                <Checkbox 
                    caption="<b>Заказ</b> <i>{service.order_number ? service.order_number : service.number}</i>" 
                    indeterminateValue={[...(service.pickups ? service.pickups.map(p => p.id) : []), service.id]}
                    bind:selected={selected} 
                />
            </div>
            <div class="uk-flex">
                <Dropdown left>
                    <ul class="d-menu context ml-0" style="display: block; position:relative !important; right: 0 !important;">
                        <li>
                            <a on:click={handleAddEditableService(service)}><span class="mif-eye icon"></span> Детали заказа</a>
                        </li>
                        <li>
                            <a on:click={handleArchiveService(service)}><span class="mif-bin icon"></span> Удалить заказ</a>
                        </li>
                        <li class="divider"></li>
                        <li>
                            <a on:click={handleFitBounds}>Показать заказ на карте</a>
                        </li>
                        {#if service.pickups}
                            {#each service.pickups as pickup}
                                <li>
                                    <a on:click={handleSetMapCenter(pickup)}><b style="color:darkgreen">Забор</b> <i>{pickup.number}</i> на карте</a>
                                </li>
                            {/each}
                        {/if}
                        <li>
                            <a on:click={handleSetMapCenter(service)}><b style="color:darkorange">Доставка</b> <i>{service.number}</i> на карте</a>
                        </li>
                    </ul>
                </Dropdown>
            </div>
        </div>
        <div class="uk-width">
            {#if service.routes_id && $routesMapStore[service.routes_id]}
                <div class="uk-flex">
                    <div class="color-circle" style="background-color: {$routesMapStore[service.routes_id].color}"></div>
                    <span style="font-size:11px;">
                        Машрут {$routesMapStore[service.routes_id].uid || $routesMapStore[service.routes_id].id}
                    </span>
                </div>
            {/if}
        </div>
        <div class="uk-width pl-4">
            <CollapseContent toggleKey="service-{service.id}">
                {#if service.pickups}
                    {#each service.pickups as pickup}
                        <Checkbox caption="<b style='color:darkgreen;'>Забор</b> <i>{pickup.number}</i>" value={pickup.id} /> 
                        <Panel class="mb-2">
                            <OrdersListItemInfo service={pickup} />
                        </Panel>
                    {/each}
                {/if}
                <Checkbox caption="<b style='color:darkorange;'>Доставка</b> <i>{service.number}</i>" value={service.id} /> 
                <Panel>
                    <OrdersListItemInfo {service} />
                </Panel>
            </CollapseContent>
        </div>
    </Panel>
{/if}

<style>
    .color-circle {
        width: 7px;
        height: 7px; 
        border-radius: 100%;
        margin-right: 7px;
        cursor: pointer;
    }
</style>