<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let type = "rollup";

    $: icon = type === "rollup" ? "minus" : "plus";

</script>

<style lang="scss">.wrapper {
  box-sizing: border-box;
  width: 19px;
  height: 19px; }

button {
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  padding: 0; }

span {
  color: #ffffff;
  font-weight: bold; }

.plus {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url("/svg/close.svg");
  border-radius: 50%; }
  .plus span {
    transform: rotate(45deg); }
  .plus:hover {
    cursor: pointer; }

.rollup {
  background-color: #f2c94c;
  border: 1px solid #f2c94c;
  border-radius: 50%; }
</style>

<div class="wrapper">
    <div
        class="{type}"
        on:click>
    </div>
</div>

