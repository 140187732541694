<script>
  import { createEventDispatcher } from "svelte"
  import {
    Slider,
    ListItem,
    Icon,
    Image,
    View,
    Upload,
    Grid,
  } from "svelte-uikit-ui"
  import { Button, Form, Group, Input } from "svelte-metro-ui"

  export let formData = {
    files: [],
  }
  let dkpFiles = []
  let imageFiles = []
  let stsFiles = []
  let osagoFiles = []
  let dutyFiles = []
  let contractFiles = []
  let otherFiles = []

  const dispatch = createEventDispatcher()

  const handleChangeFileOrder = (index, value) => () => {
    let files = formData.files
    files = files.filter((v, i) => i != index)
    files.splice(index + value, 0, formData.files[index])
    formData.files = files
  }

  const handleRemoveImage = (src) => () => {
    formData.files = formData.files.filter((f) => f.url != src)
  }

  const handleUploadComplete = ({ detail, tag = null }) => {
    let response = detail.response
    response = JSON.parse(response)
    if (response) {
      Object.values(response).forEach((file) => {
        formData.files = [
          ...formData.files,
          {
            url: file.thumb,
            tag,
          },
        ]
      })
    }
  }

  const handleUploadDkpComplete = ({ detail }) =>
    handleUploadComplete({ detail, tag: "dkp" })

  const handleUploadStsComplete = ({ detail }) =>
    handleUploadComplete({ detail, tag: "sts" })

  const handleUploadOsagoComplete = ({ detail }) =>
    handleUploadComplete({ detail, tag: "osago" })

  const handleUploadDutyComplete = ({ detail }) =>
    handleUploadComplete({ detail, tag: "duty" })

  const handleUploadContractComplete = ({ detail }) =>
    handleUploadComplete({ detail, tag: "contract" })

  const handleUploadOtherComplete = ({ detail }) =>
    handleUploadComplete({ detail, tag: "other" })

  $: dkpFiles = formData.files.filter((f) => f.tag == "dkp")
  $: stsFiles = formData.files.filter((f) => f.tag == "sts")
  $: osagoFiles = formData.files.filter((f) => f.tag == "osago")
  $: dutyFiles = formData.files.filter((f) => f.tag == "duty")
  $: contractFiles = formData.files.filter((f) => f.tag == "contract")
  $: otherFiles = formData.files.filter((f) => f.tag == "other")
  $: imageFiles = formData.files.filter((f) => !f.tag)
</script>

<Grid class="uk-child-width-1-2">
  <View>
    <Group label="Номер">
      <Input bind:value={formData.number} />
    </Group>
    <Group label="Марка">
      <Input bind:value={formData.mark} required />
    </Group>
    <Group label="Модель">
      <Input bind:value={formData.model} required />
    </Group>
    <Group label="VIN">
      <Input bind:value={formData.win} required />
    </Group>
    <Group label="СТС">
      <Input bind:value={formData.sts} required />
    </Group>
    
    <Group label="Собственник">
      <Input bind:value={formData.owner} />
    </Group>

    <Group label="Описание">
      <textarea
        class="uk-textarea uk-width"
        rows="5"
        bind:value={formData.description}
        required
      />
    </Group>
  </View>

  <View>
    <Group label="Цвет">
      <Input bind:value={formData.color} />
    </Group>

    <Group label="Год выпуска">
      <Input bind:value={formData.year} />
    </Group>

    <Group label="Тип ТС">
      <Input bind:value={formData.type} />
    </Group>

    <Group label="Кузов">
      <Input bind:value={formData.bodywork} />
    </Group>

    <Group label="Двигатель">
      <Input bind:value={formData.engine} />
    </Group>

    <Group label="КПП">
      <Input bind:value={formData.kpp} />
    </Group>

    <Group label="Привод">
      <Input bind:value={formData.drive} />
    </Group>

    <Group label="Салон">
      <Input bind:value={formData.salon} />
    </Group>
  </View>

  <View>
    <p>ДКП на вход</p>
    <Upload
      on:complete={handleUploadDkpComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />
    {#if dkpFiles.length}
      <Slider class="uk-child-width-1-2 uk-height-small">
        {#each dkpFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden uk-position-relative">
            <Image src={file.url} cover />
            <div
              class="uk-background-default uk-padding-small"
              style="position: absolute; bottom: 0; left: 0px;"
            >
              <a href={file.url} target="_blank">Файл {i}</a>
            </div>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>
  <View>
    <p>СТС</p>
    <Upload
      on:complete={handleUploadStsComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />
    {#if stsFiles.length}
      <Slider class="uk-child-width-1-2 uk-height-small">
        {#each stsFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden uk-position-relative">
            <Image src={file.url} cover />
            <div
              class="uk-background-default uk-padding-small"
              style="position: absolute; bottom: 0; left: 0px;"
            >
              <a href={file.url} target="_blank">Файл {i}</a>
            </div>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>
  <View>
    <p>Полис ОСАГО + платежное поручение</p>
    <Upload
      on:complete={handleUploadOsagoComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />
    {#if osagoFiles.length}
      <Slider class="uk-child-width-1-2 uk-height-small">
        {#each osagoFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden uk-position-relative">
            <Image src={file.url} cover />
            <div
              class="uk-background-default uk-padding-small"
              style="position: absolute; bottom: 0; left: 0px;"
            >
              <a href={file.url} target="_blank">Файл {i}</a>
            </div>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>
  <View>
    <p>Госпошлина на постановку на учет</p>
    <Upload
      on:complete={handleUploadDutyComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />
    {#if dutyFiles.length}
      <Slider class="uk-child-width-1-2 uk-height-small">
        {#each dutyFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden uk-position-relative">
            <Image src={file.url} cover />
            <div
              class="uk-background-default uk-padding-small"
              style="position: absolute; bottom: 0; left: 0px;"
            >
              <a href={file.url} target="_blank">Файл {i}</a>
            </div>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>
  <View>
    <p>Договор аренды/выкупа</p>
    <Upload
      on:complete={handleUploadContractComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />
    {#if contractFiles.length}
      <Slider class="uk-child-width-1-2 uk-height-small">
        {#each contractFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden uk-position-relative">
            <Image src={file.url} cover />
            <div
              class="uk-background-default uk-padding-small"
              style="position: absolute; bottom: 0; left: 0px;"
            >
              <a href={file.url} target="_blank">Файл {i}</a>
            </div>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>
  <View>
    <p>Прочие файлы</p>
    <Upload
      on:complete={handleUploadOtherComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />
    {#if otherFiles.length}
      <Slider class="uk-child-width-1-2 uk-height-small">
        {#each otherFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden uk-position-relative">
            <Image src={file.url} cover />
            <div
              class="uk-background-default uk-padding-small"
              style="position: absolute; bottom: 0; left: 0px;"
            >
              <a href={file.url} target="_blank">Файл {i}</a>
            </div>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>

  <View class="uk-width">
    <Upload
      on:complete={handleUploadComplete}
      url="/api/v1/files/upload"
      dropareaText="Перетащите изображения сюда или"
      selectText="выберите по клику"
      droparea
      multiple
    />

    {#if imageFiles.length}
      <Slider class="uk-child-width-1-3 uk-height-small">
        {#each imageFiles as file, i (file.url)}
          <ListItem class="uk-overflow-hidden">
            <Image src={file.url} cover />
            <View positionSmall="bottom-left" middle light>
              <div
                class="uk-flex uk-flex-center uk-flex-middle uk-dark arrow-cover"
              >
                <Icon on:click={handleChangeFileOrder(i, -1)}>arrow-left</Icon>
              </div>
              <div
                class="uk-flex uk-flex-center uk-flex-middle uk-dark arrow-cover"
              >
                <Icon on:click={handleChangeFileOrder(i, 1)}>arrow-right</Icon>
              </div>
            </View>
            <View
              class="uk-margin-small-top uk-text-right"
              positionSmall="bottom-right"
            >
              <Icon on:click={handleRemoveImage(file.url)} button>close</Icon>
            </View>
          </ListItem>
        {/each}
      </Slider>
    {/if}
  </View>
</Grid>

<style>
  .arrow-cover {
    background-color: rgba(255, 255, 255, 0.35);
    cursor: pointer;
    width: 25px;
    height: 25px;
    color: black !important;
  }
</style>
