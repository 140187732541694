<script>
    import { createEventDispatcher, onMount } from 'svelte'
    import { Panel, Checkbox, Icon, Button, View, Dropdown, Radio, infoToast, warningToast, alertToast, successToast } from 'svelte-metro-ui'
    import { HsvPicker } from 'svelte-color-picker'
    import Phalcon from '../../../modules/phalcon'

    export let userStore = null
    export let user = userStore ? $userStore : null
    export let watchUserId = null

    const dispatch = createEventDispatcher()
    const localStorageColorKey = `MultiList.UsersList.user${user.id}_color`
    let showOnMap = user.showOnMap
    let showTrackOnMap = user.showTrackOnMap
    let pickColorDropdownEl = null
    let pickColorPanel = false

    if (userStore && $userStore) {
        if (!$userStore.color) $userStore.color = localStorage.getItem(localStorageColorKey) || `#${intToRGB(hashCode(user.last_name+user.first_name))}`
    } else {
        if (!user.color) user.color = localStorage.getItem(localStorageColorKey) || `#${intToRGB(hashCode(user.last_name+user.first_name))}`
    }

    function hashCode(str) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    } 

    function intToRGB(i){
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    const handleShowTrackOnMapClick = async () => {
        showTrackOnMap = !showTrackOnMap

        if (showTrackOnMap) {
            showOnMap = showTrackOnMap

            const answer = await Phalcon.api.users.getTrack(user.id)

            if (answer.status == 200) {
                if (!answer.payload.length) {
                    warningToast(`Нет данных по треку курьера "${user.last_name} ${user.first_name}" за последние 24 часа`)
                } else {
                    successToast(`Данные по треку курьера "${user.last_name} ${user.first_name}" получены`)
                }
                if (userStore && $userStore) {
                    $userStore.showOnMap = showOnMap
                    $userStore.track = answer.payload
                } else {
                    user.showOnMap = showOnMap
                    user.track = answer.payload
                }
            } else {
                alertToast(`Произошла ошибка: ${answer.message}`)
            }
        }

        if (userStore && $userStore) {
            $userStore.showTrackOnMap = showTrackOnMap
        } else {
            user.showTrackOnMap = showTrackOnMap
        }

        dispatch('showOnMap', {showOnMap, user, userStore})
    }

    const handleShowOnMapClick = () => {
        showOnMap = !showOnMap

        if (userStore && $userStore) {
            $userStore.showOnMap = showOnMap
        } else {
            user.showOnMap = showOnMap
        }

        dispatch('showOnMap', {showOnMap, user, userStore})
    }

    const handleChangeColor = e => {
        user.color = rgbToHex(e.detail.r, e.detail.g, e.detail.b)

        if (userStore && $userStore) {
            $userStore.color = user.color
            localStorage.setItem(localStorageColorKey, user.color)
        }
    }

    const handleWatchUser = () => {
        if (watchUserId == user.id) {
            watchUserId = null
            infoToast(`Курьер "${user.last_name} ${user.first_name}" больше не отслеживается`)
        } else {
            watchUserId = user.id
            infoToast(`Идет отслеживание курьера "${user.last_name} ${user.first_name}"`)
        }
    }

    onMount(() => {
        UIkit.util.on(pickColorDropdownEl, 'show', function () {
            pickColorPanel = true
        })
        UIkit.util.on(pickColorDropdownEl, 'hide', function () {
            pickColorPanel = false
        })
    })

    $: if (userStore && $userStore) {
        user = $userStore
    }
</script>

<Panel class="mb-2">
    <View class="d-flex flex-align-center flex-justify-between w-100">
        <div class="d-flex flex-align-center">
            <div class="uk-inline">
                <div class="color-circle" style="background-color: {user.color}"></div>
                <div uk-dropdown="mode: click" bind:this={pickColorDropdownEl}>
                    {#if pickColorPanel}
                        <HsvPicker on:colorChange={handleChangeColor} startColor={user.color}/>
                    {/if}
                </div>
            </div>
            <Checkbox caption={`${user.last_name} ${user.first_name}`} value={user.id} />
        </div>

        <div class="d-flex flex-align-center">
            <Button class="{user.color && showTrackOnMap ? `bg-blue fg-white` : ''}" on:click={handleShowTrackOnMapClick}>
                <Icon value="flow-line" />
            </Button>
            <Button class="{user.color && showOnMap ? `bg-blue fg-white` : ''} ml-2" on:click={handleShowOnMapClick}>
                <Icon value="compass" />
            </Button>
            <Dropdown left>
                <ul class="d-menu context ml-0" style="display: block; position:relative !important; right: 0 !important;">
                    <li>
                        <a on:click={handleWatchUser}>
                            <span class="mif-eye icon"></span>
                            {watchUserId == user.id ? 'Не следить' : 'Следить'}
                        </a>
                    </li>
                </ul>
            </Dropdown>
        </div>
    </View>
</Panel>

<style>
    .color-circle {
        width: 10px;
        height: 10px; 
        border-radius: 100%;
        margin-left: 7px;
        margin-right: 7px;
        cursor: pointer;
    }
</style>