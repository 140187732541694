<script>
  import Phalcon from 'modules/phalcon.js';
  import Router from 'modules/router.js';
  import Button from "main/Mobile/UI/Buttons/Button.html";
  import Validator from 'modules/validator.js';
  import { routeStore } from 'main/Mobile/stores/stores.js';

  // const unsubscribe = routeStore.subscribe(value => {
  //   value = myRoute;
  // });
  const baseRouteString = `yandexnavi://build_route_on_map?`;

  function getMask (data){
    let mask = Validator.modifier.phoneToMask(data);
    return mask;
  }

  let dataPoints = [];
    Phalcon.routes.my()
      .then( answer => {
        if (answer.status === 200) {
          delete answer.status;
          dataPoints = answer;
        } else {
          throw new Error('something went wrong');
        }
      })
      .catch( err => {});

  let isActive = null;

  // Phalcon.points.all().then( answer => {
  //   if ( answer.status === 200) {
  //     points = answer;
  //   } else {
  //   }
  // })

  function getTime(index, type){
    let tempString = dataPoints[0].points[index].application[`date_${type}`];
    tempString = moment(tempString).format("DD.MM HH:mm");
    return tempString;
  }

  function markField(index) {
    if (isActive === index){
      isActive = null;
    } else {
      isActive = index;
    }
  }

  function getDetails(data) {
    routeStore.set(data);
    Router.goTo(`route/${data.application.id}`);
  }
  
  function concatRoute() {
    let tempRouteString = baseRouteString;
    let tempArray = [];

    if (dataPoints && dataPoints.length > 0 && dataPoints[0].points){
      tempArray = dataPoints[0].points;
      tempArray.sort( (a, b) => a.index - b.index);

      tempRouteString += `lat_to=${tempArray[tempArray.length-1].lat}&lon_to=${tempArray[tempArray.length-1].lon}`;
      tempArray.map( (item, index) => {
        if (index !== tempArray.length - 1){
          tempRouteString += `&lat_via_${index}=${item.lat}&lon_via_${index}=${item.lon}`;
        }
      });
    }
    return tempRouteString;
  }

</script>

<style lang="scss">.uk-container {
  font-size: 0.8rem;
  overflow-y: auto;
  height: 100vh;
  background: #eeeeee; }

ul {
  padding: 0;
  margin: 0; }
  ul li {
    list-style: none; }

.point__condition {
  color: #FFFFFF;
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: #eeeeee; }

.uk-icon-button {
  background: #e2e2e2; }

.red {
  background: #e30613; }

.green {
  background: #28B446; }

.nav__head {
  height: 3rem;
  background: #ffffff;
  font-size: 1.3rem; }

.nav__foot {
  height: 3rem;
  background: #28B446;
  font-size: 1.3rem; }
  .nav__foot a {
    color: #ffffff; }
    .nav__foot a span {
      font-size: 1.2rem; }
    .nav__foot a:active {
      color: #ffffff; }
  .nav__foot img {
    height: 1.7rem;
    width: 1.7rem; }

.uk-link {
  text-decoration: underline;
  font-size: 0.8rem; }

.uk-alert-primary {
  color: #515151; }

.mobile__btn--disabled {
  opacity: 0.4; }

.text__green {
  color: #28B446; }

.text__red {
  color: #e30613; }
</style>

<div class="uk-container">
  <div class="nav__head uk-position-z-index uk-position-top uk-position-fixed uk-flex uk-flex-middle uk-width-1-1 uk-flex-between ">
    <span class="uk-margin-left"><a uk-icon="icon: table; ratio: 1;"></a></span>
      Название маршрута
    <span class="uk-margin-small-right"><a uk-icon="icon: more-vertical"></a></span>
  </div>

  <ul class="uk-margin-xlarge-top uk-margin-xlarge-bottom">
    {#if dataPoints && dataPoints.length > 0}
    {#each dataPoints[0].points as data, index}

    <li class="uk-margin-small-bottom">
      <div class="uk-padding-small uk-card uk-card-default uk-card-body">
        <div on:click="{ () => markField(index) }" class="uk-flex">
          <div
              class:green="{data.type === 'tk'}"
              class:red="{data.type === 'dl'}"
              class="point__condition uk-margin-small-right uk-text-center">{data.index}
          </div>
          <div class="uk-width-expand">{data.address}</div>
        </div>
        {#if isActive === index }
        <div class="uk-margin-small-top">
          {#if data.application}
          <div class="uk-flex uk-flex-between">
            <div><span
                class:text__green="{data.type === 'tk'}"
                class:text__red="{data.type === 'dl'}"
            >{data.type === 'dl' ? 'Доставка' : 'Забор'}</span></div>
            <!--                <div>{data.application.date_end}-{data.application.date_start}</div>-->
            <div>{getTime(index, 'start')} - {getTime(index, 'end')}</div>
          </div>
          <div class="uk-flex uk-flex-between">
            <div>{data.application.client.name}</div>
            <div><a class="uk-link">{getMask(data.application.client.phone)}</a></div>
          </div>
          <div class="uk-flex uk-flex-between uk-margin-small-bottom">
            <div>{data.legals[0]}</div>
            <div>{data.tags[1]}</div>
          </div>
          <div class="uk-flex uk-flex-around uk-margin-top">
            <div class="uk-text-center"><a class="uk-icon-button uk-margin-small-right" uk-icon="social"></a><div>Отменить</div></div>
            <div class="uk-text-center"><a class="uk-icon-button uk-margin-small-right" uk-icon="happy"></a><div>Выполнить</div></div>
            <div class="uk-text-center">
              <a on:click="{ () => getDetails(data)}" class="uk-icon-button uk-margin-small-right" uk-icon="settings"></a>
              <div>Подробнее</div>
            </div>
          </div>
          {:else}
          <div class="uk-flex uk-flex-around uk-margin-top">
            <div class="uk-text-center"><a class="uk-icon-button uk-margin-small-right" uk-icon="social"></a><div>Отменить</div></div>
            <div class="uk-text-center"><a class="uk-icon-button uk-margin-small-right" uk-icon="happy"></a><div>Выполнить</div></div>
            <div class="uk-text-center">
              <a on:click|preventDefault class="mobile__btn--disabled uk-icon-button uk-margin-small-right" uk-icon="settings"></a>
              <div class="uk-text-muted">Подробнее</div>
            </div>
          </div>
          {/if}
        </div>
        {/if}
      </div>
    </li>

    {/each}
    {/if}

<!--    {#each points as point, index}-->
<!--      <li class="uk-margin-small-bottom">-->
<!--        <div class="uk-padding-small uk-card uk-card-default uk-card-body">-->
<!--          <div on:click="{ () => markField(index) }" class="uk-flex">-->
<!--            <div-->
<!--                class:red="{point.condition === 'Забор'}"-->
<!--                class:green="{point.condition === 'Доставка'}"-->
<!--                class="point__condition uk-margin-small-right"></div>-->
<!--            <div>{point.address}</div>-->
<!--          </div>-->
<!--          {#if isActive === index}-->
<!--            <div class="uk-margin-small-top">-->
<!--              <div class="uk-flex uk-flex-between">-->
<!--                <div><span-->
<!--                    class:text__red="{point.condition === 'Забор'}"-->
<!--                    class:text__green="{point.condition === 'Доставка'}"-->
<!--                >{point.condition} № {point.num}</span></div>-->
<!--                <div>{point.time.up}-{point.time.to}</div>-->
<!--              </div>-->
<!--              <div class="uk-flex uk-flex-between">-->
<!--                <div>{point.name}</div>-->
<!--                <div><a class="uk-link">{getMask(point.phone)}</a></div>-->
<!--              </div>-->
<!--              <div class="uk-flex uk-flex-between uk-margin-small-bottom">-->
<!--                <div>{point.vendor}</div>-->
<!--                <div>{point.price} ₽</div>-->
<!--              </div>-->
<!--              <div class="uk-flex uk-flex-around">-->
<!--                <div class="uk-text-center"><a class="uk-icon-button"></a><div>Отменить</div></div>-->
<!--                <div class="uk-text-center"><a class="uk-icon-button"></a><div>Выполнить</div></div>-->
<!--                <div class="uk-text-center"><a on:click="{ () => getDetails(point.num)}" class="uk-icon-button"></a><div>Подробнее</div></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          {/if}-->
<!--        </div>-->
<!--      </li>-->
<!--    {/each}-->
  </ul>


  {#if dataPoints && dataPoints.length > 0}
  <div class="uk-position-bottom uk-position-fixed uk-flex">
    <div class="nav__foot uk-position-bottom uk-position-fixed uk-text-center uk-flex uk-flex-middle uk-width-1-1">
      <a href="{concatRoute()}" class="uk-width-1-1">
        <span class="uk-margin-right"><img src="/svg/icons/gps.svg" alt=""></span>
        <span>Построить весь маршрут</span>
      </a>
    </div>
  </div>
  {:else}
  <div class="uk-alert-primary" uk-alert>
    <p>На сегодня нет точек. Попросите назначить вам маршрут у логиста.</p>
  </div>
  {/if}


</div>

<!--<div class="uk-position-bottom uk-position-fixed uk-flex">-->
<!--  <Button on:click="{ () => {}('Подтвердить')}" name="Подтвердить" type="confirm"/>-->
<!--  <Button on:click="{ () => {}('Отменить')}" name="Отменить" type="cancel"/>-->
<!--</div>-->