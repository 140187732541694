<script>
    import { onMount, getContext } from 'svelte'

    export let iconValue = null
    export let caption = null
    export let data = null
    
    let structure = getContext('structure')
    let contentEl = null
    let el = null

    onMount(() => {
        if (structure && typeof structure === 'object' && data) {
            Object.keys(structure).forEach(key => {
                if (data[key]) {
                    m4q(el).attr(`data-${key}`, data[key])
                }
            })
        }
    })
</script>

<hidden>
    <data data-el="contentEl" bind:this={contentEl}>
        <slot name="content">

        </slot>
    </data>
</hidden>

<li 
    data-icon={iconValue ? `<span class='mif-${iconValue}'>` : null} 
    data-caption={caption}
    data-content={contentEl ? contentEl.innerHTML : null}
    bind:this={el}
>
    <slot>
        
    </slot>
</li>

<style>
    hidden {
        display: none;
    }
</style>