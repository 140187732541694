<script>
    import { onMount, onDestroy, createEventDispatcher } from 'svelte'

    const dispatch = createEventDispatcher()
    let cls = ''

    export { cls as class }
    export let items = []
    export let sortedItems = items
    export let key = 'id'
    export let order = items.map((item, idx) => item[key] || idx)

    let el = null
    let observer = null
    let dragStart = false

    onMount(() => {
        setTimeout(() => {
            MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

            observer = new MutationObserver(function(mutations, observer) {
                let sorted = false
                let sItems = []
                m4q(el).children().each((idx, child) => {
                    let itemIdx = child.dataset.idx
                    let item = JSON.parse(child.dataset.data)
                    sItems.push(item)
                    if (order[idx] != (item[key] || itemIdx)) {
                        sorted = true
                    }
                    order[idx] = item[key] || itemIdx
                })

                if (dragStart) {
                    dispatch('dragEnd')
                    dragStart = false
                } else {
                    dispatch('dragStart')
                    dragStart = true
                }

                if (sorted) {
                    sortedItems = sItems
                    dispatch('sorted', sortedItems)
                }
            });

            observer.observe(el, {
                subtree: true,
                attributes: true
            });
        })
    })

    onDestroy(() => {
        if (observer) observer.disconnect()
    })

    const checkItems = () => {
        for (let i = 0; i < items.length; i++) {
            if (items[i][key]) {
                if (!order.includes(items[i][key])) {
                    order = items.map(item => item[key])
                    break
                }
            }
        }
    }

    $: items, checkItems()
    $: sortedItems = items
</script>

<ul class="{cls}" data-role="drag-items" bind:this={el}>
    {#if items}
        {#each items as item, i}
            <li data-data={JSON.stringify(item)} data-idx={i}>
                <slot {item}>
                    Item {i+1}
                </slot>
            </li>
        {/each}
    {/if}
</ul>

<style>
    ul { 
        list-style: none;
        margin: 0;
    }

    li {
        cursor: grab;
    }
</style>