<script>
    import { createEventDispatcher } from 'svelte'
    import { Button, Icon } from 'svelte-metro-ui'
    import ProductsStoreTable from './ProductsStoreTable.svelte'

    export let selected = []

    const dispatch = createEventDispatcher()

    const handleConfirm = () => {
        dispatch('confirm')
    }

    const handleAddProduct = () => {
        dispatch('addProductRequest')
    }
</script>

<div>
    <listener on:click={handleAddProduct}>
        <slot name="addButton">
            <Button>
                Создать товар
                <Icon value="plus" />
            </Button>
        </slot>
    </listener>

    <ProductsStoreTable bind:selected />

    <div class="uk-padding-small uk-position-bottom-left uk-background-default uk-text-right uk-width footer">
        <listener on:click={handleConfirm}>
            <slot name="confirmButton">
                <Button success>
                    Готово
                </Button>
            </slot>
        </listener>
    </div>
</div>

<style>
    .footer {
        border-top: 1px solid #dfdfdf; 
        position: sticky !important;
    }
</style>