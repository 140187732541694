<script>
    import { setContext, createEventDispatcher } from 'svelte';
    import * as i18n from "i18n/strings.json";
    
    import Phalcon from "modules/phalcon.js";

    import LegalCard from "components/legals/LegalsCard.html";
    import LegalSelect from "components/legals/LegalsSelect.html";
    import ClientsSelect from "components/clients/ClientsSelect.html";
    import AddressSelect from "components/addresses/AddressesSelect.html";
    import AddressCodeSelect from "components/addresses/AddressesCodeSelect.html";
    import RoutesSelect from "components/routes/RoutesSelect.html";
    
    import TimePicker from "UI/TimePicker.html"
    import Input from "UI/Input.html"
    import Add from "UI/ButtonAdd.html"
    import DatePicker from "UI/DatePicker.html"
    import FormControl from "UI/FormControl.html";
    import PhoneInput from 'UI/InputPhone.html';
    import Select from "UI/Select.html";

    export let data;
    export let isDelivery = false;
    export let takeoutNumber;
    export let requiredCallbacks = {};
    export let tkCount;
    export let addressDisabled;

    const dispatch = createEventDispatcher();

    let address, timeOnChangeTimeout;
    let client, legal, legalShortName = '', type = isDelivery ? 'delivery' : 'takeout_' + takeoutNumber;
    let hintType = isDelivery ? 'DELIVERY' : 'TAKEOUT';
    const HINT = `HINT_${hintType}`;
    

    const legalOnChange = () => {
      if(legal){
            legalShortName = data.legal.short_name;
            if(!data.client.address || data.client.address == '') {
                data.client.address = data.legal.address || '';
                data.point.address = data.legal.address || '';
            }
        }
    }

    const clientAddressOnChange = () => {
        if(data.point.address && data.point.address != "") data.client.address = data.point.address;
    }

    const clientOnChange = () => {
        if(client){
            data.client.name = client.name;
              data.client.phone = client.phone;

                data.client.address = client.address || '';
                data.point.address = client.address || '';

        }
    }

    const pointOnChange = (event) => {
        data.point = Object.assign(data.point, event.detail);
        if(event.detail.address) data.client.address = event.detail.address;
    }
    
    $: client, clientOnChange();
    $: legal, legalOnChange();
    
    const timeOnChange = () => {
        if(timeOnChangeTimeout) clearTimeout(timeOnChangeTimeout);
        timeOnChangeTimeout = setTimeout(() => {
            let start = parseInt(data.time_start.replace(':', ''));
            let end = parseInt(data.time_end.replace(':', ''));

            if(end < start) data.time_end = data.time_start;
            if(start > end) data.time_start = data.time_end;
        }, 1000);
    };

    if(data.client && data.client.legal) {
        legalShortName = data.client.legal.short_name
    }

    if(!data.date) {
        data.date = moment().format('YYYY-MM-DD')
    }
</script>

<style>
    .wrapper {
        padding: 10px;
    }
</style>

<div class="wrapper">
            <div class="uk-flex">
                <div class="uk-width-1-3">Номер</div>
                <div class="uk-width-auto uk-margin-small-right"><span uk-icon="question" uk-tooltip="title: {i18n.ru[`${HINT}_ORDER_NUMBER`]}; pos: top-left"></span></div>
                <Input placeholder="Номер заказа в вашей учетной системе" type="text" bind:value="{data.number}"/>
            </div>
            <div class="uk-flex" id="{isDelivery ? 'delivery-legal' : 'takeout-legal'}">
                <div class="uk-width-1-3">Контрагент</div>
                <div class="uk-width-auto uk-margin-small-right"><span uk-icon="question" uk-tooltip="title: {i18n.ru[`${HINT}_LEGAL`]}; pos: top-left"></span></div>
                <LegalSelect bind:data={data.legal} bind:text={legalShortName} bind:client />
            </div>
            {#if data.legal}
                <div class="uk-flex uk-flex-right uk-margin-small-bottom">
                    {#if data.legal.inn}
                    <div class="uk-margin-small-left uk-text-muted">
                        <span>ИНН</span>
                        <span>{data.legal.inn}</span>
                    </div>
                    {/if}

                    {#if data.legal.kpp}
                    <div class="uk-margin-small-left uk-text-muted">
                        <span>КПП</span>
                        <span>{data.legal.kpp}</span>
                    </div>
                    {/if}

                    {#if data.legal.ogrn}
                    <div class="uk-margin-small-left uk-text-muted">
                        <span>ОГРН</span>
                        <span>{data.legal.ogrn}</span>
                    </div>
                    {/if}
                </div>
            {/if}
            <div class="uk-flex" id="{isDelivery ? 'delivery-address-code' : 'takeout-address-code'}">
                <div class="uk-width-1-3">Код адреса</div>
                <div class="uk-width-auto uk-margin-small-right"><span uk-icon="question" uk-tooltip="title: {i18n.ru[`${HINT}_ADDRESS_CODE`]}; pos: top-left"></span></div>
                <AddressCodeSelect
                    placeholder="Начните ввод"
                    bind:text={data.point.code}
                    on:change={pointOnChange}
                />
            </div>
            <div class="uk-flex" id="{isDelivery ? 'delivery-address' : 'takeout-address'}">
                <div class="uk-width-1-3">Адрес <span class="required">*</span></div>
                <div class="uk-width-auto uk-margin-small-right"><span uk-icon="question" uk-tooltip="title: {i18n.ru[`${HINT}_ADDRESS`]}; pos: top-left"></span></div>
                <AddressSelect
                    placeholder="Начните ввод"
                    bind:isValidCallback="{requiredCallbacks[type+'_adress']}" 
                    on:danger
                    bind:text={data.client.address}
                    bind:data={data.point}
                    disabled={addressDisabled}
                />
            </div>
            <div class="uk-flex">
                <div class="uk-width-3-5">Контактное лицо</div>
                <ClientsSelect 
                    bind:data={client} 
                    bind:text={data.client.name} 
                    bind:legal
                />
            </div>
            <div class="uk-flex">
                <div class="uk-width-3-5">Телефон</div>
                <PhoneInput bind:phone={data.client.phone}/>
            </div>
            <div class="uk-flex">
                <div class="uk-width-3-5">Дата <span class="required">*</span></div>
                <DatePicker bind:value="{data.date}" required={true} requireName="Дата" bind:isValidCallback="{requiredCallbacks[type+'_date']}" on:danger />
            </div>
            <div class="uk-flex">
                <div class="uk-width-3-5">Время <span class="required">*</span></div>
                <div class="uk-flex uk-width-1-1">
                    <div><TimePicker bind:value="{data.time_start}" title="с" /></div>
                    <div class="uk-margin-small-left" >
                      <TimePicker bind:value="{data.time_end}" title="по" />
                    </div>
                </div>
            </div>
            <FormControl name="Маршрут" hint="Если на выбранную в заявке дату есть сформированные маршруты, то вы можете задать маршрут из списка">
                <RoutesSelect date_start={data.date} date_end={data.date} bind:data={data.route} />
            </FormControl>

            <!--              -->
<!--            <div class="uk-flex">-->
<!--                <div class="uk-width-3-5"></div>-->
<!--                <div class="uk-flex uk-width-1-1">-->
<!--                  <div><TimePicker bind:value="{data.time_start}" title="с" /></div>-->
<!--                  <div class="uk-margin-small-left" >-->
<!--                       <TimePicker bind:value="{data.time_end}" title="по" />-->
<!--                  </div>-->
<!--                </div>-->
<!--            </div>-->
            <!--              -->

            <div class="uk-width uk-margin-small-top">
                {#if !isDelivery}
                    <div class="uk-flex uk-flex-between" id="takeout-goto-products">
                        <div class="uk-text-center uk-width-expand">
                            <a class="uk-link" on:click="{() => dispatch('focusProd')}" >Перейти к добавлению Товаров</a>
                        </div>
                    </div>
                    {#if !tkCount || tkCount > 1}
                        <div class="uk-position-small uk-position-bottom-right">
                            <span uk-icon="icon: trash" style="cursor: pointer" on:click="{() => dispatch('remove')}"></span>
                        </div>
                    {/if}
                {/if}
            </div>
</div>