<script>
    import { onMount } from 'svelte';

    import validator from 'modules/validator.js';
    import router from 'modules/router.js';
    import phalcon from 'modules/phalcon.js';

    import PrivatePolicyModal from 'components/private_policy/PrivatePolicyModal.html';

    import Input from 'UI/Input.html';
    import PhoneInput from 'UI/InputPhone.html';
    import Checkbox from 'UI/Checkbox.html';
    import Button from 'UI/ButtonOld.html';

    export let formData = {
        domain: "",
        secret: phalcon.getUrlVars()['secret'],
        recaptcha_response: null
    };

    let validation = {};
    let loader;
    let accountIsFree = false;
    let recaptchaToken;
    let modalPrivacy = false;
    let domain = '';

    const check = () => {
        if(formData.domain != ""){
            loader = phalcon.accounts.checkDomain(formData.domain).then(answer => {
                if(answer.status == 200){
                    accountIsFree = true;
                }
            })
        } else {
            UIkit.notification(`Название аккаунта не может быть пустым`, {status: "danger", pos: "bottom-right"});
        }
    }

    const submit = () => {
        if(validator.execute(validation) && formData.password != ""){
            if(formData.password == formData.password_agree){
                phalcon.users.checkPhone(formData.phone).then(answer => {
                    if(answer.status == 200){
                        loader = phalcon.confirm.register(formData).then(answer => {
                            if(answer.status == 200){
                                UIkit.notification(`Поздравляем! Аккаунт создан успешно!`, {status: "success", pos: "bottom-right"});
                                setTimeout(() => {
                                    phalcon.session.start(answer).then(answer => {
                                        if (answer.status == 200) {

                                        } else {
                                            router.goTo('auth');
                                        }
                                    })
                                }, 1500);
                            }
                        })
                    }
                });
            } else {
                UIkit.notification(`Пароли не совпадают`, {status: "danger", pos: "bottom-right"});
            }
        }
    }

    $: {
        domain = validator.translate.rus_to_latin(domain).replace(/[^a-zA-Z0-9- ]/g, "").toLowerCase();
        formData.domain = domain;
    };

    onMount(() => {
        //console.log("onMount");
        grecaptcha.ready(function () {
            grecaptcha.execute(document.getElementById('recaptcha_v3_key').value, {
                action: 'confirm'
            }).then(function (token) {
                formData.recaptcha_response = token;
            });
        });
    })
</script>

<form onkeydown="if(event.keyCode === 13) return false;">
    <fieldset class="uk-fieldset uk-width-medium">
        {#if !accountIsFree}
            <p>Введите желаемое название вашего аккаунта</p>
            <div class="uk-flex uk-flex-end">
<!--                <input type="text" bind:value={formData.domain} class="uk-input uk-form-small uk-text-right" />-->
                <input type="text" bind:value={domain} class="uk-input uk-form-small uk-text-right" />
                <span>.routes.expert</span>
            </div>
            <p class="uk-text-muted uk-text-small uk-margin-medium-top">
                Адрес для входа в Ваш аккаунт REX (одно слово без пробелов и только латинскими буквами, цифрами или знаком “-”)
            </p>
            <div class="uk-flex uk-flex-center uk-margin-medium-top">
                <div class="uk-width-small">
                    <Button name="Далее" on:click={check} size="md" {loader} />
                </div>
            </div>
        {:else}
            <p>Всего один шаг до начала работы. Пожалуйста, введите данные для последующих входов в сервис</p>
            <Input bind:value={formData.first_name} bind:isValidCallback={validation.first_name} isRequired={true} placeholder="Имя" type="text" />
            <Input bind:value={formData.last_name} placeholder="Фамилия" type="text" />
            <PhoneInput bind:phone={formData.phone} bind:validCallback={validation.phone} isRequired={true} />
            <Input bind:value={formData.password} bind:validCallback={validation.password} isRequired={true} placeholder="Пароль" type="password" />
            <Input bind:value={formData.password_agree} placeholder="Подтведите пароль" type="password" />
            <div class="uk-flex uk-flex-center uk-margin-medium-top">
                <div class="uk-width-small">
                    <Button name="Создать аккаунт" on:click={submit} size="md" {loader} />
                </div>
            </div>
            <div class="uk-margin-small-top">
                <a class="uk-text-muted" on:click="{() => accountIsFree = false}">Назад</a>
            </div>
            <p class="uk-text-small uk-text-muted">Нажимая кнопку «Создать аккаунт» Вы принимаете условия договора-оферты на использование сервиса, <a class="uk-text-muted uk-text-link" on:click|stopPropagation="{() => modalPrivacy = true}">политики конфиденциальности</a>, и даете согласие на обработку своих персональных данных</p>
        {/if}
    </fieldset>
</form>

{#if modalPrivacy}
    <PrivatePolicyModal on:close="{() => modalPrivacy = false}"/>
{/if}

<style>
    .uk-input {
        border: none;
        border-bottom: 2px solid #C4C4C4;
        margin-top: -2.4px;
        font-size: 16px;
        padding-right: 3px !important;
    }
</style>