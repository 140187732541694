<script>
    import { filter } from 'rxjs/operators';
    import { onDestroy, createEventDispatcher, onMount } from 'svelte'
    import Phalcon from 'modules/phalcon.js'
    import Scroll from 'modules/scroll.js';
    import { expressDSUserRecommendation } from 'main/stores/DeliveryServicesStore.js'
    
    import { regionsMap } from 'main/stores/RegionsStore.js'

    import DeliveryServicesItem from "components/delivery_services/DeliveryServicesItem.html"
    import Backdrop from 'UI/Backdrop.html'
    import { successMsg, warningMsg, errorMsg } from 'UI/Messages.html'
    import DeliveryServicesItemInfoBlock from './DeliveryServicesItemInfoBlock.html'
    import DeliveryService from 'models/DeliveryService'

    import dsService from 'services/DeliveryServicesService';

    const dispatch = createEventDispatcher()

    const rand = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    export let deliveryService = DeliveryService.empty();
    export let status = {}
    export let related = false
    export let statusList = []
    export let statusListLoading = false
    export let usersList = []
    export let usersListLoading = false
    export let currentTime = 0
    export let timeLeft = 0
    export let showRegion = false
    
    let newDeliveryUserId = deliveryService.deliveryUserId
    let durationString = "00:00"
    let timer
    let showDetail = false
    let showPickups = false
    let showCargo = false
    let timeStrings = []
    let region = regionsMap.get(deliveryService.regionId)
    let regionProperties = null
    let deliveryUserRecommendation = null

    const expressDSUserRecommendationUnsubscribe = expressDSUserRecommendation.subscribe(v => {
        if (v[deliveryService.id]) {
            if (!deliveryUserRecommendation || (deliveryUserRecommendation && deliveryUserRecommendation.deliveryUser.id != v[deliveryService.id].deliveryUser.id)) {
                deliveryUserRecommendation = v[deliveryService.id];
                Scroll.scrollTo('#delivery-services-list', `#delivery-services-${deliveryService.id}`);
                warningMsg(`Курьера ${deliveryUserRecommendation.deliveryUser.last_name} ${deliveryUserRecommendation.deliveryUser.first_name} можно назначить на заказ №${deliveryService.number}`);
            }
        } else {
            deliveryUserRecommendation = null
        }
    })

    if (region) {
        if (region.properties) regionProperties = JSON.parse(region.properties)
    }

    const onChangeStatusId = async () => {
        await deliveryService.saveStatus();
        dispatch('statusChange', deliveryService.statusId);
    }

    async function setDeliveryUser(force = false, deliveryUserId = newDeliveryUserId) {
        if (usersListLoading) return;
        
        usersListLoading = true

        const answer = await Phalcon.api.delivery.services.setDeliveryUser(deliveryService.id, deliveryUserId, { force })
        if(answer.status != 200) {
            if (
                answer.payload && 
                answer.code && 
                answer.code == 'SOME_ASSIGN_RULES_ERROR' && 
                Array.isArray(answer.payload)
            ) {
                Swal.fire({
                    title: `Нарушены правила назначения заказа!`,
                    html: `Нарушены следующие правила назначения заказа:<br><br>` + answer.payload.join(',<br>'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Все равно назначить!',
                    cancelButtonText: 'Не назначать'
                }).then((result) => {
                    if (result.value) {
                        setDeliveryUser(true, deliveryUserId);
                    } else { 
                        newDeliveryUserId = deliveryService.deliveryUserId;
                    }
                })
            }
        } else {
            deliveryService.deliveryUserId = newDeliveryUserId;
            dispatch('userChange', deliveryService)
        }

        usersListLoading = false
    }

    const onChangeDeliveryUserId = async () => {
        setDeliveryUser();
    }

    const setRecommendedDeliveryUser = async () => {
        if (deliveryUserRecommendation && deliveryUserRecommendation.deliveryUser && deliveryUserRecommendation.deliveryUser.id) {
            setDeliveryUser(false, deliveryUserRecommendation.deliveryUser.id);
        }
    }

    const onChildPickupIconClick = event => {
        dispatch('pickupIconClick', event.detail)
    }

    const onPickupIconClick = () => {
        if (related) {
            dispatch('pickupIconClick', {
                lng: deliveryService.lng, 
                lat: deliveryService.lat
            });

            return;
        }

        if (deliveryService.pickups) {
            dispatch('pickupIconClick', {
                lng: deliveryService.pickups[0].lng, 
                lat: deliveryService.pickups[0].lat
            });
        }
    }

    const onDeliveryIconClick = () => {
        dispatch('deliveryIconClick', {
            lng: deliveryService.lng, 
            lat: deliveryService.lat
        });
    }

    const timeIntToMS = time => {
        let m = Math.floor(time / 60);
        if (m) time -= (m * 60);

        let s = Math.floor(time);

        if (m < 10) m = `0${m}`
        if (s < 10) s = `0${s}`

        return `${m}:${s}`
    }

    const timeIntToHM = time => {
        let h = Math.floor(time / 60 / 60);
        if (h) time -= (h * 60 * 60);

        let m = Math.floor(time / 60);

        if (h < 10) h = `0${h}`
        if (m < 10) m = `0${m}`

        return `${h}:${m}`
    }

    // onMount(() => {
    //     const subscription = dsService.statusChangeObservable
    //         .pipe(
    //             filter(ds => ds.id == deliveryService.id)
    //         )
    //         .subscribe({
    //             next(ds) { 
    //                 deliveryService.statusId = ds.statusId;
    //                 deliveryService.deliveryUserId = ds.deliveryUserId;
    //                 console.log('got value', {ds}); 
    //             },
    //             error(err) { console.error('something wrong occurred: ' + err); },
    //             complete() { console.log('done'); }
    //         })

    //     return () => subscription.unsubscribe()
    // })

    onDestroy(() => {
        expressDSUserRecommendationUnsubscribe();
        $expressDSUserRecommendation[deliveryService.id] = null;
    });

    const clearRecommendation = () => {
        if (deliveryUserRecommendation) $expressDSUserRecommendation[deliveryService.id] = null;
    }

    $: durationString = timeIntToMS(timeLeft);
    $: status = statusList.find(s => s.id == deliveryService.statusId);
    $: if (!related) {
        let tl = deliveryService.duration - (currentTime - deliveryService.createdAt);

        if (tl > 0 && deliveryService.statusId != 11) {
            timeLeft = tl;
        } else {
            timeLeft = 0;
        }
    }

    $: newDeliveryUserId = deliveryService.deliveryUserId;
    $: newDeliveryUserId, clearRecommendation();
</script>

<div id="delivery-services-{deliveryService.id}" ds-id={deliveryService.id} style="animation-duration: 0.3s;"
    class="uk-card uk-card-default uk-card-small uk-margin-small-top" class:related>
    <div class="uk-card-body uk-padding-remove">
        <div class="uk-grid-match uk-grid-small" uk-grid>
            <div class="uk-width-expand uk-grid-small" uk-grid>
                <div class="uk-width-small" style="width:140px">
                    <h4 class="uk-text-meta uk-text-truncate" uk-tooltip="{deliveryService.number}">№{deliveryService.number}</h4>
                    {#if statusList.length}
                        <div class="uk-position-relative">
                            {#if deliveryService.saveStatusLoading}
                                <Backdrop>
                                    <div uk-spinner></div>
                                </Backdrop>
                            {/if}
                            <select 
                                class="uk-select uk-form-small status-select" 
                                on:change={onChangeStatusId} 
                                bind:value={deliveryService.statusId} 
                                style="background:{status.color}33; color:{status.color};"
                            >
                                {#each statusList as item}
                                    <option value={item.id}>{item.name}</option>
                                {/each}
                            </select>
                        </div>
                    {/if}
                </div>
                <div class="uk-width-small" style="width:140px">
                    <h4 class="uk-text-meta">Курьер:</h4>
                    {#if usersList.length}
                        <div class="uk-position-relative" >
                            {#if usersListLoading}
                                <Backdrop>
                                    <div uk-spinner></div>
                                </Backdrop>
                            {/if}
                            <select 
                                class="uk-select uk-form-small" 
                                on:change={onChangeDeliveryUserId} 
                                bind:value={newDeliveryUserId} 
                                disabled={related}
                            >
                                <option value={0}>Не выбран</option>
                                {#each usersList.filter(u => u.on_duty) as user (user.id)}
                                    <option 
                                        class={user.status}
                                        disabled={!user.on_duty} 
                                        value={user.id}
                                        title="{!user.on_duty ? 'Смена закрыта' : ''}"
                                    >
                                    {user.last_name} {user.first_name}
                                    </option>
                                {/each}
                            </select>
                        </div>
                    {:else}
                        Нет курьеров
                    {/if}
                </div>
                <div class="uk-width-expand uk-text-right">
                    <div>
                        {#if deliveryService.pickups}
                            <a uk-icon="icon: location; ratio: 0.8" class="pickup-icon" on:click={onPickupIconClick}></a>
                        {/if}

                        {#if deliveryService.pickups && !related}
                            <span uk-icon="icon: arrow-right; ratio: 0.8"></span>
                        {/if}

                        {#if !related}
                            <a uk-icon="icon: location; ratio: 0.8" class="delivery-icon" on:click={onDeliveryIconClick}></a>
                        {/if}
                    </div>
                
                    <div 
                        class="time uk-margin-small-top"
                        class:low-priority="{timeLeft > 60 * 60}"
                        class:medium-priority="{timeLeft <= 60 * 60 && timeLeft > 60 * 30}"
                        class:high-priority="{timeLeft <= 60 * 30}">
                        <span uk-icon="icon: clock; ratio: 0.8"></span>
                        <label>{durationString}</label>
                    </div>
                </div>

                <div class="uk-width uk-grid-collapse" uk-grid>
                    {#if deliveryService.pickups}
                        <div class="uk-width-expand uk-text-truncate" uk-tooltip="{deliveryService.pickups[0].address}">
                            {deliveryService.pickups[0].address}
                        </div>
                        <div class="uk-width-auto" style="padding-left: 15px; padding-right: 15px;">
                            <span uk-icon="icon: arrow-right; ratio: 0.8"></span>
                        </div>
                    {/if}
                    <div class="uk-width-expand uk-text-truncate" uk-tooltip="{deliveryService.address}">
                        {deliveryService.address}
                    </div>
                </div>
                
                <div class="uk-width">
                    {#if !related && showRegion}
                        <span class="uk-badge" style="--fill-color:{regionProperties && regionProperties.fill ? regionProperties.fill : 'black'}">{region && region.name ? region.name : 'Без региона'}</span>
                    {/if}
                
                    {#if (deliveryService.statusId == 4 || deliveryService.statusId == 2) && deliveryService.pickups && deliveryService.pickups[0].statusId == 11}
                        <span class="uk-badge green">Курьер забрал заказ из магазина</span>
                    {/if}
                </div>
            </div>
            
            <div class="uk-width-auto uk-flex uk-flex-middle uk-flex-center">
                {#if showDetail}
                    <a uk-icon="chevron-up" on:click="{() => showDetail = false}"></a>
                {:else}
                    <a uk-icon="chevron-down" on:click="{() => showDetail = true}"></a>
                {/if}
            </div>
        </div>

        {#if showDetail}
            <hr class="uk-margin-small-top">
            <div>
                {#if deliveryService.workObject}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Пункт</span>
                        {deliveryService.workObject}
                    </DeliveryServicesItemInfoBlock>
                {/if}

                {#if deliveryService.address}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Адрес</span>
                        {deliveryService.address}
                    </DeliveryServicesItemInfoBlock>
                {/if}

                {#if deliveryService.contactName}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Контактное лицо</span>
                        {deliveryService.contactName}
                    </DeliveryServicesItemInfoBlock>
                {/if}

                {#if deliveryService.contactPhone}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Контактный номер телефона</span>
                        <a href="tel:+7{deliveryService.contactPhone}">+7{deliveryService.contactPhone}</a>
                    </DeliveryServicesItemInfoBlock>
                {/if}

                {#if deliveryService.date}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Дата доставки</span>
                        {moment(deliveryService.date).format("DD.MM.YYYY")}
                    </DeliveryServicesItemInfoBlock>
                {/if}

                <DeliveryServicesItemInfoBlock>
                    <span class="uk-text-meta" slot="title">Время доставки</span>
                    {deliveryService.timewindowsString()}
                </DeliveryServicesItemInfoBlock>

                {#if deliveryService.comment}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Комментарий</span>
                        {deliveryService.comment}
                    </DeliveryServicesItemInfoBlock>
                {/if}

                {#if deliveryService.cargo}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Список груза</span>
                        {#if showCargo} 
                            <button on:click="{() => showCargo = false}">Скрыть</button>
                        {:else}
                            <button on:click="{() => showCargo = true}">Показать</button>
                        {/if}
                    </DeliveryServicesItemInfoBlock>
                    {#if showCargo}
                        <div class="uk-padding-small">
                            {#each Object.keys(deliveryService.cargo) as key} 
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-expand" uk-leader>{key}</div>
                                    <div>{deliveryService.cargo[key]}</div>
                                </div>
                            {/each}
                        </div>
                    {/if}
                {/if}

                {#if deliveryService.pickups}
                    <DeliveryServicesItemInfoBlock>
                        <span class="uk-text-meta" slot="title">Откуда забрать</span>
                        {#if showPickups} 
                            <button on:click="{() => showPickups = false}">Скрыть</button>
                        {:else}
                            <button on:click="{() => showPickups = true}">Показать</button>
                        {/if}
                    </DeliveryServicesItemInfoBlock>
                    {#if showPickups}
                        {#each deliveryService.pickups as pickup} 
                            <DeliveryServicesItem 
                                { statusList }
                                { usersList }
                                { timeLeft }
                                deliveryService={pickup}
                                on:pickupIconClick={onChildPickupIconClick}
                                related 
                            />
                        {/each}
                    {/if}
                {/if}
                
            </div>
        {/if}
        </div>
    {#if deliveryUserRecommendation && !deliveryService.deliveryUserId}
        <div class="uk-card-footer uk-flex uk-flex-between uk-flex-middle uk-padding-remove uk-margin-small-top">
            <p class="uk-text-bold" style="color: darkorange">{deliveryUserRecommendation.text}</p>
            <a on:click={setRecommendedDeliveryUser}>Назначить</a>
        </div>
    {/if}
</div>

<style>
    h4 {
        margin-bottom: 5px;
        font-size: 15px;
    }

    p {
        margin: 0 !important;
        margin-bottom: 3px;
        font-size: 13px;
    }

    b, div {
        font-size: 13px;
    }

    .status-select {
        font-size: 11px;
        height: 25px;
        white-space: nowrap;
        border: none;
        border-radius: 6px;
        padding: 3px;
    }

    .uk-card {
        padding: 10px;
    }

    .uk-card.related {
        background-color: #f6f6f6;
        box-shadow: none;
    }

    .uk-text-meta {
        color: #818181;
    }

    .time.low-priority {
        color: darkgreen
    }

    .time.medium-priority {
        color: darkorange
    }

    .time.high-priority {
        color: darkred
    }

    .pickup-icon:hover {
        color: darkgreen
    }

    .delivery-icon:hover {
        color: darkorange
    }

    .uk-badge {
        background-color: var(--fill-color);
    }

    .uk-badge.green {
        background-color: green !important;
    }

    select option:disabled {
        color: rgba(0,0,0,0.5);
        background-color: rgba(0,0,0,0.1);
    }

    select option.FREE {
        background-color: rgb(202, 255, 202);
    }
    
    select option.WAITING {
        background-color: rgb(248, 248, 144);
    }
    
    select option.BUSY {
        background-color: rgb(255, 220, 220);
    }
</style>