<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let name = "";
  export let isDisabled = false;
  export let type = "text";
  export let value = "";
  export let textPos = "left";
  export let placeholder = "";
  export let input;
  export let onkeydown = "";
  export let required = false;
  export let requireName = "";
  export let isValidCallback;
  export let isRequired = false;
  export let collapse = false;
  export let size = 'expanded';

  let danger = false;

  export const validCallback = () => {
    let isValid = true;

    if (value.length !== 0) isValid = checkValid(value);
    else if (!required && value.length !== 0) isValid = checkValid(value);
    else setDanger();
    return isValid;
  };

  function checkValid(data) {
    if (data.length !== 0) {
      return true;
    }
    return false;
  }

  function getChange(event) {
    danger = false;
    if (type == 'number') {
      value = Math.abs(event.target.value);
    } else {
      value = event.target.value;
    }
    dispatch('change', event.target.value);
  }

  function getInput(event) {
    danger = false;
    if (type == 'number') {
      value = Math.abs(event.target.value);
    } else {
      value = event.target.value;
    }
    dispatch('input', event.target.value);
  }

  function setDanger() {
    input.classList.add('uk-animation-shake', 'uk-text-danger');
    setTimeout(() => {
      input.classList.remove('uk-animation-shake');
    }, 300);

    danger = true;
    dispatch('danger');
  }

  function removeDanger() {
    if (input) input.classList.remove('uk-animation-shake', 'uk-text-danger');
  }

  if (type == 'text' || type == 'time') {
    isValidCallback = () => {
      if (!value || value == '') {

        UIkit.notification({
          message: `Поле "${requireName != "" ? requireName : placeholder}" не может быть пустым`,
          status: 'danger',
          pos: 'bottom-right',
          timeout: 5000
        });

        input.classList.add('uk-animation-shake', 'uk-text-danger');
        setTimeout(() => {
          input.classList.remove('uk-animation-shake');
        }, 300);

        danger = true;
        dispatch('danger');

        return false;
      }
      return true;
    };
  }

  $: if (!danger) {
    removeDanger()
  }
  $: value, removeDanger();
</script>

<input 
  name={name}
  placeholder={placeholder}
  type={type}
  disabled={isDisabled}
  value={value}
  min="{type == 'number' ? 0: ''}"
  class="uk-input uk-text-{textPos}"
  class:uk-width-small="{size == 'small'}"
  class:collapse

  onkeydown={onkeydown}
  on:input={getInput}
  on:change

  required
  bind:this={input} 
/>

<style lang="scss">.wrapper {
  width: 100%; }

input {
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  font-size: 14px;
  height: 30px; }
</style>