<script>
    import { writable, get } from 'svelte-v3/store'
    import { Panel } from 'svelte-metro-ui'
    import { ListKey, filteredItems } from './MultiListStore'
    import { routeMode } from './PageStore'
    import { updateServiceInStore } from './ServicesStore'
    import { routesMapStore } from '../../stores/RoutesStore'

    export let service = null

    const handleClick = () => {
        if ($routeMode && !service.routes_order && !service.routes_id) {
            let uid, route
            let routeStore = $filteredItems[ListKey.Routes].find(rs => get(rs).isChanged)
            let routeOnMapStore = $filteredItems[ListKey.RoutesOnMap].find(rs => get(rs).isChanged)

            if (!routeStore || !routeOnMapStore) {
                uid = 'R' + Math.random().toString(36).substring(7)
                route = {
                    uid,
                    delivery_services_ids: service.id,
                    services: [service],
                    geometry: null,
                    isChanged: true,
                    points: [
                        {
                            lat: service.lat,
                            lng: service.lng
                        }
                    ]
                }
            }

            if (!routeStore) {
                routeStore = writable(route)
                $filteredItems[ListKey.Routes] = [...$filteredItems[ListKey.Routes],routeStore]

                updateServiceInStore(service.id, {
                    routes_order: 0,
                    routes_id: uid
                })

                service.routes_order = 0
                service.routes_id = uid

                if (!routeOnMapStore) {
                    routeOnMapStore = writable(route)
                    setTimeout(() => {
                        $filteredItems[ListKey.RoutesOnMap] = [...$filteredItems[ListKey.RoutesOnMap],routeOnMapStore]
                    })
                }
            } else {         
                let routeItem = get(routeStore)
                let services = routeItem.services
                routeItem.services = [...services, service]
                routeItem.delivery_services_ids = routeItem.services.map(s => s.id).join(',')
                routeStore.set(routeItem)

                updateServiceInStore(service.id, {
                    routes_order: services.length,
                    routes_id: routeItem.uid,
                    delivery_user_id: routeItem.delivery_user_id
                })

                if (routeOnMapStore) {
                    let routeOnMap = get(routeOnMapStore)
                    routeOnMap.geometry = null
                    routeOnMap.points = routeItem.services.map(s => {
                        return {
                            lat: s.lat,
                            lng: s.lng
                        }
                    })
                    routeOnMapStore.set(routeOnMap)
                }
            } 
        }
    }
</script>

{#if service}
    <div on:click={handleClick}>
        <Panel class="mb-2 pos-relative delivery-planner-service-item">
            {service.number}
            {#if service.routes_id && $routesMapStore[service.routes_id]}
                <div class="uk-flex">
                    <div class="color-circle" style="background-color: {$routesMapStore[service.routes_id].color}"></div>
                    <span style="font-size:11px;">
                        Машрут {$routesMapStore[service.routes_id].uid || $routesMapStore[service.routes_id].id}
                    </span>
                </div>
            {/if}
        </Panel>
    </div>
{/if}

<style>
    .color-circle {
        width: 7px;
        height: 7px; 
        border-radius: 100%;
        margin-right: 7px;
        cursor: pointer;
    }
</style>