<script>
  import Button from "UI/ButtonOld.html"

  export let data = {
    price:"500",
    add:"Доп. Машинка",
    period:"месяц"
  };

  $:countToPrice = data.price * (data.count || 1)
</script>

<div class="wrapper">
  <div class="card__between uk-flex-between uk-card uk-card-body uk-card-default uk-padding-small">
    <slot name="title">
      <div>
        {data.add}
      </div>
    </slot>

    <slot name="price">
      <div class="uk-text-center uk-height-1-1">
        <div class="price">
          {#if data.count}+{/if}{countToPrice}
          <span class="currency">₽</span>
        </div>
        <div class="timeCount">{data.period || 'месяц'} </div>
      </div>
    </slot>

    <slot name="action">
      {#if !data.count}
        <div>
          <Button name="Подтвердить" status="success" />
        </div>
      {/if}
    </slot>
    <slot name="count">
      {#if data.count}
        <div>
          <button disabled="{data.count <=1}" on:click="{ ()=> data.count -=1}" class="btn__remote">-</button>
          <button disabled class="btn__container">{data.count}</button>
          <button on:click="{ ()=> data.count +=1}" class="btn__remote">+</button>
        </div>
      {/if}
    </slot>
  </div>
</div>

<style>
  .card__between{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wrapper {
    font-size: 18px;
  }

  .timeCount{
    font-size: 16px;
    font-weight: 300;
    color: #c4c4c4;
  }

  .price{
    font-size: 36px;
    color: #515151;
  }

  .currency{
    font-size: 24px;
  }

  .uk-card-default{
    width: 250px;
    height: 210px;
    padding-top: 20px;
    color: black;
    font-weight: 500;
  }

  .btn__container{
    width: 50px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #c4c4c4;
    background: rgba(0, 0, 0, 0);
  }

  .btn__remote{
    width: 35px;
    height: 20px;
    border: 1px solid #c4c4c4;
    background: rgba(0, 0, 0, 0);
    border-radius: 3px;
  }
</style>