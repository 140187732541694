<script>
  export let  type = "star",
              ratio = "1";
</script>

<style>
  .trash {
    color: #e30613;
  }
</style>

<div class="wrapper">
    <a class="{type}" on:click uk-icon="icon: {type}; ratio: {ratio}}" ></a>
</div>